export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_START_SIDEBAR = 'FETCH_START_SIDEBAR'

export const GET_ALL_USERS = 'get_all_users';
export const ADD_USER = 'add_user';
export const GET_USER_DETAILS = 'get_user_detail'
export const EDIT_SUBDIVISION_USER = 'edit_subdivision_user'
export const UPDATE_ALERT_LIST = 'UPDATE_ALERT_LIST';
export const GET_SUBDIVISION = 'get_subdivision'

export const GET_TRAVEL_LIST_SOCKET = 'GET_TRAVEL_LIST_SOCKET'
export const END_TRAVEL_SOCKET = 'END_TRAVEL_SOCKET'
export const UPDATE_TRAVEL_RAISED_COUNT = 'UPDATE_TRAVEL_RAISED_COUNT'

//Exteral Comtroller
export const GET_EXTERNAL_USER = 'get_external_user'
export const ADD_EXTERNAL_USER = 'add_external_user'
export const EDIT_EXTERNAL_USER = 'edit_extrnal_user'

//  IMPORTANT CONTACT

export const IMPORTANT_CONTACT_LIST = 'important_contact _list';
export const ADD_IMPORTANT_CONTACT = 'add_important_contact';
export const UPDATE_IMPORTANT_CONTACT = 'update_important_contact';
export const REMOVE_IMPORTANT_CONTACT = 'remove_important_contact';
export const SET_IMPORT_CONTACT = 'set_import_contact';
export const RESET_IMPORT_LIST = 'RESET_IMPORT_LIST'

// LOCAL_POLICE_NUMBER
export const LOCAL_POLICE_NUMBER_LIST = 'local_police_number_list';
export const ADD_LOCAL_POLICE_NUMBER = 'add_local_police_number';
export const UPDATE_LOCAL_POLICE_NUMBER = 'update_local_police_number';
export const REMOVE_LOCAL_POLICE_NUMBER = 'remove_local_police_number';
export const SET_LOCAL_POLICE_NUMBER = 'set_local_police_number';

//  USEFUL LINK FOR TRAVEL
export const USEFUL_LINK_FOR_TRAVEL_LIST = 'useful_link_for_travel';
export const ADD_USEFUL_LINK_FOR_TRAVEL = 'add_useful_link_for_travel';
export const UPDATE_USEFUL_LINK_FOR_TRAVEL = 'update_useful_link_for_travel';
export const REMOVE_USEFUL_LINK_FOR_TRAVEL = 'remove_useful_link_for_travel';
export const SET_USEFUL_LINK_TRAVEL = 'set_useful_link__travel'


//user profile
export const USER_PROFILE = 'user_profile'
export const EDIT_USER_PROFILE = 'edit_user_profile'
export const CHANGE_PASSWORD = 'change_password'
export const FORGOT_PASSWORD = 'forgot_password'
export const SET_LOADER = 'SET_LOADER'

export const GET_USER_DETAILS_UPDATE = 'GET_USER_DETAILS_UPDATE'

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//TENANT USER
export const GET_TENANTLIST = 'GET_TENANTLIST'
export const ADD_TENANT = 'ADD_TENANT';
export const SET_TENANT = 'SET_TENANT'

// Travel
export const GET_TRAVEL_LIST = "GET_TRAVEL_LIST";
export const RESET_TRAVEL_LIST = "RESET_TRAVEL_LIST";
export const GET_ACTIVE_TRAVEL = "GET_ACTIVE_TRAVEL";
export const ADD_TRAVEL = "ADD_TRAVEL";
export const RESET_ADD_STATUS = "RESET_ADD_STATUS";
export const GET_TRAVEL_LOG_DETAIL = "GET_TRAVEL_LOG_DETAIL";
export const ADD_TRAVEL_COMMENT = "ADD_TRAVEL_COMMENT";
export const RESET_TRAVEL_COMMENT = "RESET_TRAVEL_COMMENT";
export const UPDATE_TRAVEL_STATUS = "UPDATE_TRAVEL_STATUS";
export const RESET_UPDATE_STATUS = "RESET_UPDATE_STATUS";
export const UPDATE_TRAVEL_LIST = "UPDATE_TRAVEL_LIST";

//sub-division users
export const GET_USERSLIST = 'GET_USERSLIST'
export const SET_USER = 'SET_USER'
// tenant-user alert
export const GET_ALERTLIST = 'GET_ALERTLIST'
export const SET_ALERT = 'SET_ALERT'
export const ADD_ALERTCOMMENT = 'ADD_ALERTCOMMENT';
export const RESET_ALERT_COMMENT = 'RESET_ALERT_COMMENT'
export const GET_ALERTLIST_SOCKET = 'GET_ALERTLIST_SOCKET'

// tenant-user in-house
export const GET_INHOUSELIST = 'GET_INHOUSELIST'
export const GET_INHOUSELIST_SOCKET = 'GET_INHOUSELIST_SOCKET'
export const UPDATE_IN_HOUSE_LIST = 'UPDATE_IN_HOUSE_LIST';


// default police-number
export const GET_DEFAULT_POLICENUMBER = 'GET_DEFAULT_POLICENUMBER'

// Alert
export const GET_ALERT_LIST = "GET_ALERT_LIST"
export const GET_ALERT_LIST_UPDATE = "GET_ALERT_LIST_UPDATE"

export const GET_ALERT_DETAIL = "GET_ALERT_DETAIL"
export const RESOLVE_ALERT = "RESOLVE_ALERT"
export const RESET_RESOLVE_ALERT = "RESET_RESOLVE_ALERT"
export const ADD_ALERT_COMMENT = "ADD_ALERT_COMMENT"
export const UPDATE_GPS = "UPDATE_GPS"
export const RESET_UPDATE_GPS = "RESET_UPDATE_GPS"
export const RESET_ALERT_LIST = "RESET_ALERT_LIST"
export const RESET_IN_HOUSE_LIST = "RESET_IN_HOUSE_LIST"
export const GET_IN_HOUSE_LIST = "GET_IN_HOUSE_LIST"
export const GET_IN_HOUSE_LOC_LIST = "GET_IN_HOUSE_LOC_LIST"
export const ADD_IN_HOUSE_LOC = "ADD_IN_HOUSE_LOC"
export const RESET_ADD_IN_HOUSE_LOC = "RESET_ADD_IN_HOUSE_LOC"
export const RESET_EDIT_IN_HOUSE_LOC = "RESET_EDIT_IN_HOUSE_LOC"
export const EDIT_IN_HOUSE_LOC = "EDIT_IN_HOUSE_LOC"
export const DEL_IN_HOUSE_LOC = "DEL_IN_HOUSE_LOC"
export const RESET_DEL_IN_HOUSE_LOC = "RESET_DEL_IN_HOUSE_LOC"
export const IS_IN_HOUSE_EDIT = "IS_IN_HOUSE_EDIT"
export const GET_IN_HOUSE_LOC_DETAIL = "GET_IN_HOUSE_LOC_DETAIL"
export const GET_IN_HOUSE_LIST_UPDATE = "GET_IN_HOUSE_LIST_UPDATE"
export const GET_IN_HOUSE_LIST_RESOLVE = "GET_IN_HOUSE_LIST_RESOLVE"
export const GET_ALERT_LIST_RESOLVE = "GET_ALERT_LIST_RESOLVE"
export const ALERT_LOG_LIST = "ALERT_LOG_LIST"

export const ADD_ACTION_FOCUSBOARD = "ADD_ACTION_FOCUSBOARD"
export const GET_ACTION_FOCUSBOARD = "GET_ACTION_FOCUSBOARD"
export const COMPLETE_ACTION_FOCUSBOARD = "COMPLETE_ACTION_FOCUSBOARD"
export const DELETE_ACTION_FOCUSBOARD = "DELETE_ACTION_FOCUSBOARD"
export const SET_ACTION_FOCUSBOARD_ID = "SET_ACTION_FOCUSBOARD_ID"

// export const GET_ALERT_LIST_UPDATE = "GET_ALERT_LIST_UPDATE"

//checkList 
export const SET_CHECKLIST = "SET_CHECKLIST";

// for admin panel dashboard dropdown and connect tenant popup
export const GET_SUBDIVISION_LIST = "GET_SUBDIVISION_LIST"
export const STATISTICS_COUNT = "STATISTICS_COUNT"
export const CONNECT_DISCONNECT_SUB = "CONNECT_DISCONNECT_SUB"
export const CONNECTED_SUBDIVISIONS = "CONNECTED_SUBDIVISIONS"
export const CONNECTED_SUB_SIDEBAR = "CONNECTED_SUB_SIDEBAR"
export const SEC_SUBDIV = "SEC_SUBDIV"