import { GET_USERSLIST, ADD_TENANT, EDIT_SUBDIVISION_USER, SET_USER, STATISTICS_COUNT } from "../../@jumbo/constant/ActionTypes";

const INIT_STATE = {
  users: [],
  edit: null

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERSLIST: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case SET_USER: {

      return {
        ...state,
        edit: action.payload,
      };
    }
    case ADD_TENANT: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }


    case EDIT_SUBDIVISION_USER: {
      return {
        ...state,
        allUsers: state.allUsers.map(allUsers => (allUsers.id === action.payload._id ? action.payload : allUsers)),
      };

    }

    case STATISTICS_COUNT: {
      return {
        ...state,
        counts: action.payload,
      };
    }
    default:
      return state;
  }
};
