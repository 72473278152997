import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// const useStyles = styled((theme) => ({

//   titleRoot: {
//     marginBottom: 2,
//     fontSize: 14,
//     letterSpacing: 0.25,
//     // fontWeight: "300",
//     color: theme.palette.common.dark,
//     textOverflow: "ellipsis",
//     maxWidth: "250px",
//     display: "block",
//     overflow: "hidden",
//     whiteSpace: "nowrap",
//   },
// }));

export default function UserListRow({ row, handleUserDelete, handleEdit, userProfile, onHandleClickMore }) {
  // let classes = useStyles();
  const labelId = `enhanced-table-checkbox-${row._id}`;

  return (
    <TableRow role="checkbox" tabIndex={-1} key={row._id}>

      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {!row.id ? "-" : row.id}
      </TableCell>
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {`${row.first_name}   ${row.last_name}`}
      </TableCell>
      {/* <TableCell>
        <Typography component="div" variant="h4" style={{
                marginBottom: 2,
                fontSize: 14,
                letterSpacing: 0.25,
                // fontWeight: "300",
                // color: theme.palette.common.dark,
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            > */}
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {!row.mobile ? "-" : row.mobile}
        {/* </Typography> */}
      </TableCell>
      {/* <TableCell>
        <Typography component="div" variant="h4" style={{
                marginBottom: 2,
                fontSize: 14,
                letterSpacing: 0.25,
                // fontWeight: "300",
                // color: theme.palette.common.dark,
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            > */}
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {!row.email ? "-" : row.email}
        {/* </Typography> */}
      </TableCell>
      {/* <TableCell>
        <Typography component="div" variant="h4" style={{
                marginBottom: 2,
                fontSize: 14,
                letterSpacing: 0.25,
                // fontWeight: "300",
                // color: theme.palette.common.dark,
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            > */}
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {!row.firm_name ? "-" : row.firm_name}
        {/* </Typography> */}
      </TableCell>
      {/* <TableCell>
        <Typography component="div" variant="h4" style={{
                marginBottom: 2,
                fontSize: 14,
                letterSpacing: 0.25,
                // fontWeight: "300",
                // color: theme.palette.common.dark,
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            > */}
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {/* {!row.email?"-":`${!!row ?row.number_of_licenses? row.number_of_licenses.assigned:null:null} / ${!!row ? row.number_of_licenses ? row.number_of_licenses.used:null:null}`} */}
        {!row.number_of_licenses
          ? "-"
          : ` ${!!row
            ? row.number_of_licenses
              ? row.number_of_licenses.used
              : null
            : null
          } / ${!!row
            ? row.number_of_licenses
              ? row.number_of_licenses.assigned
              : null
            : null
          }`}
        {/* </Typography> */}
      </TableCell>
      {/* <TableCell>
        <Typography component="div" variant="h4" style={{
                marginBottom: 2,
                fontSize: 14,
                letterSpacing: 0.25,
                // fontWeight: "300",
                // color: theme.palette.common.dark,
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            > */}
      <TableCell
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
      >
        {row.status === 1 ? (
          <FiberManualRecordIcon
            style={{
              fontSize: "0.5rem",
              color: "rgb(95, 170, 176)",
              marginRight: "5px",
            }}
          />
        ) : (
          ""
        )}
        {row.status === 2 ? (
          <FiberManualRecordIcon
            style={{
              fontSize: "0.5rem",
              color: "rgb(225, 108, 108)",
              marginRight: "5px",
            }}
          />
        ) : (
          ""
        )}
        {row.status === 1 ? `Active` : "Disabled"}
        {/* </Typography> */}
      </TableCell>

      <TableCell align="center">
        {userProfile && userProfile?.user_type == 2 ?
          <button
            style={{
              marginRight: "40px",
              backgroundColor: "transparent",
              cursor: "pointer",
              border: "0px",
              outline: "0px",
              boxShadow: "none",
              color: " rgb(95, 170, 176)",
              fontSize: "12px",
              padding: "0px",
            }}
            onClick={() => onHandleClickMore(row._id)}
          >
            More
          </button> : (<>
            <button
              style={{
                marginRight: "40px",
                backgroundColor: "transparent",
                cursor: "pointer",
                border: "0px",
                outline: "0px",
                boxShadow: "none",
                color: " rgb(95, 170, 176)",
                fontSize: "12px",
                padding: "0px",
              }}
              onClick={() => handleEdit(row._id)}
            >
              Edit
            </button>
            <button
              style={{
                marginRight: "40px",
                backgroundColor: "transparent",
                cursor: "pointer",
                border: "0px",
                outline: "0px",
                boxShadow: "none",
                color: " rgb(225, 108, 108)",
                fontSize: "12px",
                padding: "0px",
              }}
              onClick={() => handleUserDelete(row)}
            >
              Delete
            </button>
          </>)}
      </TableCell>
    </TableRow>
  );
}
