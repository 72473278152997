import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import SubDivision from './SubDivision';
import auth from './authentication.reducer';
import ExtController from './ExtController';
import message from './message'
import tenant from './tenant';
import travel from './travel';
import users from './users';
import userProfile from './Settings'
import importantContact from './importantContact'
import usefulLinkforTravel from './usefulLinkforTravel';
import Common from './Common';
import localPoliceNumber from './localPoliceNumber';
import alert from './alert';
import CrisisLogAlert from './CrisisLogAlert';
import CrisisLogInHouse from './CrisisLogInHouse';
import DefaultPoliceNumber from './DefaultPoliceNumber';
import FOCUSBOARD from './focusBoard';
import Checklist from './checklist';
import refreshToken from './PREVIOUSFUNCTION';

export default history =>
    combineReducers({
        router: connectRouter(history),
        SUBDIVISION: SubDivision,
        EXTCONTROLLER: ExtController,
        auth,
        message,
        TENANT: tenant,
        USERPROFILE: userProfile,
        TRAVEL: travel,
        USERS: users,
        IMPORTANTCONTACT: importantContact,
        USEFULLINKTRAVEL: usefulLinkforTravel,
        common: Common,
        LOCALPOLICENUMBER: localPoliceNumber,
        ALERT: alert,
        CRISISLOGALERT: CrisisLogAlert,
        CRISISLOGINHOUSE: CrisisLogInHouse,
        POLICENUMBER: DefaultPoliceNumber,
        FOCUSBOARD: FOCUSBOARD,
        CHECKLIST: Checklist,
        // PREVIOUSFUNCTION: refreshToken,
    });
