import { Alert } from "@mui/material";
import {
  ADD_USER,
  GET_TENANTLIST,
  GET_USER_DETAILS,
  ADD_TENANT,
  SET_TENANT,
} from "../../@jumbo/constant/ActionTypes";
import {
  AddTenant,
  tenantList,
  getTenantDetails,
  editSubdivision,
  EditTenant,
  DeleteTenant,
  DeleteTenantByAdmin,
  allUserList,
} from "../../@jumbo/constant/ApiConstant";
import axios from "../../helper/customAxios";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_DATA_SUCCESS,
} from "../../@jumbo/constant/ActionTypes";
import { refreshToken } from "./Auth";


export const getTenantList = (
  user_type,
  sortBy,
  sort,
  searchValue,
  activePage,
  activePerPage
) => {
  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(fetchStart());
    axios
      .get(tenantList, {
        params: {
          user_type: user_type,
          page: activePage,
          limit: activePerPage,
          sortBy: `${sortBy}:${sort}`,
          q: searchValue,
        },
      })
      .then((data) => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          if (!data.data.data.userData.length) {
            dispatch({ type: GET_TENANTLIST, payload: [] });
          } else {
            dispatch({ type: GET_TENANTLIST, payload: data.data.data });
          }
        } else {
          dispatch({ type: GET_TENANTLIST, payload: [] });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getTenantList(user_type, sortBy, sort, searchValue, activePage, activePerPage))
          }))
          return;
        }
        if (error.response && error.response.status === 401 || error.response && error.response.status === 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const addNewTenant = (user, callbackFun) => {
  return (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
        "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
      }
    };
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(fetchStart());
    axios
      .post(AddTenant, user, config)
      .then((data) => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status == 200) {
          if (callbackFun) callbackFun.setAddDialog();
          setTimeout(() => {
            dispatch(fetchSuccess("Tenant added successfully."));
          }, 4000);
        } else {
          dispatch({ type: ADD_TENANT, payload: [] });
          dispatch(
            fetchError("There was something issue in responding server.")
          );
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addNewTenant(user, callbackFun))
          }))
          return;
        }
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addNewTenant(user, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          // if (error.response && error.response.status === 401 || error.response && error.response.status === 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

// set current user for view tab
// export const setTenantList = user => {
//   return dispatch => {
//     dispatch({ type: SET_TENANT, payload: user });
//   };
// };

export const getCurrTenantDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    axios
      .get(getTenantDetails, {
        params: {
          user_id: id,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: SET_TENANT, payload: data.data.data });
        } else {
          dispatch({ type: SET_TENANT, payload: [] });
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getCurrTenantDetail(id))
          }))
          return;
        }
        if (error.response && error.response.status === 401 || error.response && error.response.status === 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });
  };
};
export const updateTenant = (data, callbackFun, list) => {
  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(fetchStart());
    axios
      .put(EditTenant, data)
      .then((response) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun.setAddDialog();
          setTimeout(() => {
            dispatch(fetchSuccess("Tenant updated successfully."));
          }, 4000);
          // if(list) list()
        } else {
          dispatch(
            fetchError("There was something issue in responding server.")
          );
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateTenant(data, callbackFun, list))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          // if (error.response && error.response.status === 401 || error.response && error.response.status === 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const updateSubdivision = (user) => {
  return (dispatch) => {
    axios.put(editSubdivision, user).then((data) => {
      // setTimeout(() => {
      //   dispatch(fetchSuccess('Service activity updated successfully .'));
      // }, 2000);
      //   if (callbackFun) callbackFun();
      // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });
    });
    // .catch(error => {

    //   if(error?.response?.status===401 ){
    //     dispatch(setAuthUser(null));
    //     history.push('/signin')
    //   }
    //   else {
    //     console.log('error',error?.response?.status)
    //     dispatch(fetchError(error?.response?.data?.message));
    //   }
    // });
  };
};

export const deleteUser = (userId, callbackFun, list) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .delete(DeleteTenantByAdmin + userId)
      .then((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            if (callbackFun) callbackFun.setAddDialog();
          }, 1000);
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess("Tenant deleted Sucessfully."));
          }, 4000);
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(deleteUser(userId, callbackFun, list))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

// orderBy, order, searchValue, activePage, activePerPage
export const getAllTenantsListForSEC = (sortBy, sort, searchValue, activePage, activePerPage) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStart());
    axios.get(allUserList, {
      params: {
        user_type: 3, page: activePage, limit: activePerPage, sortBy: `${sortBy}:${sort}`, q: searchValue, is_pagination: true,
      },
    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          if (!data.data.data.userData.length) {
            dispatch({ type: GET_TENANTLIST, payload: [] });
          } else {
            dispatch({ type: GET_TENANTLIST, payload: data.data.data });
            dispatch(fetchSuccess(data.message));
          }

          // dispatch({ type: GET_ALL_USERS, payload: data.data.data });
        } else {

          dispatch({ type: GET_TENANTLIST, payload: [] });
          dispatch(fetchError(data.message));
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getAllTenantsListForSEC(sortBy, sort, searchValue, activePage, activePerPage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // alert('null');
          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};

export const getAllTenantsListForDashboard = (sortBy, sort, searchValue, activePage, activePerPage) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStart());
    axios.get(allUserList, {
      params: {
        user_type: 3, is_pagination: false
      },
    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          if (!data.data.data.userData.length) {
            dispatch({ type: GET_TENANTLIST, payload: [] });
          } else {
            dispatch({ type: GET_TENANTLIST, payload: data.data.data });
            dispatch(fetchSuccess(data.message));
          }
          // dispatch({ type: GET_ALL_USERS, payload: data.data.data });
        } else {
          dispatch({ type: GET_TENANTLIST, payload: [] });
          dispatch(fetchError(data.message));
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getAllTenantsListForDashboard(sortBy, sort, searchValue, activePage, activePerPage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // alert('null');
          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};
