import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Form, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserTableHead from "./UserTableHead";
import { useDebounce } from "utils/commonHelper";
import UserListRow from "./UserListRow";
import {
  getTenantList,
} from "redux/actions/tenant";
import {
  getImportantContactList,
  setCurrentImportContact,
  removeImportantContacts,
} from "redux/actions/importantContact";
import ConfirmDltDialog from "../../ConfirmDltDialog";
import AddEditUser from "./AddEditUser";
import AddConfirmDialog from "../../AddConfirmDialog";
import { } from "../../Textfeild";
import { USER_TYPE } from "utils/commonHelper";
import ReactTable from "components/common/table/ReactTable2";

export default function ImportantContact({
  open,
  onClose,
  onConfirm,
  setOpenPassConfirmDialog,
  butttonValue,
  setButttonValue,
}) {
  const { contactList, currentDetails } = useSelector(({ IMPORTANTCONTACT }) => IMPORTANTCONTACT);
  // const Profile = JSON.parse(localStorage.getItem("profile"));
  const { currentuser, loader } = useSelector(({ USERPROFILE }) => USERPROFILE);
  const [page, setPage] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const [order, setOrder] = React.useState("desc");
  const [openAddConfirmDialog, setOpenAddConfirmDialog] = useState(false);
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false);
  const [orderBy, setOrderBy] = React.useState("first_name");
  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(10);
  const [checkUser, setcheckUser] = useState()
  const [control_user, setcontrol_user] = useState()
  const dispatch = useDispatch();
  let user_type = 3;
  let successMessage = selectedUser ? `Contact was deleted successfully!` :
    currentDetails ? `Contact edited successfully!` : `Contact added successfully!`
  const Profile = JSON.parse(localStorage.getItem("profile"));
  // console.log("Profile",Profile)

  useEffect(() => {
    //     if(Profile.user_type !== 3 && Profile.user_type !== 1)
    //     {
    // dispatch(getImportantContactList());
    // }
    setcheckUser(currentuser && currentuser.detail ? currentuser.user_type : null)
    setcontrol_user(currentuser && currentuser.detail ? currentuser.control_user : null)
  }, [currentuser])


  useEffect(() => {
    USER_TYPE.SUBDIVISION_USER && (USER_TYPE.END_USER && control_user) && dispatch(getImportantContactList());
  }, []);

  const handleEdit = (user) => {
    dispatch(setCurrentImportContact(user));
    setOpenPassConfirmDialog(true);
  };

  const handleDelete = (id) => {
    setSelectedUser(id);
    setopenConfirmDltDialog(true);
  };

  const handleCancelDelete = () => {
    setopenConfirmDltDialog(false);
  };

  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(
      removeImportantContacts(
        selectedUser,
        { setAddDialog: () => setOpenAddConfirmDialog(true) },
        // getImportantContactList
      )
    );
  };

  const handleCancelConfirm = () => {
    setOpenAddConfirmDialog(false);
    dispatch(getImportantContactList());
    setTimeout(() => setSelectedUser(""), 1000)
  };

  const handleConfirm = () => {
    setOpenAddConfirmDialog(false);
    // dispatch(getImportantContactList());
    setTimeout(() => setSelectedUser(""), 1000)
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const columns = [
    {
      id: "name",
      accessor: "name",
      title: "Title",

    },
    {
      id: "phone_number",
      accessor: "phone_number",
      title: "Phone number",
    },
    {
      id: "email",
      accessor: "email",
      title: "Email",
    },
    {
      id: 'actions',
      accessor: 'actions',
      title: 'Actions',
      width: '20%',
      Component: ({ row }) => {
        return (
          <>
            <span className="text-[#4892c3] cursor-pointer" onClick={() => handleEdit(row)} >
              Edit
            </span>
            <button className="text-[#e17070] ml-2 cursor-pointer" onClick={() => handleDelete(row?._id)}>
              Delete
            </button>
          </>
        );
      }
    },
  ];
  return (
    <>
      <div className="mainDiv1">
        <ReactTable
          COLUMNS={columns}
          DATA={contactList?.important_contact}
          fetchData={() => { }}
          pagination={false}
          hiddenColumns={[USER_TYPE.CONTROL_USER, USER_TYPE.END_USER, USER_TYPE.EXTERNAL_CONTROLLER].includes(Profile?.user_type) ? ['actions'] : []}
        />
        {open ? (
          <AddEditUser
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            setOpenPassConfirmDialog={setOpenAddConfirmDialog}
            butttonValue={butttonValue}
            setButttonValue={setButttonValue} />) : ("")}
        <ConfirmDltDialog
          open={openConfirmDltDialog}
          content={`Are you sure you want to delete this contact?`}
          btnLabels={{
            no: "No",
            Yes: "Yes",
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <AddConfirmDialog
          open={openAddConfirmDialog}
          content={successMessage}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
      </div>
    </>
  );
}
