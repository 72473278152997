import React, { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Dialog, IconButton, Button, FormGroup, Checkbox, FormControlLabel } from '@mui/material';

const ConnectTennantDialog = ({ open, onClose, allUsers, onHandleConnect }) => {
    const [options, setOptions] = useState([]);

    const onHandleCheck = (check, sub) => {
        const updatedArray = options.map((a) => {
            if (a?.value === sub?.value) {
                return { ...a, is_connected: check }
            } else {
                return a
            }
        })
        setOptions(updatedArray);
    }

    const onClickToConnect = () => {
        let data = [];
        options.filter((s) => {
            if (s?.is_connected === true) {
                data.push(s?.value);
            }
        })
        onHandleConnect({ subdivisions: data });
    }

    useEffect(() => {
        if (allUsers && allUsers?.length > 0) {
            let userArray = allUsers.map((a) => {
                return { label: `${a?.first_name}${" "}${a?.last_name}`, value: a?._id, is_connected: a?.is_connected }
            })
            setOptions(userArray);
        } else {
            setOptions([]);
        }
    }, [allUsers])

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}
            maxWidth="xs"
            // TransitionProps={{ onEntering: handleEntering }}
            open={open}
        // {...other}
        >
            <DialogTitle> Subdivisions
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    x {/* <CloseIcon /> */}
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    {options && options?.length > 0 ? options.map((option, i) => (
                        <FormControlLabel
                            value={option?.value}
                            key={i}
                            checked={option?.is_connected}
                            control={<Checkbox />}
                            label={option?.label}
                            onChange={(e) => onHandleCheck(e.target.checked, option)}
                        />
                    )) : "No subdivision found"}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="Outline" onClick={onClickToConnect}>Connect</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConnectTennantDialog;