import { Box } from "@mui/system";
import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AntSwitch } from "components/antSwitch/AntSwitch";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addSubdivision,
  getAllUsersList,
} from "redux/actions/SubDivision";
import ConfirmDialog from "components/ConfirmDialog";
// import AddConfirmDialog from "components/AddConfirmDialog";
import { useNavigate } from "react-router";
import {
  // Button,
  Container,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./style";

import {
  requiredMessage,
  lettersOnlyMessage,
  phoneOnlyNumericals,
  onlyNumberAllow,
  emailNotValid,
  alphaNumaricMessage,
} from "@jumbo/constant/ErrorMessages";
import {
  stringOnly,
  emptySpace,
  phoneNumberValidation,
  emailvalidation,
  alphaNumaric,
  numberOnly,
  stringCheck,
} from "@jumbo/constant/ValidationRegex";
import AddConfirmDialog from "../AddConfirmDialog";

// import {stringOnly} from 'utils/commonHelper'
import { ButtonCancel } from "../cancelButton";
import { Buttons } from "../Button";
import { TextFields } from "../Textfeild";
import { BootstrapInput } from "../DropDown";
import { CustomLabel } from "../DropDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ariaLabel = { "aria-label": "description" };

export default function AddSubdivision() {
  const { currentuser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
  const Profile = JSON.parse(localStorage.getItem("profile"));
  // const [age, setAge] = React.useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subdivisionName, setSubdivisionName] = useState("");
  const [contractID, setContractID] = useState("");
  const [numberOflicence, setNumberOflicence] = useState("");
  const [emailId, setEmailId] = useState("");
  const [address, setaddress] = useState("");
  const [status, setStatus] = useState(1);
  const [alertTravel, setAlertTravel] = useState(true);
  const [inHouse, setInHouse] = useState(true);
  // const [inhous_alert, setInhous_alert] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [smsTurnOnOff, setSmsTurnOnOff] = useState(true)
  const [pushNotification, setPushNotification] = useState(true)
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [subdivisionNameError, setSubdivisionNameError] = useState("");
  const [contractIDError, setContractIDError] = useState("");
  const [numberOflicenceError, setNumberOflicenceError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [dropdownIcon, setDropdownIcon] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const user_type = 4;
  let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let number = /^([0-9])*$/;
  let phone = /^(\+)([0-9]{9,15})$/;
  let check = /^[a-zA-Z0-9 ]*$/;

  const handleSaveBtn = () => {
    // setAlertTravel(!alertTravel ? 0:1);
    // setInHouse(!inHouse ? 0:1)
    // alert(status);
    // alert(alertTravel);
    // alert(inHouse);
    // alert("fname");
    if (!fname || fname.match(emptySpace)) {
      setHelperText(true)
      setFnameError(requiredMessage);
    } else if (!fname.match(stringCheck)) {
      setHelperText(true)
      setFnameError(lettersOnlyMessage);
    }
    if (!lname || lname.match(emptySpace)) {
      setHelperText(true)
      setLnameError(requiredMessage);
    } else if (!lname.match(stringCheck)) {
      setHelperText(true)
      setLnameError(lettersOnlyMessage);
    }
    if (!phoneNumber || phoneNumber.match(emptySpace)) {
      setHelperText(true)
      setPhoneNumberError(requiredMessage);
    } else if (!phoneNumber.match(phone)) {
      setHelperText(true)
      setPhoneNumberError(phoneOnlyNumericals);
    }
    if (!subdivisionName || subdivisionName.match(emptySpace)) {
      setHelperText(true)
      setSubdivisionNameError(requiredMessage);
    } else if (!subdivisionName.match(check)) {
      setHelperText(true)
      setSubdivisionNameError(alphaNumaricMessage);
    }
    if (!contractID || contractID.match(emptySpace)) {
      setHelperText(true)
      setContractIDError(requiredMessage);
    } else if (!contractID.match(alphaNumaric)) {
      setHelperText(true)
      setContractIDError(alphaNumaricMessage);
    }
    if (!numberOflicence || numberOflicence.match(emptySpace)) {
      setHelperText(true)
      setNumberOflicenceError(requiredMessage);
    } else if (!numberOflicence.match(number)) {
      setHelperText(true)
      setNumberOflicenceError(onlyNumberAllow);
    }
    if (!emailId || emailId.match(emptySpace)) {
      setHelperText(true)
      setEmailIdError(requiredMessage);
    } else if (!emailId.match(email)) {
      setHelperText(true)
      setEmailIdError(emailNotValid);
    }
    if (!address || address.match(emptySpace)) {
      setHelperText(true)
      setaddressError(requiredMessage);
    }

    if (
      fname &&
      fname.match(stringCheck) &&
      !fname.match(emptySpace) &&
      lname &&
      lname.match(stringCheck) &&
      !lname.match(emptySpace) &&
      phoneNumber &&
      phoneNumber.match(phone) &&
      !phoneNumber.match(emptySpace) &&
      subdivisionName &&
      subdivisionName.match(check) &&
      !subdivisionName.match(emptySpace) &&
      contractID &&
      contractID.match(alphaNumaric) &&
      !contractID.match(emptySpace) &&
      numberOflicence &&
      numberOflicence.match(number) &&
      !numberOflicence.match(emptySpace) &&
      emailId &&
      emailId.match(email) &&
      !emailId.match(emptySpace) &&
      address &&
      !address.match(emptySpace) &&
      status
    ) {
      // setInhous_alert(obje);
      onUserSave();
    }
  };
  const onUserSave = () => {
    const obje = {
      alert_travel: alertTravel,
      in_house: inHouse,
    };
        const user = {
      email: emailId,
      first_name: fname,
      last_name: lname,
      status: status,
      contract_id: contractID,
      number_of_licenses: Number(numberOflicence),
      firm_name: subdivisionName,
      has_access_to: obje,
      address: address,
      mobile: phoneNumber,
      user_type: user_type,
      sms_switch: smsTurnOnOff,
      notification_switch: pushNotification
      }

    dispatch(
      addSubdivision(
        user,
        { setAddDialog: () => setOpenConfirmDialog(true) },
        () => dispatch(getAllUsersList())
      )
    );
  };
  const handleCancle = () => {
    navigate(`/app/Subdivisions`);
  };
  const handleCanceldialog = useCallback(() => {
    setOpenConfirmDialog(false);
    navigate(`/app/Subdivisions`);
  }, []);
  const handleConfirm = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);
  const [confirmDialogData, setConfirmDialogData] = useState({
    content: `All changes saved successfully.`,
    btnLabels: {
      ok: "Ok",
    }
  })
  return (
    <div className="mainDiv1">
      <div className="heading-section mb-48">
        <div className="pageTitle">Add subdivision</div>
      </div>

      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            width: "50ch",

            width: "100%",
          },
          flexGrow: 1,
        }}
        noValidate
        autoComplete="off"
        style={{ color: "rgba(0, 0, 0, 0.87)" }}
      >
        <Grid container ml={0} className="formGrid">
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="First Name"
              variant="standard"
              required
              value={fname}
              onChange={(e) => {
                setFname(e.target.value.slice(0, 16));
                setFnameError("");
                setHelperText(false)
              }}
              helperText={fnameError}
              helperProps={fnameError ? helperText : false}
              errorProps={fnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Last Name"
              variant="standard"
              value={lname}
              onChange={(e) => {
                setLname(e.target.value.slice(0, 16));
                setLnameError("");
                setHelperText(false)
              }}
              helperText={lnameError}
              helperProps={lnameError ? helperText : false}
              errorProps={lnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Phone number"
              variant="standard"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setPhoneNumberError("");
                setHelperText(false)
              }}
              helperText={phoneNumberError}
              helperProps={phoneNumberError ? helperText : false}
              errorProps={phoneNumberError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Subdivision Name"
              variant="standard"
              value={subdivisionName}
              onChange={(e) => {
                setSubdivisionName(e.target.value);
                setSubdivisionNameError("");
                setHelperText(false)
              }}
              helperText={subdivisionNameError}
              helperProps={subdivisionNameError ? helperText : false}
              errorProps={subdivisionNameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Contract ID"
              variant="standard"
              value={contractID}
              onChange={(e) => {
                setContractID(e.target.value);
                setContractIDError("");
                setHelperText(false)
              }}

              helperText={contractIDError}
              helperProps={contractIDError ? helperText : false}
              errorProps={contractIDError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Number of licenses"
              variant="standard"
              value={numberOflicence}
              onChange={(e) => {
                setNumberOflicence(e.target.value);
                setNumberOflicenceError("");
                setHelperText(false)
              }}
              helperText={numberOflicenceError}
              helperProps={numberOflicenceError ? helperText : false}
              errorProps={numberOflicenceError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Email"
              variant="standard"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
                setEmailIdError("");
                setHelperText(false)
              }}
              helperText={emailIdError}
              helperProps={emailIdError ? helperText : false}
              errorProps={emailIdError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              required
              id="standard-basic"
              label="Address"
              variant="standard"
              value={address}
              onChange={(e) => {
                setaddress(e.target.value);
                setaddressError("");
                setHelperText(false)
              }}
              helperText={addressError}
              helperProps={addressError ? helperText : false}
              errorProps={addressError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <FormControl
              sx={{ m: 1, minWidth: "100%", margin: 0 }}

            >
              <CustomLabel id="demo-customized-select-label"  >
                Status
              </CustomLabel>
              {/* <p style={{color: 'rgb(144, 153, 170) ',fontFamily:'SF Pro Text',fontSize:'12px'}}>Status</p> */}
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setStatusError("");
                }}
                helperText={statusError}
                input={<BootstrapInput />}
                onOpen={() => setDropdownIcon(true)}
                onClose={() => setDropdownIcon(false)}
                IconComponent={() => (
                  dropdownIcon ? <i className="icon-arrow-up" style={{ cursor: 'pointer', position: 'absolute', right: 0, zIndex: -1, color: 'rgb(133, 142, 160)' }}
                  ></i> : <i className="icon-arrow-down" style={{ cursor: 'pointer', position: 'absolute', right: 0, zIndex: -1, color: 'rgb(133, 142, 160)' }}
                  ></i>
                )}
              >
                <MenuItem value={1}>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.5rem",
                      color: "rgb(95, 170, 176)",
                      marginRight: "4px",

                    }}
                  />
                  Active
                </MenuItem>
                <MenuItem value={2}>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.5rem",
                      color: "rgb(225, 108, 108)",
                      marginRight: "4px",

                    }}
                  />
                  Disabled
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <InputLabel id="demo-simple-select-label"
          // className="lable-style mb-5"
          className={'lable-style mb-5 ' + (addressError || phoneNumberError || lnameError || fnameError || emailIdError
            || numberOflicenceError || subdivisionNameError
            ? 'lable-red' : 'lable-style mb-5')}
        >

          {!addressError && !emailIdError && !numberOflicenceError && !subdivisionNameError
            && !phoneNumberError && !fnameError && !lnameError ?
            '* all fields required' : '* Please fill all required fields'}
          {/* * all fields required */}
        </InputLabel>
      </Box>
      <Box
        style={{
          position: "relative",
          width: "100%",
          minHeight: "100%",
          margin: "0px",
        }}
      >
        <div className="heading-section">
          <div className="pageTitle">Has access to</div>
        </div>

        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              width: "50ch",
              mb: 4,
              width: "100%",
            },
            flexGrow: 1,
          }}
          noValidate
          autoComplete="off"
          style={{ color: "rgba(0, 0, 0, 0.87)" }}
        >
          <Grid container spacing={2} ml={0} className="formGrid">
            <Grid
              item
              xs={6}
              mb={2}
              style={{ position: "relative" }}
              className="gridText"
            >
              <TextFields
                id="standard-basic"
                variant="standard"
                value="Alert & Travel"
                textProps={true}
                disabled
              />

              <AntSwitch
                value={alertTravel}
                onChange={() => {
                  setAlertTravel(!alertTravel);
                }}
                checked={alertTravel}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Grid>
            <Grid
              item
              xs={6}

              mb={2}
              style={{ position: "relative", display: "flex" }}
              className="gridText"
            >
              <TextFields
                id="standard-basic"
                variant="standard"
                value="In house"
                textProps={true}
                disabled
              />

              <AntSwitch
                value={inHouse}
                onChange={() => {
                  setInHouse(!inHouse);
                }}
                checked={inHouse}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Grid>
          </Grid>
        </Box>
        <ButtonCancel

          onClick={handleCancle}
        >
          {" "}
          cancel
        </ButtonCancel>
        <Buttons
          onClick={handleSaveBtn}

          buttoncheck={true}
        >
          {" "}
          Save
        </Buttons>
      </Box>
      <AddConfirmDialog
        open={openConfirmDialog}
        // title={`Delete Subdivision`}
        content={confirmDialogData?.content}
        btnLabels={confirmDialogData?.btnLabels}
        onClose={handleCanceldialog}
        onConfirm={handleConfirm}
      />
    </div>
  );
}
