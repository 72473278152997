import React, { useEffect, useRef } from 'react'
import CheckList from 'modules/CrisisLog/logActivity/checkList';
import { Buttons } from 'modules/Button';
import { getCheckList } from 'redux/actions/checklist';
import { useDispatch, useSelector } from 'react-redux';
const Checklist = ({ alertType }) => {
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    useEffect(() => {
        localStorage.setItem('crisisTabIndex', alertType?.tab);
    }, []);
    const openAddChecklistBox = useRef();
    const dispatch = useDispatch();
    const checklist = useSelector(store => store?.CHECKLIST);
    useEffect(() => {
        dispatch(getCheckList({ type: alertType.value, subdivision_id: secSubdivisionUser }));
    }, []);
    return (
        <>
            <div className='flex justify-between'>
                <div>
                    <div className='text-left text-[22px] w-full text-[#5c6373] capitalize'> {alertType?.title} Checklist ({checklist?.checklist?.length || 0}) </div>
                    {/* <div className='text-[#aeb4c0]'> Latest checklist always on top </div> */}
                </div>
                <div>
                    <Buttons onClick={() => {
                        openAddChecklistBox.current(true);
                    }} type='button'> {'Add checklist item'}</Buttons>
                </div>
            </div>
            <CheckList isCommonChecklist={true} alertType={alertType} openAddChecklistBox={openAddChecklistBox} />
        </>
    )
}

export default Checklist;