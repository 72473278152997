import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Loader from "react-loader";
// import {useStyles} from '@mui/material';
// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { AntSwitch } from "components/antSwitch/AntSwitch";
import { FormControlLabel, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    addSubdivision,
    getAllUsersList,
} from "redux/actions/SubDivision";
import {
    addNewUser,
    getUsersList,
    updateUser,
    getCurrUserDetail,
} from "redux/actions/users";
import {
    // Button,
    Container0,
    Container1,
    Button1,
    Button2,
    Container2,
    Container3,
} from "../index.style";
// import "antd/dist/antd.css";
// import { Switch } from "antd";
import {
    requiredMessage,
    lettersOnlyMessage,
    phoneOnlyNumericals,
    onlyNumberAllow,
    noSpaceMessage,
    emailNotValid,
    alphaNumaricMessage,
} from "@jumbo/constant/ErrorMessages";
import {
    stringOnly,
    emptySpace,
    phoneNumberValidation,
    emailvalidation,
    alphaNumaric,
    numberOnly,
    stringCheck
} from "@jumbo/constant/ValidationRegex";
import { Form, useNavigate } from "react-router-dom";
// import {stringOnly} from 'utils/commonHelper'
import AddConfirmDialog from "../../AddConfirmDialog";
import { useParams } from "react-router-dom";
import { Buttons } from "../../Button";
import { ButtonCancel } from "../../cancelButton";
import { TextFields } from "../../Textfeild";
import Grid from '@mui/material/Grid';
import { CustomLabel } from "../../DropDown";
import { BootstrapInput } from "../../DropDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { idGenerator } from "utils/commonHelper";
const ariaLabel = { "aria-label": "description" };

export default function AddEditUser({ setAddDialog }) {
    let { id } = useParams();
    const { edit } = useSelector(({ USERS }) => USERS);
    // let classes = useStyles();
    let navigate = useNavigate();
    const { currentuser, loader, socektObj } = useSelector(({ USERPROFILE }) => USERPROFILE);
    const Profile = JSON.parse(localStorage.getItem("profile"));
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [subdivisionName, setSubdivisionName] = useState("");
    const [contractID, setContractID] = useState("");
    const [numberOflicence, setNumberOflicence] = useState("");
    const [emailId, setEmailId] = useState("");
    const [address, setaddress] = useState("");
    const [status, setStatus] = useState(1);
    // const [alertTravel, setAlertTravel] = useState(true);
    // const [inHouse, setInHouse] = useState(true);
    const [controlUSer, setControlUSer] = useState(false);
    const [inhous_alert, setInhous_alert] = useState();

    const [fnameError, setFnameError] = useState("");
    const [lnameError, setLnameError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [subdivisionNameError, setSubdivisionNameError] = useState("");
    const [contractIDError, setContractIDError] = useState("");
    const [numberOflicenceError, setNumberOflicenceError] = useState("");
    const [emailIdError, setEmailIdError] = useState("");
    const [addressError, setaddressError] = useState("");
    const [statusError, setStatusError] = useState("");
    const [textProps, setTextProps] = useState(true);
    // const [dialogOpen, setDialogOpen] = useState(true)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [dropdownIcon, setDropdownIcon] = useState(false);
    const [buttonProps, setButtonProps] = useState('addbutton');
    const [helperText, setHelperText] = useState(false);
    const [alertTravel, setAlertTravel] = useState(true)
    const [inHouse, setInHouse] = useState(true)
    const [inHouseLogin, setInHouseLogin] = useState()
    const [alertTravelLogin, setAlertTravelLogin] = useState()
    console.log("currentuser", currentuser?.detail?.has_access_to?.alert_travel)



    const dispatch = useDispatch();
    const user_type = 7;
    let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let number = /^([0-9])*$/;
    let phone = /^(\+)([0-9]{9,15})$/;
    // let phone = /^([\\+])?([0-9]{12})*$/;
    let loding = false;

    useEffect(() => {
        setAlertTravel(currentuser?.detail?.has_access_to?.alert_travel)
        setInHouse(currentuser?.detail?.has_access_to?.in_house)
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(getCurrUserDetail(id));

        }
    }, [id]);


    useEffect(() => {
        setAlertTravelLogin(currentuser && currentuser.detail && currentuser.detail.has_access_to ? currentuser.detail.has_access_to.alert_travel : true)
        setInHouseLogin(currentuser && currentuser.detail && currentuser.detail.has_access_to ? currentuser.detail.has_access_to.in_house : true)
    }, [currentuser, socektObj])
    console.log("revoke", inHouseLogin)

    // useEffect(() => {
    //     setInHouse(Profile.has_access_to.in_house&&Profile.has_access_to.in_house)
    //     setAlertTravel(Profile.has_access_to.alert_travel&&Profile.has_access_to.alert_travel)
    // }, [Profile])


    useEffect(() => {
        if (edit) {
            if (edit.detail) {
                setFname(!!edit ? edit.detail.first_name : null);
                setLname(!!edit ? edit.detail.last_name : null);
                setPhoneNumber(!!edit ? edit.detail.mobile : null);
                // setSubdivisionName(!!edit ? edit.detail.firm_name:null)
                // setNumberOflicence(!!edit ? edit.detail.number_of_licenses.assigned:null)
                setEmailId(!!edit ? edit.detail.email : null);
                // setaddress(!!edit ? edit.detail.address:null)
                setStatus(!!edit ? edit.detail.status : null);
                if (edit.detail.has_access_to) {
                    setAlertTravel(
                        !!edit
                            ? edit.detail
                                ? edit.detail.has_access_to.alert_travel
                                : null
                            : null
                    );
                    setInHouse(
                        !!edit
                            ? edit.detail
                                ? edit.detail.has_access_to.in_house
                                : null
                            : null
                    );
                    setControlUSer(
                        !!edit ? (edit.detail ? edit.detail.control_user : null) : null
                    );
                }
            }
        }
    }, [edit]);

    const handleSaveBtn = () => {
        if (!fname || fname.match(emptySpace)) {
            setHelperText(true)
            setFnameError(requiredMessage);
        }
        else if (!fname.match(stringCheck)) {
            setHelperText(true)
            setFnameError(lettersOnlyMessage);
        }
        if (!lname || lname.match(emptySpace)) {
            setHelperText(true)
            setLnameError(requiredMessage);
        } else if (!lname.match(stringCheck)) {
            setHelperText(true)
            setLnameError(lettersOnlyMessage);
        }
        if (!phoneNumber || phoneNumber.match(emptySpace)) {
            setHelperText(true)
            setPhoneNumberError(requiredMessage);
        } else if (!phoneNumber.match(phone)) {
            setHelperText(true)
            setPhoneNumberError(phoneOnlyNumericals);
        }
        // if (!subdivisionName || subdivisionName.match(emptySpace)) {
        //   setSubdivisionNameError(requiredMessage);
        // } else if (!subdivisionName.match(alphaNumaric)) {
        //   setSubdivisionNameError(alphaNumaricMessage);
        // }
        // if (!contractID || contractID.match(emptySpace)) {
        //   setContractIDError(requiredMessage);
        // } else if (!contractID.match(alphaNumaric)) {
        //   setContractIDError(alphaNumaricMessage);
        // }
        // if (!numberOflicence || numberOflicence.match(emptySpace)) {
        //   if (!numberOflicence ) {
        //   setNumberOflicenceError(requiredMessage);
        // } else if (!numberOflicence.toString().match(numberOnly)) {
        //   setNumberOflicenceError(onlyNumberAllow);
        // }
        if (!emailId || emailId.match(emptySpace)) {
            setHelperText(true)
            setEmailIdError(requiredMessage);
        } else if (!emailId.match(email)) {
            setHelperText(true)
            setEmailIdError(emailNotValid);
        }
        // if (!address || address.match(emptySpace)) {
        //   setaddressError(requiredMessage);
        // }
        // if (!status || status.match(emptySpace)) {
        if (!status) {
            setHelperText(true)
            setStatus(requiredMessage);
        }

        if (
            fname &&
            !fname.match(emptySpace) &&
            lname &&
            fname.match(stringCheck) &&
            !lname.match(emptySpace) &&
            lname.match(stringCheck) &&
            phoneNumber &&
            !phoneNumber.match(emptySpace) &&
            phoneNumber.match(phone) &&
            // subdivisionName &&
            // !subdivisionName.match(emptySpace) &&
            // contractID &&
            // !contractID.match(emptySpace) &&
            // numberOflicence &&
            // !numberOflicence.match(emptySpace) &&
            emailId &&
            // !emailId.match(emptySpace) &&
            // address &&
            // !address.match(emptySpace)
            // &&
            status
        ) {
            // setInhous_alert(obje);
            onUserSave();
        }
    };
    const onUserSave = () => {
        const obje = {
            alert_travel: alertTravel,
            in_house: inHouse,
        };
        const user = {
            email: emailId,
            first_name: fname,
            last_name: lname,
            status: status,
            contract_id: "",
            number_of_licenses: 0,
            firm_name: "",
            has_access_to: obje,
            address: "",
            mobile: phoneNumber,
            user_type: user_type,
            control_user: controlUSer,
        };
        const userEdit = {
            user_id: !!edit ? edit.detail._id : null,
            email: emailId,
            first_name: fname,
            last_name: lname,
            status: status,
            contract_id: "",
            number_of_licenses: 0,
            // 'firm_name':'',
            has_access_to: obje,
            address: "",
            mobile: phoneNumber,
            user_type: user_type,
            control_user: controlUSer,
        };
        if (edit) {
            // dispatch(updateUser(userEdit,{setAddDialog: () => setOpenConfirmDialog(true),setAddDialogClose: () => setAddDialog(false)},() => dispatch(getUsersList())));
            dispatch(
                updateUser(
                    userEdit,
                    { setAddDialog: () => setOpenConfirmDialog(true) },
                    () => dispatch(getUsersList())
                )
            );
        } else {
            dispatch(
                addNewUser(
                    user,
                    { setAddDialog: () => setOpenConfirmDialog(true) },
                    () => dispatch(getUsersList())
                )
            );
        }
    };

    const handleCancle = () => {
        setOpenConfirmDialog(true);
    };
    const handleCancelDelete = () => {
        setOpenConfirmDialog(false);
        navigate(`/app/Users`);
    };
    const handleConfirmDelete = () => {
        setOpenConfirmDialog(false);
    };
    const handelCancel = () => {
        navigate(`/app/Users/`);
    };

    return (
        <div className="mainDiv1">
            <div className='heading-section mb-48'>
                <div className="pageTitle">
                    {edit ? "Edit user" : "Add user"}
                </div>
            </div>
            <Box
                component="form"
                sx={{
                    "& > :not(style)": {
                        width: "50ch",
                        mb: 0,
                        width: "100%",
                    },
                    flexGrow: 1
                }}
                noValidate
                autoComplete="off"
                style={{
                    color: "rgba(0, 0, 0, 0.87)"
                    // ,marginRight:'200px'

                }}
            >
                <Grid container spacing={2} ml={0} className="formGrid" >

                    <Grid item xs={12} mb={2} className="gridText" >
                        <TextFields
                            id="standard-basic"
                            label="First Name *"
                            variant="standard"
                            value={fname}
                            onChange={(e) => {
                                setFname(e.target.value.slice(0, 16));
                                setFnameError("");
                                setHelperText(false)
                            }}

                            helperText={fnameError}
                            helperProps={fnameError ? helperText : false}
                            errorProps={fnameError ? true : false}

                        />
                    </Grid>

                    <Grid item xs={12} mb={2} className="gridText">
                        <TextFields
                            id="standard-basic"
                            label="Last Name *"
                            variant="standard"
                            value={lname}
                            onChange={(e) => {
                                setLname(e.target.value.slice(0, 16));
                                setLnameError("");
                                setHelperText(false)
                            }}
                            helperText={lnameError}
                            helperProps={lnameError ? helperText : false}
                            errorProps={lnameError ? true : false}
                        />
                    </Grid>
                    <Grid item xs={12} mb={2} className="gridText">
                        <TextFields
                            id="standard-basic"
                            label="Phone number *"
                            variant="standard"
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                                setPhoneNumberError("");
                                setHelperText(false)
                            }}
                            helperText={phoneNumberError}
                            helperProps={phoneNumberError ? helperText : false}
                            errorProps={phoneNumberError ? true : false}
                        // error={phoneNumberError?true:false}
                        />
                    </Grid>
                    <Grid item xs={12} mb={2} className="gridText">
                        <TextFields
                            id="standard-basic"
                            label="Email *"
                            variant="standard"
                            value={emailId}
                            onChange={(e) => {
                                setEmailId(e.target.value);
                                setEmailIdError("");
                                setHelperText(false)
                            }}
                            helperText={emailIdError}
                            helperProps={emailIdError ? helperText : false}
                            errorProps={emailIdError ? true : false}
                        />
                    </Grid>


                    <Grid item xs={12} mb={2} className="gridText">
                        <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                            <CustomLabel id="demo-customized-select-label" style={{ marginLeft: 0 }}>Status</CustomLabel>
                            {/* <div> */}
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                style={{}}
                                value={status}
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                    setStatusError("");
                                }}
                                helperText={statusError}
                                defaultValue="Active"
                                input={<BootstrapInput />}
                                onOpen={() => setDropdownIcon(true)}
                                onClose={() => setDropdownIcon(false)}
                                IconComponent=
                                {
                                    () => (

                                        dropdownIcon ? <i className="icon-arrow-up" style={{ cursor: 'pointer', position: 'absolute', right: 0, zIndex: -1, color: 'rgb(133, 142, 160)' }}></i> : <i className="icon-arrow-down" onClick={() => setDropdownIcon(false)} style={{ cursor: 'pointer', position: 'absolute', right: 0, zIndex: -1, color: 'rgb(133, 142, 160)' }}></i>
                                    )
                                }
                            >



                                <MenuItem value={1} > <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(95, 170, 176)', marginRight: '4px' }} />Active</MenuItem>
                                <MenuItem value={2}><FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(225, 108, 108)', marginRight: '4px' }} />Inactive</MenuItem>
                                {/* <MenuItem value={3} disabled={status === 1 || status === 2 || status === 4 ? true : false}><FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'Orange', marginRight: '4px' }} />Pending</MenuItem> */}
                                {/* <MenuItem value={4} disabled={status === 1 || status === 2 ? true : false}><FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(119 42 42)', marginRight: '4px' }} />Rejected</MenuItem> */}

                            </Select>
                            {/* {dropdownIcon?<i className="icon-arrow-up" ></i>:<i className="icon-arrow-down"></i>}</div> */}
                        </FormControl>
                    </Grid>
                </Grid>
                <InputLabel
                    id="demo-simple-select-label"
                    // style={{ color: "rgb(78, 145, 192)" ,fontSize:"15px",font: "inherit"}}
                    className={'lable-style mb-5 ' + (emailIdError || phoneNumberError || lnameError || fnameError ? 'lable-red' : 'lable-style mb-5')}
                >
                    {!emailIdError && !phoneNumberError && !lnameError && !fnameError ? '* all fields required' : '* Please fill all required fields'}
                </InputLabel>
            </Box>
            <Box
                style={{
                    position: "relative",
                    width: "100%",
                    minHeight: "100%",
                    margin: "0px",
                }}
            >
                <div className='heading-section'>
                    <div className="pageTitle">
                        Licence
                    </div>
                </div>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": {
                            width: "50ch",
                            mb: 4,
                            width: "100%",
                        },
                        flexGrow: 1
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                    <Grid container spacing={2} ml={0} className="formGrid" >

                        <Grid item xs={6} mb={2} style={{ position: "relative", pointerEvents: alertTravelLogin ? null : 'none' }} className="gridText" >
                            <TextFields
                                defaultValue="Alert & Travel"
                                variant="standard"
                                textProps={true}
                                disabled
                            />
                            {edit ? (
                                <AntSwitch
                                    onChange={() => {
                                        setAlertTravel(!alertTravel);
                                    }}
                                    checked={alertTravel}
                                />
                            ) : (
                                <AntSwitch
                                    value={alertTravel}
                                    onChange={() => {
                                        setAlertTravel(!alertTravel);
                                    }}
                                    checked={!alertTravelLogin ? alertTravelLogin : alertTravel}
                                />
                            )}

                        </Grid>

                        <Grid item xs={6} md={5} mb={2} style={{ position: "relative", display: 'flex', pointerEvents: inHouseLogin ? null : 'none' }} className="gridText">
                            <TextFields
                                defaultValue="In house"
                                variant="standard"
                                textProps={true}
                            // disabled
                            />
                            {edit ? (
                                <AntSwitch
                                    onChange={() => {
                                        setInHouse(!inHouse);
                                    }}
                                    checked={inHouse}
                                // inputProps={{ 'aria-label': 'ant design' }}
                                />
                            ) : (

                                <AntSwitch
                                    value={inHouse}
                                    onChange={() => {
                                        setInHouse(!inHouse);
                                    }}
                                    checked={!inHouseLogin ? inHouseLogin : inHouse}
                                // inputProps={{ 'aria-label': 'ant design' }}

                                />
                            )}
                            <br />
                        </Grid>
                        <Grid item xs={6} mb={2} mt={5} style={{ position: "relative", display: 'flex' }} className="gridText">
                            <TextFields
                                defaultValue="Control user"
                                variant="standard"
                                textProps={true}
                                disabled
                            />
                            {edit ? (
                                <AntSwitch
                                    onChange={() => {
                                        setControlUSer(!controlUSer);
                                    }}
                                    checked={controlUSer}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                            ) : (
                                <AntSwitch
                                    value={controlUSer}
                                    onChange={() => {
                                        setControlUSer(!controlUSer);
                                    }}
                                    checked={controlUSer}
                                    inputProps={{ 'aria-label': 'ant design' }}

                                />
                            )}</Grid>
                    </Grid>
                </Box>
                <ButtonCancel onClick={handelCancel} > cancel</ButtonCancel>
                <Buttons onClick={handleSaveBtn} buttoncheck={buttonProps}> Save</Buttons>
            </Box>
            <AddConfirmDialog
                open={openConfirmDialog}
                // title={`Delete Subdivision`}
                content={`All changes saved successfully. `}
                btnLabels={{
                    ok: "OK",
                }}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

        </div>
    );
}
