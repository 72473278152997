import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
// import {useStyles} from '@mui/material';
// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";
import { FormControlLabel, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AntSwitch } from "components/antSwitch/AntSwitch";
import FormHelperText from "@mui/material/FormHelperText";
import { addExtController, getExternalUser } from "redux/actions/ExtController";
import FormGroup from "@mui/material/FormGroup";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import ConfirmDialog from "components/ConfirmDialog";

import Checkbox from "@mui/material/Checkbox";

// import "antd/dist/antd.css";
// import { Switch } from "antd";
import {
  requiredMessage,
  lettersOnlyMessage,
  phoneOnlyNumericals,
  onlyNumberAllow,
  emailNotValid,
  alphaNumaricMessage,

} from "@jumbo/constant/ErrorMessages";
import {
  stringOnly,
  emptySpace,
  phoneNumberValidation,
  emailvalidation,
  alphaNumaric,
  numberOnly,
  stringCheck
} from "@jumbo/constant/ValidationRegex";
import {
  // Button,
  Container,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./style";
// import {stringOnly} from 'utils/commonHelper'
import AddConfirmDialog from "../AddConfirmDialog";
import { Buttons } from '../Button'
import { ButtonCancel } from "../cancelButton";
import { TextFields } from "../Textfeild";
import { BootstrapInput } from "../DropDown";
import { CustomLabel } from "../DropDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { BootstrapCheckbox } from "../checkBox";
import { useParams } from "react-router-dom";
import { getCurrUserDetail, getsubUsers } from "redux/actions/ExtController";
import { CustomCheckedIcon } from './CustomCheckedIcon';
const ariaLabel = { "aria-label": "description" };


export default function AddExtController() {
  const { allExternalUsers, subdivison } = useSelector(({ EXTCONTROLLER }) => EXTCONTROLLER);
  let { id } = useParams();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState(1);
  const [alertTravel, setAlertTravel] = useState(true);
  const [inHouse, setInHouse] = useState(true);
  const [emailId, setEmailId] = useState('')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [dropdownIcon, setDropdownIcon] = useState(false)
  const [helperText, setHelperText] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const user_type = 5;
  let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let number = /^([0-9])*$/;
  let phone = /^(\+)([0-9]{9,15})$/;
  useEffect(() => {

    dispatch(getsubUsers())
  }, [id]);

  const handleCheck = (event) => {
    var permissions_array = [...permissions];
    if (event.target.checked) {
      permissions_array = [...permissions, event.target.value];
    } else {
      permissions_array.splice(permissions.indexOf(event.target.value), 1);
    }
    setPermissions(permissions_array);

  };
  const handleSaveBtn = () => {
    if (!fname || fname.match(emptySpace)) {
      setHelperText(true)
      setFnameError(requiredMessage);
    } else if (!fname.match(stringCheck)) {
      setHelperText(true)
      setFnameError(lettersOnlyMessage);
    }
    if (!lname || lname.match(emptySpace)) {
      setHelperText(true)
      setLnameError(requiredMessage);
    } else if (!lname.match(stringCheck)) {
      setHelperText(true)
      setLnameError(lettersOnlyMessage);
    }
    if (!phoneNumber || phoneNumber.match(emptySpace)) {
      setHelperText(true)
      setPhoneNumberError(requiredMessage);
    } else if (!phoneNumber.match(phone)) {
      setHelperText(true)
      setPhoneNumberError(phoneOnlyNumericals);
    }
    if (!emailId || emailId.match(emptySpace)) {
      setHelperText(true)
      setEmailIdError(requiredMessage);
    } else if (!emailId.match(email)) {
      setHelperText(true)
      setEmailIdError(emailNotValid);
    }
    // if (!status || status.match(emptySpace)) {
    //   setStatus(requiredMessage);
    // }

    if (
      fname && fname.match(stringCheck) &&
      !fname.match(emptySpace) &&
      lname && lname.match(stringCheck) &&
      !lname.match(emptySpace) &&
      phoneNumber && phoneNumber.match(phone) &&
      !phoneNumber.match(emptySpace) &&
      emailId && emailId.match(email) &&
      !emailId.match(emptySpace) &&
      status
    ) {
      // setInhous_alert(obje);
      onUserSave();
    }
  };
  const onUserSave = () => {
    const obje = {
      alert_travel: alertTravel,
      in_house: inHouse,
    };
    const user = {
      email: emailId,
      first_name: fname,
      last_name: lname,
      status: status,
      has_access_to: obje,
      mobile: phoneNumber,
      // number_of_licenses: 2,
      subdivision_accessible: permissions,
      user_type: user_type,
    };

    dispatch(addExtController(user, { setAddDialog: () => setOpenConfirmDialog(true) }, () => dispatch(getExternalUser())));
    // setOpenConfirmDialog(true);
  };

  const handleCencle = () => {
    navigate(`/app/ExternalUsers`);
  }
  const handleCancelDialog = () => {
    setOpenConfirmDialog(false);
    navigate(`/app/ExternalUsers`);
  };
  const handleConfirm = () => {
    setOpenConfirmDialog(false);
  };
  return (
    <div className="mainDiv1">

      <div className="heading-section mb-48">
        <div className="pageTitle">Add Ext controller</div>
      </div>

      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            width: "50ch",

            width: "100%",
          },
          flexGrow: 1,
        }}
        noValidate
        autoComplete="off"
        style={{ color: "rgba(0, 0, 0, 0.87)" }}
      >
        <Grid container spacing={2} ml={0} className="formGrid">
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="First Name *"
              variant="standard"
              value={fname}
              onChange={(e) => {
                setFname(e.target.value.slice(0, 16));
                setFnameError("");
                setHelperText(false)
              }}
              helperText={fnameError}
              helperProps={fnameError ? helperText : false}
              errorProps={fnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Last Name *"
              variant="standard"
              value={lname}
              onChange={(e) => {
                setLname(e.target.value.slice(0, 16));
                setLnameError("");
                setHelperText(false)
              }}
              helperText={lnameError}
              helperProps={lnameError ? helperText : false}
              errorProps={lnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Phone number *"
              variant="standard"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setPhoneNumberError("");
                setHelperText(false)
              }}
              helperText={phoneNumberError}
              helperProps={phoneNumberError ? helperText : false}
              errorProps={phoneNumberError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Email *"
              variant="standard"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
                setEmailIdError("");
                setHelperText(false)
              }}
              helperText={emailIdError}
              helperProps={emailIdError ? helperText : false}
              errorProps={emailIdError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            {/* <TextFields id="standard-basic" label="Status" variant="standard"> */}
            <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
              <CustomLabel id="demo-customized-select-label">Status</CustomLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setStatusError("");
                }}
                helperText={statusError}
                defaultValue="Active"
                input={<BootstrapInput />}
                onOpen={() => setDropdownIcon(true)}
                onClose={() => setDropdownIcon(false)}
                IconComponent={() => (
                  dropdownIcon ? <i className="icon-arrow-up" style={{ cursor: 'pointer', position: 'absolute', right: 0, zIndex: -1, color: 'rgb(133, 142, 160)' }}
                  ></i> : <i className="icon-arrow-down" style={{ cursor: 'pointer', position: 'absolute', right: 0, zIndex: -1, color: 'rgb(133, 142, 160)' }}
                  ></i>
                )}
              >
                <MenuItem value={1}><FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(95, 170, 176)', marginRight: '4px' }} />Active</MenuItem>
                <MenuItem value={2}><FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(225, 108, 108)', marginRight: '4px' }} />Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <InputLabel id="demo-simple-select-label"
          className={'lable-style mb-5 ' + (phoneNumberError || lnameError || fnameError || emailIdError
            ? 'lable-red' : 'lable-style mb-5')}
        >
          {/* * all fields required */}
          {!emailIdError
            && !phoneNumberError && !fnameError && !lnameError ?
            '* all fields required' : '* Please fill all required fields'}
        </InputLabel>
      </Box>
      <Box
        style={{
          position: "relative",
          width: "100%",
          minHeight: "100%",
          margin: "0px",
        }}
      >
        <div className="heading-section">
          <div className="pageTitle">Has access to</div>
        </div>

        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              width: "50ch",
              mb: 4,
              width: "100%",
            },
            flexGrow: 1,
          }}
          noValidate
          autoComplete="off"
          style={{ color: "rgba(0, 0, 0, 0.87)" }}
        >
          <Grid container spacing={2} ml={0} className="formGrid">
            <Grid
              item
              xs={6}
              mb={2}
              style={{ position: "relative" }}
              className="gridText"
            >
              <TextFields
                defaultValue="Alert & Travel"
                variant="standard"
                textProps={true}
                disabled

              />
              <AntSwitch
                value={alertTravel}
                onChange={() => {
                  setAlertTravel(!alertTravel);
                }}
                checked={alertTravel}
                inputProps={{ 'aria-label': 'ant design' }}
              //  color="rgb(72, 146, 195)"
              />
            </Grid>
            <Grid
              item
              xs={6}
              mb={2}
              style={{ position: "relative" }}
              className="gridText"
            >
              <TextFields
                defaultValue="In house"
                variant="standard"
                textProps={true}
                disabled

              />
              <AntSwitch
                value={inHouse}
                onChange={() => {
                  setInHouse(!inHouse);
                }}
                checked={inHouse}
                inputProps={{ 'aria-label': 'ant design' }}

              />
            </Grid>
          </Grid></Box>

        <div className="heading-section">
          <div className="pageTitle">Has access to</div>
        </div>

        <Box

          component="form"
          sx={{
            "& > :not(style)": {
              ml: 0,
              width: "50ch",
              mb: 4,
              width: "33%",
              // marginRight: "-20px",
            },
          }}
          noValidate
          autoComplete="off"
          style={{ color: "rgba(0, 0, 0, 0.87)" }}
        >
          {!!subdivison.userData
            ? subdivison.userData
              .map((row, index) => {
                return (

                  <FormControlLabel key={row._id} className="checkbox-lable" control={
                    // <Checkbox
                    //   className="checkbox-ui" id={row._id}  onChange={handleCheck} value={row._id}/>



                    <Checkbox
                      checked={row ? permissions.filter((x) => x === row._id).length : null}
                      onChange={handleCheck}
                      icon={<CustomCheckedIcon checked={permissions.filter((x) => x === row._id).length} />}
                      checkedIcon={<CustomCheckedIcon checked={permissions.filter((x) => x === row._id).length} />}
                      value={row._id}

                      sx={{
                        "&:hover": {
                          backgroundColor: 'white'
                        }
                      }}
                    />




                  } label={row.firm_name} />
                );
              })
            : null}
        </Box>

        {/* </FormControl> */}
        {/* <Button
          onClick={handleCencle}
          variant="outlined"
          // style={{ width: "150px", height: "45px", marginBottom: "30px" }}
          style={{
            border: '1px solid rgb(72, 146, 195)',
            borderRadius:' 8px',
            color:'rgb(72, 146, 195)',
            maxWidth: '192px',
            height: '49px',
            marginTop: '0',
            width: '100%',
            
// borderTop: `solid 1px ${theme.palette.borderColor.main}`,
'&:hover': {
background:'rgb(62, 138, 193)'}
          }}
        >
          cancel
        </Button> */}
        <ButtonCancel onClick={handleCencle}> cancel</ButtonCancel>
        {/* <Button
          variant="contained"
          style={{
            background: 'rgb(72, 146, 195) ',
            marginLeft:'10px',
    textTransform: 'capitalize ',
    padding: '15px ',
    width:' 100% ',
    marginTop: '40px ',
    lineHeight: '1.2 ',
    borderRadius: '8px ',
    boxShadow: 'rgb(98 132 152 / 33%) 0px 11px 26px -10px ',
    fontFamily: "SF Pro Text" ,
    fontSize: '16px ',
    maxWidth: "192px",
            color:'white',
            
            height: "49px",
            marginBottom: "40px",
    

'&:hover': {
background:'rgb(62, 138, 193)'}

          }}
          // style={{
          //   maxWidth: "150px",
          //   marginLeft: "20px",
          //   height: "47px",
          //   marginBottom: "76px",
          // }}
          onClick={handleSaveBtn}
        >
          Save
        </Button> */}
        <Buttons onClick={handleSaveBtn} buttoncheck={true}>Save</Buttons>

      </Box>
      <AddConfirmDialog
        open={openConfirmDialog}
        // title={`Delete Subdivision`}
        content={`All changes saved successfully. `}
        btnLabels={{
          ok: "Ok",
        }}
        onClose={handleCancelDialog}
        onConfirm={handleConfirm}
      />
    </div>

  );
}
