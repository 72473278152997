import {
  USER_PROFILE,
  EDIT_USER_PROFILE,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  SET_LOADER,
} from "../../@jumbo/constant/ActionTypes";
import {
  userProfile,
  editUserProfile,
  changePassword,
  forgotPassword,
} from "../../@jumbo/constant/ApiConstant";
import axios from "../../helper/customAxios";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS } from '../../@jumbo/constant/ActionTypes';
import { refreshToken } from "./Auth";


export const getUserProfile = (redirectPath, callbackFun) => {
  return (dispatch) => {
    // if(token){


    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(fetchStart())
    dispatch({ type: SET_LOADER, payload: true });
    axios
      .get(userProfile)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: USER_PROFILE, payload: data.data.data });
        } else {
          dispatch({ type: FETCH_ERROR, loading: false });
          dispatch({ type: USER_PROFILE, payload: null });
        }
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getUserProfile(redirectPath, callbackFun))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          if (redirectPath) redirectPath()
          dispatch({ type: USER_PROFILE, payload: null });
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
        dispatch({ type: SET_LOADER, payload: false });

      });
  };
};

export const updateUserProfile = (user, callbackFun) => {
  return (dispatch) => {
    // console.log("localStorage tokens",);
    // console.log("try", localStorage.getItem("previous_token"), localStorage.getItem("previous_token") == localStorage.getItem("userToken"), localStorage.getItem("previous_token"), localStorage.getItem("userToken"));
    // if (localStorage.getItem("previous_token") && localStorage.getItem("previous_token") == localStorage.getItem("userToken")) {
    //   dispatch(updateUserProfile(user, callbackFun))
    //   return;
    // }


    dispatch(fetchStart());
    axios
      .put(editUserProfile, user)
      .then((data) => {
        if (data) {

          dispatch({ type: FETCH_SUCCESS, loading: false });
          if (callbackFun) {
            callbackFun.setAddDialog();
            callbackFun.setOpen();
            callbackFun.setDisable();

            setTimeout(() => {
              dispatch(fetchSuccess("Profile updated successfully ."));

            }, 4000);
          }
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch(async (error) => {
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else if (error.response && error.response.status == 444) {
          await dispatch(refreshToken(() => {
            dispatch(updateUserProfile(user, callbackFun))
          }))
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const userChangePassword = (user, callbackFun) => {

  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    // dispatch({ type: FETCH_SUCCESS, loading: false });
    dispatch(fetchStart());
    axios
      .post(changePassword, user)
      .then((data) => {

        if (data) {



          if (callbackFun) {

            callbackFun.setAddDialog();
            // callbackFun.setCloseDialog();
            dispatch({ type: FETCH_SUCCESS, loading: false });
          }
          // setTimeout(() => {
          //   dispatch({ type: FETCH_SUCCESS, loading: false });
          // }, 2000);
          setTimeout(() => {
            dispatch(fetchSuccess("PassWord change successfully."));
            // dispatch({ type: FETCH_SUCCESS, loading: false });
          }, 4000);

        }
        // dispatch(fetchSuccess('Is in Progressing....'));

        // if (data?.data?.data?.red_snakbar) {
        //   setTimeout(() => {
        //     dispatch(fetchError(data?.data?.message));
        //   }, 2000);
        // } else {

        // dispatch({ type: ADD_USER, payload: data.data.data });
        // }
        else {
          dispatch({ type: CHANGE_PASSWORD, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(userChangePassword(user, callbackFun))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          //     dispatch(setAuthUser(null));
          //     history.push('/signin');
          //   } else {
          // window.location='/';
          dispatch(fetchStart());
          setTimeout(() => {
            dispatch({ type: FETCH_SUCCESS, loading: false });
          }, 2000);


          // setTimeout(() => {
          dispatch(fetchError(error.response.data.message));
          // }, 2000);
          //     callbackFun.onCloseDialog();
          //     callbackFun.setAddButton();
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });
  };
};



export const userForgotPassword = (user, callbackFun, list) => {

  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(fetchStart());
    axios
      .post(forgotPassword, user)
      .then((data) => {
        if (data) {
          if (callbackFun) {
            dispatch({ type: FETCH_SUCCESS, loading: false });
            callbackFun.setAddDialog();

            setTimeout(() => {
              dispatch(fetchSuccess(data.data.message));
            }, 2000);
            if (list) list()
          }
          // dispatch(fetchSuccess('Is in Progressing....'));

          // if (data?.data?.data?.red_snakbar) {
          //   setTimeout(() => {
          //     dispatch(fetchError(data?.data?.message));
          //   }, 2000);
          // } else {
          //   setTimeout(() => {
          //     dispatch(fetchSuccess(data?.data?.message));
          //   }, 2000);
          // }
          // dispatch({ type: ADD_USER, payload: data.data.data });
        } else {
          dispatch({ type: FORGOT_PASSWORD, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(
            fetchError("There was something issue in responding server.")

          );

        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(userForgotPassword(user, callbackFun, list))
          }))
          return;
        }
        if (error.response.status === 401 || error.response.status === 400) {
          //     dispatch(setAuthUser(null));
          //     history.push('/signin');
          //   } else {
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
          //     callbackFun.onCloseDialog();
          //     callbackFun.setAddButton();
        }
      });
  };
};

export const logout = () => {
  localStorage.clear();
  return ({ type: USER_PROFILE, payload: {} })
};
