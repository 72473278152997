import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Pagination from './Pagination/Pagination';

const ReactTable = ({ COLUMNS = [], DATA = [], hiddenColumns = [], fetchData, pageIndex = 1, totalCount = 0, pageSize, pagination = true, type }) => {
    const columns = useMemo(() => COLUMNS, [COLUMNS]);
    const data = useMemo(() => DATA, [DATA]);
    const { loading } = useSelector(({ common }) => common);
    return (
        <>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="sticky enhanced table"
                    className="custome-tbl table-auto overflow-scroll w-full"
                >
                    <TableHead className='border-t-2'>
                        <TableRow>
                            {columns.map((value, index) => {
                                if (!hiddenColumns.includes(value.id)) {
                                    return (
                                        <TableCell sx={{ width: (value?.width || 'auto') }} align={value?.align || 'center'} key={index} style={{ paddingTop: '0.26rem', paddingBottom: '0.26rem' }}>{value?.title}</TableCell>
                                    );
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ height: "30px" }}>
                        {(/* loading?.length <= 0 &&  */data) && data.map((row, index) => (
                            <TableRow
                                key={index}
                                className='text-center'
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {columns.map((value, index) => {
                                    if (!hiddenColumns.includes(value.id)) {
                                        const Component = value?.Component;
                                        return (
                                            <TableCell key={index} align={value?.align || 'center'}>{Component ? <Component row={row} /> : row[value?.accessor]}</TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            {pagination && type && type === "checklist" && <Pagination
                activePage={pageIndex}
                totalItems={totalCount}
                itemsCountPerPage={pageSize}
                handlePageChange={fetchData}
            />}
        </>
    )
}

export default ReactTable;