import { Dataset } from "@mui/icons-material";
import {
  UPDATE_TRAVEL_RAISED_COUNT,
  END_TRAVEL_SOCKET,
  ADD_TRAVEL,
  GET_TRAVEL_LIST,
  RESET_TRAVEL_LIST,
  RESET_ADD_STATUS,
  GET_TRAVEL_LIST_SOCKET,
  GET_ALL_USERS,
  GET_TRAVEL_LOG_DETAIL,
  ADD_TRAVEL_COMMENT,
  RESET_TRAVEL_COMMENT,
  UPDATE_TRAVEL_STATUS,
  GET_ACTIVE_TRAVEL,
  RESET_UPDATE_STATUS,
  UPDATE_TRAVEL_LIST,
} from "../../@jumbo/constant/ActionTypes";

const INITIAL_STATE = {
  travelList: null,
  getStatus: 0,
  markerList: [],
  preveMarkerList: null,
  addStatus: 0,
  userList: [],
  travelLogDetail: {},
  addTravelComment: {},
  updateTravel: {},
  activeTravelList: [],
};
let finalData;
let dataSet;
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRAVEL_LIST:
      return {
        ...state,
        travelList: {
          ...state?.travelList,
          ...action.payload /*  raisedTravelCount: action?.payload?.total_no_of_records */,
        },
        getStatus: action.status,
      };
    case UPDATE_TRAVEL_RAISED_COUNT:
      return {
        ...state,
        travelList: {
          ...state?.travelList,
          raisedTravelCount: action?.payload?.raisedTravelCount,
        },
      };
    case GET_TRAVEL_LIST_SOCKET: {
      let alertLogs = [];
      let currentTravelLogs = state?.travelList?.UserTravelList;
      if (currentTravelLogs && currentTravelLogs?.length > 0) {
        alertLogs = currentTravelLogs.filter(
          (alert) => alert.travelID !== action?.payload?.crisisTravel?.travelID
        );
      }
      return {
        ...state,
        travelList: {
          ...state.travelList,
          raisedTravelCount: action.payload.raisedTravelCount,
          total_no_of_records: action.payload.totalTravelCount,
          total_upcoming: action.payload.totalUpcomingCount,
          UserTravelList: [action?.payload?.crisisTravel, ...alertLogs],
        },
      };
    }
    case END_TRAVEL_SOCKET: {
      let alertLogs = [];
      let currentTravelLogs = state?.travelList?.UserTravelList;
      let raisedTravelCount = state?.travelList?.raisedTravelCount;
      if (currentTravelLogs && currentTravelLogs?.length > 0) {
        alertLogs = currentTravelLogs.filter(
          (alert) => alert.travelID !== action?.payload?.crisisTravel?._id
        );
        raisedTravelCount =
          currentTravelLogs?.length !== alertLogs?.length
            ? (state?.travelList?.raisedTravelCount || 1) - 1
            : raisedTravelCount;
      }
      return {
        ...state,
        travelList: {
          ...state.travelList,
          raisedTravelCount: raisedTravelCount,
          // total_no_of_records: (state?.travelList?.total_no_of_records || 1) - 1,
          UserTravelList: [...alertLogs],
        },
      };
    }

    case RESET_TRAVEL_LIST:
      return {
        ...state,
        travelList: null,
        getStatus: 0,
      };

    case GET_ACTIVE_TRAVEL:
      return {
        ...state,
        activeTravelList: action.payload,
      };

    case ADD_TRAVEL:
      return {
        ...state,
        addStatus: action.status,
      };

    case RESET_ADD_STATUS:
      return {
        ...state,
        addStatus: 0,
      };

    case GET_ALL_USERS:
      return {
        ...state,
        userList: action.payload,
      };

    case GET_TRAVEL_LOG_DETAIL:
      return {
        ...state,
        travelLogDetail: action.payload,
      };

    case ADD_TRAVEL_COMMENT:
      return {
        ...state,
        addTravelComment: action.payload,
      };

    case RESET_TRAVEL_COMMENT:
      return {
        ...state,
        addTravelComment: {},
      };

    case UPDATE_TRAVEL_STATUS:
      const idx =
        state.travelList &&
        state.travelList.UserTravelList.length > 0 &&
        state.travelList.UserTravelList.findIndex(
          (x) => x._id === action.payload.travel_id
        );
      return {
        ...state,
        updateTravel: action.payload,
        travelList: state.travelList.UserTravelList.splice(idx, 1),
      };

    case RESET_UPDATE_STATUS:
      return {
        ...state,
        updateTravel: {},
      };
    case UPDATE_TRAVEL_LIST: {
      return {
        ...state,
        travelList: { ...state?.travelList, ...action?.payload },
      };
    }
    default:
      return state;
  }
};
