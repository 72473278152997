import React from 'react'
import axios from "../../helper/customAxios";
import { getUserProfile } from './Settings';
import { Form, useNavigate } from "react-router-dom";
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS, LOGOUT, SEC_SUBDIV } from '../../@jumbo/constant/ActionTypes';
import { USER_PROFILE } from '../../@jumbo/constant/ActionTypes';
import { refresh_token } from '@jumbo/constant/ApiConstant';

// const API_URL = "https://node.inheritxdev.in/cc-log-dev/api/v1/users/";
// const API_URL = "https://node.inheritxdev.in/cc-log-qa/api/v1/users/";
// const API_URL = "https://node.inheritxdev.in/cc-log-prod/api/v1/users/";
// const API_URL = "https://sodev.inheritxdev.in/api/v1/users/";
// const API_URL = "https://soqa.inheritxdev.in/api/v1/users/";
const API_URL = process.env.REACT_APP_BASE_URL;

export default function onLogin(email, password, redirectPath, group_code) {
	return dispatch => {
		try {
			dispatch(fetchStart());
			axios
				.post('admin/login', {
					email,
					password,
					redirectPath,
					group_code,
					platform: "WEB"
				})
				.then(({ data }) => {
					dispatch({ type: FETCH_SUCCESS, loading: false });
					setTimeout(() => {
						dispatch(fetchSuccess(data.message));
					}, 2000);
					// dispatch({ type: "SET_PREVIOUSAPI", payload: data.data.token })
					axios.defaults.headers.common['Authorization'] = data.data.token;
					localStorage.setItem(
						"userToken",
						JSON.stringify(data.data.token)
					);

					localStorage.setItem("profile", JSON.stringify(data.data))
					dispatch(getUserProfile(() => redirectPath()))

				})
				.catch(function (error) {
					dispatch({ type: FETCH_SUCCESS, loading: false });
					if (error?.response?.data?.message) {
						// setTimeout(() => {
						dispatch(fetchError(error.response.data.message), 3000);
						// }, 2000);
					}

				});
		} catch (error) {
			if (error?.response?.data?.message) {
				// setTimeout(() => {
				dispatch(fetchError(error.response.data.message), 3000);
				// }, 2000);
			}
		}
	};

}



export const onLogout = (navigate) => {
	return (dispatch) => {
		axios
			.get('/v1/users/logout')
			.then(({ data }) => {
				if (data) {
					localStorage.removeItem("userToken");
					localStorage.removeItem("profile");
					dispatch({ type: LOGOUT })
					dispatch({ type: USER_PROFILE, payload: null });
					dispatch({ type: FETCH_SUCCESS, loading: false });
					localStorage.removeItem("sec_subdiv_id");
					dispatch({ type: SEC_SUBDIV, payload: "" })
					navigate();
				}
				else {
					dispatch({ type: FETCH_SUCCESS, loading: false });
					dispatch(fetchError(data.error));
				}
			})
			.catch((error) => {
				if (error.response && error.response.status == 444) {
					dispatch(refreshToken(() => {
						dispatch(onLogout(navigate))
					}))
					return;
				}
				if (
					(error.response && error.response.status == 401) ||
					(error.response && error.response.status == 400)
				) {
					dispatch({ type: LOGOUT })
					dispatch({ type: USER_PROFILE, payload: null });
					dispatch({ type: FETCH_SUCCESS, loading: false });
					localStorage.removeItem("userToken");
					localStorage.removeItem("profile");
					navigate();
				}
				else {
					dispatch({ type: FETCH_SUCCESS, loading: false });
					// dispatch(fetchError(error.response.data.message));
				}
			});
	};
};

// Refresh_token
export const refreshToken = (previousFunction) => {
	return (dispatch) => {
		dispatch(fetchStart());
		const token = JSON.parse(localStorage.getItem("userToken"));
		axios.defaults.headers.common["Authorization"] = token;
		axios
			.post(refresh_token, { token: token })
			.then(({ data }) => {
				if (data) {
					// const userProfile = JSON.parse(localStorage.getItem("profile"));
					// localStorage.removeItem('previous_token');
					// localStorage.setItem("profile", JSON.stringify({ ...userProfile, token_expiry_date: data?.data?.expiry }))
					localStorage.setItem('userToken', JSON.stringify(data?.data))
					// dispatch({ type: "SET_PREVIOUSAPI", payload: data?.data })
					// setTimeout(() => {
					previousFunction()
					// }, 3500)
				}
				else {
					console.log("second");
				}
				dispatch({ type: FETCH_SUCCESS, loading: false });
			})
			.catch((error) => {
				dispatch({ type: FETCH_SUCCESS, loading: false });
				console.log("error", error);
			});
	};
};