import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Form, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserTableHead from "./UserTableHead";
import { USER_TYPE, useDebounce } from "utils/commonHelper";
import UserListRow from "./UserListRow";
import { getLocalPoliceNumberList, setLocalPoliceNumber, RemoveLocalPoliceNumber } from "redux/actions/localPoliceNumber";
import ConfirmDltDialog from "../../ConfirmDltDialog";
import Grid from '@mui/material/Grid';
import {
    Container0,
} from "./index.style";
import ConfirmDialog from "components/ConfirmDialog";
import AddConfirmDialog from "../../AddConfirmDialog";
import { getImportantContactList } from "redux/actions/importantContact";
import ReactTable from "components/common/table/ReactTable2";
import { SET_LOCAL_POLICE_NUMBER } from "@jumbo/constant/ActionTypes";
const perPages = [10, 20, 50, 100]
export default function UsefulLinkForTravel({ open,
    onClose,
    onConfirm,
    setOpenPassConfirmDialog,
    butttonValue,
    setButttonValue }) {
    const { numberList } = useSelector(({ LOCALPOLICENUMBER }) => LOCALPOLICENUMBER);
    const Profile = JSON.parse(localStorage.getItem("profile"));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedUser, setSelectedUser] = useState('');
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState("desc");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)
    const [orderBy, setOrderBy] = React.useState("first_name");

    const [currentData, setCurrentData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [searchValue, setSearchValue] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [activePerPage, setActivePerPage] = useState(10);
    const [editData, setEditData] = useState(null);
    const [addDialog, setAddDialog] = useState(false);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let user_type = 3;


    useEffect(() => {
        dispatch(
            getLocalPoliceNumberList()

        );
        if (Profile.user_type !== 3 && Profile.user_type !== 1) {
            dispatch(getImportantContactList())
        }
    }, []);

    const handleEdit = user => {
        // alert(user)
        dispatch({ type: SET_LOCAL_POLICE_NUMBER, payload: [] });
        dispatch(setLocalPoliceNumber(user))
        // setOpenPassConfirmDialog(true);
        navigate(`/app/Settings/EditNumber/${user}`);
    };
    const addContact = () => {
        // setAddDialog(true)

        //   navigate(`/app/Settings/addContact/`);
        //   dispatch({ type: SET_TENANT, payload: null });
    };

    const handleDelete = (id) => {

        setSelectedUser(id)
        setopenConfirmDltDialog(true);
    };

    const handleCancelDelete = () => {
        setopenConfirmDltDialog(false);
    };

    const handleConfirmDelete = () => {
        setopenConfirmDltDialog(false);
        // let user =
        //   {'importantContactId':selectedUser}
        // console.log(user)dispatch(getUsefulLinkForTravel())
        dispatch(RemoveLocalPoliceNumber(selectedUser, { setAddDialog: () => setOpenConfirmDialog(true) }))
        // getLocalPoliceNumberList
    };

    const handleCancelConfirm = () => {
        setOpenConfirmDialog(false)
        dispatch(getLocalPoliceNumberList())
    }
    const handleConfirm = () => {
        setOpenConfirmDialog(false)
    }
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrderBy(property);
        setOrder(isAsc ? "desc" : "asc");
    };

    const columns = [
        {
            id: "country",
            accessor: "country",
            title: "Country",
            align: "left",
            Component: ({ row }) => {
                return (
                    <>
                        {row?.is_default ? <div className="default-number">Default number</div> : null}{!row.country ? "-" : row.country}
                    </>
                )
            }
            // {row?.is_default ? <div className="default-number">Default number</div> : null}{!row.country ? "-" : row.country}
        },
        {
            id: "police_phone",
            accessor: "police_phone",
            title: "Police phone",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: "description",
            accessor: "description",
            title: "Description",
            format: (value) => value.toLocaleString("en-US"),
        },
        {
            id: 'actions',
            accessor: 'actions',
            title: 'Actions',
            width: '20%',
            Component: ({ row }) => {
                return (
                    <>
                        <span className="text-[#4892c3] cursor-pointer" onClick={() => handleEdit(row?._id)} >
                            Edit
                        </span>
                        {console.log("row delete button", row)}
                        {!row?.is_default ? <button className="text-[#e17070] ml-2 cursor-pointer" onClick={() => handleDelete(row?._id)}>
                            Delete
                        </button>
                            : null}
                    </>
                );
            }
        },
    ];

    return (
        <>
            {/* <Grid xs={12} sm={12} md={12} >
                <Container0> */}
            <div className="mainDiv1">
                <ReactTable
                    COLUMNS={columns}
                    DATA={numberList?.local_police_number}
                    fetchData={() => { }}
                    pagination={false}
                // hiddenColumns={[USER_TYPE.CONTROL_USER, USER_TYPE.END_USER, USER_TYPE.EXTERNAL_CONTROLLER].includes(Profile?.user_type) ? ['actions'] : []}
                />
                <ConfirmDltDialog
                    open={openConfirmDltDialog}
                    // title={`Delete Subdivision`}
                    content={`Are you sure you want to delete this Police number?`}
                    btnLabels={{
                        no: "No",
                        Yes: "Yes",
                    }}
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
                <AddConfirmDialog
                    open={openConfirmDialog}
                    // title={`Delete Subdivision`}
                    content={`Police number was deleted successfully! `}
                    btnLabels={{
                        ok: "Ok",
                    }}
                    onClose={handleCancelConfirm}
                    onConfirm={handleConfirm}
                />
            </div>
            {/* </Container0>
            </Grid> */}
        </>
    );
}
