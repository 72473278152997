import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
// import Loader from 'react-loader';
// import {useStyles} from '@mui/material';
// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";
import { FormControlLabel, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import CloudUploadIcon from '@material-ui/CloudUpload';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AntSwitch } from "components/antSwitch/AntSwitch";
import {
  addSubdivision,
  getAllUsersList,
} from "redux/actions/SubDivision";
import {
  addNewTenant,
  getTenantList,
  updateTenant,
  getCurrTenantDetail,
} from "redux/actions/tenant";
import {
  // Button,
  Container0,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "../index.style";
// import "antd/dist/antd.css";
// import { Switch } from 'antd';
import {
  requiredMessage,
  lettersOnlyMessage,
  phoneOnlyNumericals,
  onlyNumberAllow,
  emailNotValid,
  alphaNumaricMessage,
} from "@jumbo/constant/ErrorMessages";
import {
  stringOnly,
  emptySpace,
  phoneNumberValidation,
  emailvalidation,
  alphaNumaric,
  numberOnly,
  stringCheck,
} from "@jumbo/constant/ValidationRegex";
import { Form, useNavigate } from "react-router-dom";
// import {stringOnly} from 'utils/commonHelper'
import AddConfirmDialog from "../../AddConfirmDialog";
import { useParams } from "react-router-dom";
// import {TextFields} from "../textField";
import { TextFields } from "../../Textfeild";
import { Buttons } from "../../Button";
import { ButtonCancel } from "../../cancelButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import GridContainer from "../../GridContainer";

const ariaLabel = { "aria-label": "description" };
const useStyles = styled((theme) => ({
  dialogRoot: {
    width: "100%",
    margin: "opx 0px",
    minWidth: "800px",
    overflow: "hidden",
    display: "block",
  },
  root: {
    display: "flex",
    webkitBoxPack: "justify",
    justifyContent: " space-between",
    alignItems: " flex-start",
    flexDirection: "row",
    marginTop: "10px",
    padding: "0px",
  },
  imageButton: {
    fontSize: '10px',
    marginLeft: '15px',
    // color: theme.palette.text.primary,
    // textOverflow: 'ellipsis',
    // maxWidth: '200px',
    // display: 'block',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
  },
  root2: {
    fontFamily: "SFTextSemibold",
    textAlign: "left",
    // marginBottom: "35px",
    fontSize: "22px",
    width: "100%",
    color: " rgb(92, 99, 115)",
  },
  helperText: {
    color: "red",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function AddEditUser({ setAddDialog }) {
  let { id } = useParams();
  const { edit } = useSelector(({ TENANT }) => TENANT);
  let classes = useStyles();
  let navigate = useNavigate();
  // const [age, setAge] = React.useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subdivisionName, setSubdivisionName] = useState("");
  const [contractID, setContractID] = useState("");
  const [numberOflicence, setNumberOflicence] = useState("");
  const [emailId, setEmailId] = useState("");
  const [address, setaddress] = useState("");
  const [status, setStatus] = useState(1);
  const [alertTravel, setAlertTravel] = useState(true);
  const [inHouse, setInHouse] = useState(true);
  const [inhous_alert, setInhous_alert] = useState();

  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [subdivisionNameError, setSubdivisionNameError] = useState("");
  const [contractIDError, setContractIDError] = useState("");
  const [numberOflicenceError, setNumberOflicenceError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [companyLogo, setCompanyLogo] = useState('')
  const [companyLogoError, setCompanyLogoError] = useState('')
  const [statusError, setStatusError] = useState("");
  // const [dialogOpen, setDialogOpen] = useState(true)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [buttonProps, setButtonProps] = useState("addbutton");
  const [helperText, setHelperText] = useState(false);

  const dispatch = useDispatch();
  const user_type = 3;
  let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let number = /^([0-9])*$/;
  // let phone = /^([\\+])?([0-9]{12})*$/;
  let phone = /^(\+)([0-9]{9,15})$/;
  let loding = false;
  let check = /^[A-Za-z\s]*$/;

  useEffect(() => {
    if (id) {
      dispatch(getCurrTenantDetail(id));
    }

  }, [id]);

  useEffect(() => {
    if (edit?.detail) {
      setFname(!!edit ? edit.detail.first_name : null);
      setLname(!!edit ? edit.detail.last_name : null);
      setPhoneNumber(!!edit ? edit.detail.mobile : null);
      setSubdivisionName(!!edit ? edit.detail.firm_name : null);
      if (edit.detail.number_of_licenses) {
        setNumberOflicence(
          !!edit
            ? edit.detail
              ? edit.detail.number_of_licenses.assigned
              : null
            : null
        );
      }
      setaddress(!!edit ? edit.detail.address : null);
      setEmailId(!!edit ? edit.detail.email : null);
      // setCompanyLogo(!!edit ? edit.detail.address : null);
    }
  }, [edit]);

  const handleSaveBtn = () => {
    if (!fname || fname.match(emptySpace)) {
      setHelperText(true)
      setFnameError(requiredMessage);
    } else if (!fname.match(stringCheck)) {
      setHelperText(true)
      setFnameError(lettersOnlyMessage);
    }
    if (!lname || lname.match(emptySpace)) {
      setHelperText(true)
      setLnameError(requiredMessage);
    } else if (!lname.match(stringCheck)) {
      setHelperText(true)
      setLnameError(lettersOnlyMessage);
    }
    // if (!phoneNumber || phoneNumber.match(emptySpace)) {
    //   setHelperText(true)
    //   setPhoneNumberError(requiredMessage);
    // } else if (!phoneNumber.match(phone)) {
    //   setHelperText(true)
    //   setPhoneNumberError(phoneOnlyNumericals);
    // }
    if (phoneNumber) {
      if (!phoneNumber) {
        setHelperText(true)
        setPhoneNumberError(requiredMessage);
      } else if (!phoneNumber.match(phone)) {
        setHelperText(true)
        setPhoneNumberError(phoneOnlyNumericals);
      }
    }
    if (!subdivisionName || subdivisionName.match(emptySpace)) {
      setHelperText(true)
      setSubdivisionNameError(requiredMessage);
    } else if (!subdivisionName.match(stringCheck)) {
      setHelperText(true)
      setSubdivisionNameError(lettersOnlyMessage);
    }
    // if (!contractID || contractID.match(emptySpace)) {
    //   setContractIDError(requiredMessage);
    // } else if (!contractID.match(alphaNumaric)) {
    //   setContractIDError(alphaNumaricMessage);
    // }
    // if (!numberOflicence || numberOflicence.match(emptySpace)) {

    if (!numberOflicence) {
      setHelperText(true)
      setNumberOflicenceError(requiredMessage);
    } else if (!numberOflicence.toString().match(number)) {
      setHelperText(true)
      setNumberOflicenceError(onlyNumberAllow);
    }
    if (!emailId || emailId.match(emptySpace)) {
      setHelperText(true)
      setEmailIdError(requiredMessage);
    } else if (!emailId.match(email)) {
      setHelperText(true)
      setEmailIdError(emailNotValid);
    }
    if (!address || address.match(emptySpace)) {
      setHelperText(true)
      setaddressError(requiredMessage);
    }
    // if (!status || status.match(emptySpace)) {
    //   setStatus(requiredMessage);
    // }
    if (phoneNumber) {
      if (
        fname &&
        fname.match(stringCheck) &&
        !fname.match(emptySpace) &&
        lname &&
        lname.match(stringCheck) &&
        !lname.match(emptySpace) &&
        phoneNumber &&
        phoneNumber.match(phone) &&
        !phoneNumber.match(emptySpace) &&
        subdivisionName &&
        subdivisionName.match(stringCheck) &&
        !subdivisionName.match(emptySpace) &&
        // contractID &&
        // !contractID.match(emptySpace) &&
        numberOflicence &&
        numberOflicence.toString().match(number) &&
        // !numberOflicence.match(emptySpace) &&
        emailId &&
        emailId.match(email) &&
        !emailId.match(emptySpace) &&
        address &&
        !address.match(emptySpace)
        // &&
        // status
      ) {
        // setInhous_alert(obje);
        onUserSave();
      }
    }
    else if (!phoneNumber) {
      if (
        fname &&
        fname.match(stringCheck) &&
        !fname.match(emptySpace) &&
        lname &&
        lname.match(stringCheck) &&
        !lname.match(emptySpace) &&
        // phoneNumber &&
        // phoneNumber.match(phone) &&
        // !phoneNumber.match(emptySpace) &&
        subdivisionName &&
        subdivisionName.match(stringCheck) &&
        !subdivisionName.match(emptySpace) &&
        // contractID &&
        // !contractID.match(emptySpace) &&
        numberOflicence &&
        numberOflicence.toString().match(number) &&
        // !numberOflicence.match(emptySpace) &&
        emailId &&
        emailId.match(email) &&
        !emailId.match(emptySpace) &&
        address &&
        !address.match(emptySpace)
        // &&
        // status
      ) {
        // setInhous_alert(obje);
        onUserSave();
      }
    }
  };
  const onUserSave = () => {

    const obje = {
      alert_travel: alertTravel,
      in_house: inHouse,
    };

    const formData = new FormData();
    formData.append('email', emailId);
    formData.append('first_name', fname);
    formData.append('last_name', lname);
    formData.append('status', status);
    formData.append('contract_id', "con1");
    formData.append('number_of_licenses', Number(numberOflicence));
    formData.append('firm_name', subdivisionName);
    formData.append('has_access_to', JSON.stringify(obje));
    formData.append('address', address);
    formData.append('mobile', phoneNumber);
    formData.append('user_type', user_type);
    formData.append('logoImage', companyLogo);

    // const user = {
    //   email: emailId,
    //   first_name: fname,
    //   last_name: lname,
    //   status: status,
    //   contract_id: "con1",
    //   number_of_licenses: Number(numberOflicence),
    //   firm_name: subdivisionName,
    //   has_access_to: obje,
    //   address: address,
    //   mobile: phoneNumber,
    //   user_type: user_type,
    // };
    const userEdit = {
      user_id: !!edit ? edit.detail._id : null,
      email: emailId,
      first_name: fname,
      last_name: lname,
      status: status,
      contract_id: "con1",
      number_of_licenses: Number(numberOflicence),
      firm_name: subdivisionName,
      has_access_to: obje,
      address: address,
      mobile: phoneNumber,
      user_type: user_type,
    };
    if (edit) {
      // dispatch(updateTenant(userEdit,{setAddDialog: () => setOpenConfirmDialog(true),setAddDialogClose: () => setAddDialog(false)},() => dispatch(getTenantList())));
      dispatch(
        updateTenant(
          userEdit,
          { setAddDialog: () => setOpenConfirmDialog(true) },
          () => dispatch(getTenantList())
        )
      );
    } else {
      dispatch(
        addNewTenant(
          formData,
          // user,
          { setAddDialog: () => setOpenConfirmDialog(true) },
          () => dispatch(getTenantList())
        )
      );
    }
  };

  const handleCancle = () => {
    setOpenConfirmDialog(true);
  };
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    navigate(`/app/Tenants`);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };
  const handelCancel = () => {
    navigate(`/app/Tenants/`);
    // setAddDialog(false)
    // setOpenConfirmDialog(true);

    // !loding&&<Loader/>
  };

  return (
    <div className="mainDiv1">
      <div className="heading-section mb-48">
        <div className="pageTitle">{edit ? "Edit tenant" : "Add tenant"}</div>
      </div>

      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            width: "50ch",

            width: "100%",
          },
          flexGrow: 1,
        }}
        noValidate
        autoComplete="off"
        className="boxColor"
      >
        <Grid container spacing={2} ml={0} className="formGrid" >
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="First Name *"
              variant="standard"
              value={fname}
              onChange={(e) => {
                setFname(e.target.value.slice(0, 16));
                setFnameError("");
                setHelperText(false)
              }}
              helperText={fnameError}
              helperProps={fnameError ? helperText : false}
              errorProps={fnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Last Name *"
              variant="standard"
              value={lname}
              onChange={(e) => {
                setLname(e.target.value.slice(0, 16));
                setLnameError("");
                setHelperText(false)
              }}
              helperText={lnameError}
              helperProps={lnameError ? helperText : false}
              errorProps={lnameError ? true : false}
            />
          </Grid>

          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Phone number"
              variant="standard"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setPhoneNumberError("");
                setHelperText(false)
              }}
              helperText={phoneNumberError}
              helperProps={phoneNumberError ? helperText : false}
              errorProps={phoneNumberError ? true : false}
            />
          </Grid>

          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Tenant name *"
              variant="standard"
              value={subdivisionName}
              onChange={(e) => {
                setSubdivisionName(e.target.value);
                setSubdivisionNameError("");
                setHelperText(false)
              }}
              helperText={subdivisionNameError}
              helperProps={subdivisionNameError ? helperText : false}
              errorProps={subdivisionNameError ? true : false}
            />
          </Grid>

          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Number of licenses *"
              variant="standard"
              value={numberOflicence}
              onChange={(e) => {
                setNumberOflicence(e.target.value);
                setNumberOflicenceError("");
                setHelperText(false)
              }}
              helperText={numberOflicenceError}
              helperProps={numberOflicenceError ? helperText : false}
              errorProps={numberOflicenceError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Email *"
              variant="standard"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
                setEmailIdError("");
                setHelperText(false)
              }}
              helperText={emailIdError}
              helperProps={emailIdError ? helperText : false}
              errorProps={emailIdError ? true : false}
            />
          </Grid>

          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Address *"
              variant="standard"
              value={address}
              onChange={(e) => {
                setaddress(e.target.value);
                setaddressError("");
                setHelperText(false)
              }}
              helperText={addressError}
              helperProps={addressError ? helperText : false}
              errorProps={addressError ? true : false}
            />
          </Grid>

          {!edit ? <Grid item xs={12} mb={2} className="gridText">
            <Button variant="contained" component="label">
              <div style={{ marginRight: '10px' }}><CloudUploadIcon /></div>
              <p className="imageButton">{companyLogo?.size ? companyLogo?.name : 'Choose LOGO'}</p>
              <input
                type="file"
                hidden
                onChange={event => {
                  setCompanyLogo(event.target.files[0]);
                  setCompanyLogoError('');
                }}
                accept="image/png, image/jpeg"
              />
            </Button>{' '}
            {companyLogo ? <img id="logo" src={URL.createObjectURL(companyLogo)} style={{ marginTop: '15px', maxHeight: "170px" }} width="150px" /> : ''}
          </Grid> : null}

        </Grid>
        <InputLabel
          id="demo-simple-select-label"
          // className="lable-style mb-5"
          className={'lable-style mb-5 ' + (addressError || phoneNumberError || lnameError || fnameError || emailIdError
            || numberOflicenceError || subdivisionNameError
            ? 'lable-red' : 'lable-style mb-5')}
        >
          {/* * all fields required */}
          {!addressError && !emailIdError && !numberOflicenceError && !subdivisionNameError
            && !phoneNumberError && !fnameError && !lnameError ?
            '* all fields required' : '* Please fill all required fields'}
        </InputLabel>
      </Box>
      <Box
        style={{
          position: "relative",
          width: "100%",
          minHeight: "100%",
          margin: "0px",
        }}
      >

        <div className='heading-section'>
          <div className="pageTitle">
            Has access to
          </div>
        </div>


        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              width: "50ch",
              mb: 4,
              width: "100%",
            },
            flexGrow: 1,
          }}
          noValidate
          autoComplete="off"
          style={{ color: "rgba(0, 0, 0, 0.87)" }}
        >
          <Grid container spacing={2} ml={0} className="formGrid" >
            <Grid item xs={6} mb={2} style={{ position: "relative", pointerEvents: 'none' }} className="gridText">
              <TextFields defaultValue="Alert & Travel" variant="standard" textProps={true} disabled />

              <AntSwitch
                value={alertTravel}
                defaultChecked
                inputProps={{ "aria-label": "disabled checkbox" }}
                checkTrue={true}
                disabled
              // disableRipple={true}
              />
            </Grid>

            <Grid item xs={6} md={5} mb={2} style={{ position: "relative", display: 'flex', pointerEvents: 'none' }} className="gridText">
              <TextFields
                defaultValue="In house"
                variant="standard"
                textProps={true}
                disabled
              />

              <AntSwitch
                disabled
                defaultChecked
                //  checked={controlUSer}
                // inputProps={{ "aria-label": "ant design" }}
                inputProps={{ "aria-label": "disabled checkbox" }}
                checkTrue={true}
              />
            </Grid>
          </Grid>
        </Box>
        <ButtonCancel onClick={handelCancel}>
          {" "}
          cancel
        </ButtonCancel>
        <Buttons
          onClick={handleSaveBtn}

          buttoncheck={true}
        >
          {" "}
          Save
        </Buttons>
      </Box>
      <AddConfirmDialog
        open={openConfirmDialog}
        content={`All changes saved successfully. `}
        btnLabels={{
          ok: "OK",
        }}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}
