import React, { useState } from 'react';
import { TableRow, TableCell, Collapse, Box } from '@mui/material';
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material'
import { checkInDestinationCols, checkInStatus } from '../data'
import { CheckInDestinationCell, MapHeader, CheckInComment } from '../style'
import GooglMap from 'components/GooglMap'
import COLORS from 'utils/colors';
import moment from "moment"

export default function CheckInDestinationRow({ data, index }) {
    const [open, setOpen] = useState(false)
    console.log("lastCheckinComment", data)
    const renderCell = (col) => {
        return (
            <TableCell key={`colData-${col.id}`}>
                {col.id === 'detail' ?
                    <i className="icon-eye actioneye"
                        aria-label="toggle password visibility"
                        // className="actioneye"
                        // style={{color:"rgb(133, 142, 160)",fontSize:'16px',marginLeft:'5px',cursor: "pointer",}}
                        onClick={() => setOpen(!open)}
                    ></i>
                    // <VisibilityOutlinedIcon style={{ color: '#858EA0', fontSize: '20px', cursor: 'pointer' }} onClick={() => setOpen(!open)} />
                    : col.id === 'location' ? `${data.address}` : col.id === 'time' ? moment(`${new Date(data.time)}`).format('DD-MM-YYYY hh:mm')
                        : col.id === 'log_type' ? checkInStatus[data[col.id]] : data[col.id]}
            </TableCell>
        )
    }

    console.log("data data 9898", data);

    return (
        <>
            <TableRow key={`data-${index}`} sx={CheckInDestinationCell}>
                {checkInDestinationCols.map((col) => renderCell(col))}
            </TableRow>
            {open && <TableRow sx={CheckInDestinationCell}>
                <TableCell colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <MapHeader>GPS/map data:</MapHeader>
                        <GooglMap markerList={[{ lat_long: data.gps, name: data.street_address }]} showCoord={false} />
                        <div style={{ marginTop: '2%', color: COLORS.grey }}>GPS coordinates: {data.gps[0] && data.gps[0]} {data.gps[1] && data.gps[1]}</div>

                        <MapHeader>Check In Comment</MapHeader>
                        <CheckInComment>{data.msg || '-'}</CheckInComment>
                    </Collapse>
                </TableCell>
            </TableRow>}
        </>
    )
}