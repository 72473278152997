import axios from '../../helper/customAxios';
import { GET_TRAVEL_LIST, RESET_TRAVEL_LIST, ADD_TRAVEL, RESET_ADD_STATUS, GET_ALL_USERS, GET_TRAVEL_LOG_DETAIL, ADD_TRAVEL_COMMENT, RESET_TRAVEL_COMMENT, UPDATE_TRAVEL_STATUS, GET_ACTIVE_TRAVEL, RESET_UPDATE_STATUS, FETCH_SUCCESS, UPDATE_ALERT_LIST, UPDATE_IN_HOUSE_LIST, UPDATE_TRAVEL_RAISED_COUNT } from '../../@jumbo/constant/ActionTypes';
import { travelList, addTravel, allUserList, travelLogDetail, addTravelCommentURL, updateTravelStatus, activeTravelList } from '../../@jumbo/constant/ApiConstant'
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { refreshToken } from './Auth';
export const getTravelList = (params, isTravelTab = true) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.get(travelList, { params: params }).then((res) => {
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type: GET_TRAVEL_LIST, payload: res.data.data, status: res.status });
                dispatch({ type: UPDATE_IN_HOUSE_LIST, payload: { total_inhouse: res.data.data?.total_inhouse } });
                dispatch({ type: UPDATE_ALERT_LIST, payload: { total_alerts: res.data.data?.total_alerts } });
                if (isTravelTab && res?.data?.data?.total_no_of_records) {
                    dispatch({ type: UPDATE_TRAVEL_RAISED_COUNT, payload: { raisedTravelCount: res?.data?.data?.total_no_of_records } });
                }
            } else {
                dispatch({ type: GET_TRAVEL_LIST, payload: null });
            }
            dispatch({ type: FETCH_SUCCESS, loading: false })
        }).catch((err) => {
            dispatch({ type: GET_TRAVEL_LIST, payload: null, status: err.response && err.response.status ? err.response.status : 400 });
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getTravelList(params, isTravelTab = true))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                // console.log(err.response.data && err.response.data.message)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                // console.log(err.response && err.response.data && err.response.data.message ? err.response.data.message : '' )
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        });
    }
}

export const resetTravelList = () => {
    return dispatch => {
        dispatch({ type: RESET_TRAVEL_LIST })
    }
}

export const getActiveTravel = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.get(activeTravelList, params).then((res) => {
            if (res.status === 200) {
                dispatch({ type: GET_ACTIVE_TRAVEL, payload: res.data.data });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: GET_ACTIVE_TRAVEL, payload: [] });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getActiveTravel(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                // console.log(err.response)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
            else {
                // console.log(err)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}


export const createTravel = (data) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.post(addTravel, data).then((res) => {
            if (res.status === 200) {
                dispatch({ type: ADD_TRAVEL, payload: res.data, status: 200 });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: ADD_TRAVEL, payload: {}, status: 200 });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(createTravel(data))
                }))
                return;
            }
            if (err.response && err.response.status && err.response.status === 401) {
                // console.log(err.response)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
            else {
                // console.log(err)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const resetStatus = () => {
    return dispatch => {
        dispatch({ type: RESET_ADD_STATUS })
    }
}

export const getUserList = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.get(allUserList, { params: params }).then(res => {
            if (res.status === 200) {
                if (!res.data.data.userData.length) {
                    dispatch({ type: GET_ALL_USERS, payload: [] });
                    dispatch({ type: FETCH_SUCCESS, loading: false })
                } else {
                    dispatch({ type: GET_ALL_USERS, payload: res.data.data });
                    dispatch({ type: FETCH_SUCCESS, loading: false })
                }
            } else {
                dispatch({ type: GET_ALL_USERS, payload: [] });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getUserList(params))
                }))
                return;
            }
            if (err.response && err.response.status && err.response.status === 401) {
                console.log(err.response)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
            else {
                console.log(err)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })

    };
};

export const getTravelLogDetail = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.get(`${travelLogDetail}/${params.travel_id}`).then((res) => {
            if (res.status === 200) {
                dispatch({ type: GET_TRAVEL_LOG_DETAIL, payload: res.data.data });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: GET_TRAVEL_LOG_DETAIL, payload: [] });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getTravelLogDetail(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                console.log(err.response)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
            else {
                console.log(err)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const addTravelComment = (id, comment) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.post(`${addTravelCommentURL}/${id}`, comment).then((res) => {
            if (res.status === 200) {
                dispatch({ type: ADD_TRAVEL_COMMENT, payload: res.data.data });
                dispatch({ type: FETCH_SUCCESS, loading: false });
                dispatch(getTravelLogDetail({ travel_id: id }));
            } else {
                dispatch({ type: ADD_TRAVEL_COMMENT, payload: {} });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(addTravelComment(id, comment))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                console.log(err.response)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
            else {
                console.log(err)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const resetTravelComment = () => {
    return dispatch => {
        dispatch({ type: RESET_TRAVEL_COMMENT })
    }
}

export const UpdateTravelStatus = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart());
        axios.post(`${updateTravelStatus}/${params.travel_id}`, { status: params.status, test_mode: params.test_mode }).then((res) => {
            if (res.status === 200) {
                dispatch({ type: UPDATE_TRAVEL_STATUS, payload: { ...res.data, travel_id: params.travel_id } });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: UPDATE_TRAVEL_STATUS, payload: {} });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(UpdateTravelStatus(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                console.log(err.response)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
            else {
                console.log(err)
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const resetUpdatestatus = () => {
    return dispatch => {
        dispatch({ type: RESET_UPDATE_STATUS })
    }
}
