import { Alert } from "@mui/material";
import { ADD_USER, GET_ALERTLIST, ALERT_LOG_LIST, ADD_ALERTCOMMENT, ADD_TENANT, SET_ALERT, RESET_ALERT_COMMENT, UPDATE_IN_HOUSE_LIST, UPDATE_TRAVEL_LIST } from "../../@jumbo/constant/ActionTypes";
import { Adduser, alertListCrisisLog, alertLogList, getAlertDetails, editSubdivision, EditUser, AddAlertComment } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS } from '../../@jumbo/constant/ActionTypes';
import { refreshToken } from "./Auth";

export const getAlertList = (alert_type, filterList, activePerPage, activePage) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios.get(alertListCrisisLog, {
      params: {
        alert_type: alert_type,
        subdivisions: filterList.toString(),
        limit: activePerPage,
        page: activePage,
      },
    })
      .then(data => {

        if (data.status === 200) {
          if (!data.data.data.alertLogs.length) {
            dispatch({ type: GET_ALERTLIST, payload: [] });
          } else {

            dispatch({ type: GET_ALERTLIST, payload: data.data.data });
          }
          if (data?.data?.data) {
            dispatch({ type: UPDATE_IN_HOUSE_LIST, payload: { total_inhouse: data.data.data?.total_inhouse } });
            dispatch({ type: UPDATE_TRAVEL_LIST, payload: { total_travel: data?.data?.data?.total_travel } });
          }
        } else {
          dispatch({ type: GET_ALERTLIST, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getAlertList(alert_type, filterList, activePerPage, activePage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {

          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: GET_ALERTLIST, payload: [] });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: GET_ALERTLIST, payload: [] });
          // dispatch(fetchError(error.response.data.message));
        }
      });

  };
};


export const addNewUser = (user, callbackFun) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .post(Adduser, user)
      .then(data => {

        if (data.status == 200) {

          // alert("jfgjh")
          if (callbackFun) callbackFun.setAddDialog();
          dispatch({ type: FETCH_SUCCESS, loading: false });
          setTimeout(() => {
            dispatch(fetchSuccess('User added successfully.'));

          }, 4000);


          //   if (callbackFun) callbackFun(data.data);
          // } else {

          // dispatch({ type: ADD_TENANT, payload: data.data });
          // dispatch({ type: ADD_TENANT, payload:data.data});
        }
        else {
          dispatch({ type: ADD_TENANT, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addNewUser(user, callbackFun))
          }))
          return;
        }
        // dispatch({ type: ADD_TENANT, payload:[]});
        if (error.response.status === 401 || error.response.status === 400) {
          //   dispatch(setAuthUser(null));
          //   history.push('/signin');
          // } else {
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
      });
  };
};

export const resetAlertComment = () => {
  return dispatch => {
    dispatch({ type: RESET_ALERT_COMMENT })
  }
}

export const addAlertComment = (id, addComment) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios.post(AddAlertComment + id, addComment).then((res) => {

      if (res.status === 200) {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        // dispatch({ type: ADD_ALERTCOMMENT, payload: res.data.data });
        dispatch(getAlertDetail(id));
      } else {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        dispatch({ type: ADD_ALERTCOMMENT, payload: {} });
      }
    }).catch((err) => {
      if (err.response && err.response.status == 444) {
        dispatch(refreshToken(() => {
          dispatch(addAlertComment(id, addComment))
        }))
        return;
      }
      if (err && err.response && err.response.status === 401) {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        console.log(err.response)
      }
      else {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        console.log(err)
      }
    })
  }
}

export const getAlertDetail = (id, setLoader) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    // setLoader(true)
    axios.get(getAlertDetails + id)
      .then(data => {
        if (data.status === 200) {
          // setLoader(false)
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: SET_ALERT, payload: data.data.data });
        } else {
          // setLoader(false)
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: SET_ALERT, payload: [] });
        }
        dispatch(fetchSuccess());
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getAlertDetail(id, setLoader))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // setLoader(false)
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: SET_ALERT, payload: [] });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          // setLoader(false)
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: SET_ALERT, payload: [] });
          // dispatch(fetchError(error.response.data.message));
        }
        dispatch(fetchError());
      });

  };
};
export const updateUser = (data, callbackFun, list) => {
  return dispatch => {

    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .put(EditUser, data)
      .then(response => {
        if (data) {
          // alert("update")
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun.setAddDialog();

          setTimeout(() => {
            dispatch(fetchSuccess('User updated successfully.'));
          }, 4000);
          // if(list) list()
        }
        else {
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateUser(data, callbackFun, list))
          }))
          return;
        }
        if (error.response.status === 401 || error.response.status === 400) {
          //   dispatch(setAuthUser(null));
          //   history.push('/signin');
          // } else {
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const updateSubdivision = (user) => {
  return dispatch => {

    // dispatch(fetchStart());
    axios
      .put(editSubdivision, user)
      .then(data => {
        // setTimeout(() => {
        //   dispatch(fetchSuccess('Service activity updated successfully .'));
        // }, 2000);
        //   if (callbackFun) callbackFun();
        // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });

      })
    // .catch(error => {

    //   if(error?.response?.status===401 ){
    //     dispatch(setAuthUser(null));
    //     history.push('/signin')
    //   } 
    //   else {
    //     console.log('error',error?.response?.status)
    //     dispatch(fetchError(error?.response?.data?.message));
    //   }
    // });
  };
};

// export const deleteSubdivision = (userId) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .delete(deleteClassCategorie + userId)
//       .then(data => {
//         if (data.status === 200) {
//           setTimeout(() => {
//             dispatch(fetchSuccess('Subdivision deleted successfully.'));
//           }, 2000);
//           // if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError());
//         }
//       })
//       .catch(error => {
//         dispatch(fetchError(error?.response?.data?.message));
//       });
//   };
// };

export const getAlertTravelLogsList = (id, page, limit, for_alert) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios.get(`${alertLogList}/${id}`, {
      params: {
        page,
        limit,
        for_alert
      },
    }).then(data => {
      if (data.status === 200) {
        if (!data.data.data.logs.length) {
          dispatch({ type: ALERT_LOG_LIST, payload: data.data.data });
        } else {
          // console.log("logList1111111", data);
          dispatch({ type: ALERT_LOG_LIST, payload: data.data.data });
        }
      } else {
        dispatch({ type: ALERT_LOG_LIST, payload: [] });
      }
      dispatch({ type: FETCH_SUCCESS, loading: false });
    })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getAlertTravelLogsList(id, page, limit, for_alert))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch(fetchError(error.response.data.message));
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
        dispatch({ type: ALERT_LOG_LIST, payload: [] });
      });

  };
};