import React, { useState, useEffect } from 'react'
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addTravelComment, getTravelLogDetail } from 'redux/actions/travel';
import Loader from '../../Loader';
import { CrisisContainer, DetailHeader, Bullet, UserNameContainer, TravelIdContainer, NameTravelIDBox } from "../style";
import CrisisLog from './CrisisLog'
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LogActivity from "../logActivity";
import { CHECKLIST_TYPE } from 'utils/alertHelper';
import { TRAVEL_STATUS } from 'modules/Travel/data';
export default function Detail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const printHeadRef = useRef(null)
    const [loader, setLoader] = useState(false)
    const [activeTab, setActiveTab] = useState(0);
    const { travelLogDetail } = useSelector(({ TRAVEL }) => TRAVEL)
    const userLogDetail = travelLogDetail.UserTravelDetails ? travelLogDetail.UserTravelDetails : {}
    // const travel_id = location && location.pathname && location.pathname.split('/').pop() //'63be832f2e65b3d1f61f25bf'
    const { id: travel_id } = useParams();

    useEffect(() => {
        setLoader(true)
        dispatch(getTravelLogDetail({ travel_id }))
    }, [])

    useEffect(() => {
        if (Object.keys(userLogDetail).length > 0) {
            setTimeout(() => setLoader(false), 3000)
        }
    }, [userLogDetail])
    const testMode = () => {
        return <div className="test-mode">Test Mode</div>
    }
    return (
        loader ? <Loader /> : <CrisisContainer>
            {userLogDetail.test_mode ? testMode() : null}
            <div ref={printHeadRef}>
                <DetailHeader>Crisis log, Ongoing events</DetailHeader>
                <NameTravelIDBox>
                    <UserNameContainer>
                        {/* <Bullet style={{ backgroundColor: '#5FAAB0' }} /> */}
                        <FiberManualRecordIcon style={{ fontSize: "10px", color: 'rgb(95, 170, 176)', marginRight: '5px' }} />
                        User: {`${userLogDetail && userLogDetail.userFirstname || ''} ${userLogDetail && userLogDetail.userLastname || ''}`}</UserNameContainer>
                    <TravelIdContainer>Travel ID: <b>{userLogDetail && userLogDetail.id ? userLogDetail.id : ''}</b></TravelIdContainer>
                </NameTravelIDBox>
                <DetailHeader style={{ display: 'none' }} className="capitalize">{CHECKLIST_TYPE?.TRAVEL.title} Logs</DetailHeader>
            </div>
            <LogActivity addLogAPI={addTravelComment} alertType={CHECKLIST_TYPE.TRAVEL} isCompleted={travelLogDetail?.UserTravelDetails?.status == TRAVEL_STATUS?.COMPLETED} setActiveTab={setActiveTab} activeTab={activeTab} />
            {activeTab === 0 && <CrisisLog travel_id={travel_id} navigate={navigate} printHeadRef={printHeadRef} />}
        </CrisisContainer>
    )
}