import { Component } from "react";
import React, { useState, useRef, useEffect } from "react";
// import { PersonOutline, LockClosedOutline, KeyOutline } from "react-ionicons";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import "./login.css";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";

// import { login } from "redux/actions/auth.action"
import { login } from "redux/actions/auth.action";
import { isValidEmail } from "utils/commonHelper";
import {
  requiredMessage,
  lettersOnlyMessage,
  phoneOnlyNumericals,
  onlyNumberAllow,
  emailNotValid,
  alphaNumaricMessage,
} from "@jumbo/constant/ErrorMessages";
import Loader from "../Loader";
import header from "applayout/header/index";

// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header from "applayout/header/index";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Buttons } from "../Button";
import { TextFields } from "../Textfeild";
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions/Common';
import { FETCH_SUCCESS } from "@jumbo/constant/ActionTypes";
import { USER_TYPE } from "utils/commonHelper";
import onLogin from "redux/actions/Auth";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function Login() {
  let navigate = useNavigate();

  const { currentuser, loader } = useSelector(({ USERPROFILE }) => USERPROFILE);
  const loader1 = useSelector(({ common }) => common.loading);
  const { user } = useSelector(({ auth }) => auth);
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userCode, setUserCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const [pswVal, setPswVal] = useState("");
  const passwordField = useRef();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [showPassword, setShowPassword] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const Profile = JSON.parse(localStorage.getItem("profile"));
  const currentUser = useSelector(store => store?.USERPROFILE?.currentuser?.detail)
  let loginUser = !!Profile ? Profile.user_type : null;
  let control_user = !!Profile ? Profile.control_user : null

  useEffect(() => {
    if ((currentUser?.has_access_to?.in_house || currentUser?.has_access_to?.alert_travel) && ([USER_TYPE.SUBDIVISION_USER, USER_TYPE.EXTERNAL_CONTROLLER, USER_TYPE.CONTROL_USER, USER_TYPE.END_USER].includes(loginUser) || control_user)) {
      navigate((currentUser?.has_access_to?.alert_travel ? "/app/Alert" : "/app/Inhouse"));
    } else if (loginUser == USER_TYPE.TENANT_USER) {
      navigate("/app/SubDivisions");
    } else if (loginUser == USER_TYPE.ADMIN) {
      // || loginUser == USER_TYPE.SUB_ADMINS
      navigate("/app/dashboard");
    } else if (loginUser == USER_TYPE.SEC_CONTROLLER) {
      navigate("/app/Tenants");
    } else if (currentUser) {
      navigate("/app/users");
    }
  }, [currentUser])

  const redirectPath = () => {
    navigate("/login");
  };

  function emailValidation(email) {
    //eslint-disable-next-line
    // const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      setHelperText(true)
      setEmailVal(requiredMessage);
    } else if (!isValidEmail(email)) {
      setHelperText(true)
      setEmailVal(emailNotValid);
      return false;
    } else {
      setEmailVal("");
      return true;
    }
  }

  function passValidation(password) {
    if (password === "") {
      setHelperText(true)
      setPswVal(requiredMessage);
      return false;
    } else {
      setPswVal("");
      return true;
    }
  }

  const onChangeUsername = (e) => {
    const email = e.target.value;
    setEmail(email);
    setEmailVal("");
    setHelperText(false)
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPswVal("");
    setHelperText(false)
  };

  const handleForfotPassword = () => {
    navigate("/ForgotPassword");
  };

  const handleLogin = (e) => {
    // e.preventDefault();
    form.current.validateAll();
    let emailVal = emailValidation(email);
    let pswVal = passValidation(password);

    if (emailVal && pswVal) {
      dispatch(onLogin(email, password, redirectPath, userCode))
    }
  };


  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      passwordField.current.focus();
      handleLogin();
    }
  };
  if (!currentUser && !localStorage.getItem('userToken')) {
    return (
      <>
        <div className="login-container">
          <div className="loginForm">
            <p className="loginTxt">Sign in</p>
            <Form ref={form}>
              <div className="loginFeild">

                <i className="icon-user icons" style={{ color: email ? " rgb(72, 146, 195)" : " rgb(174, 180, 192)", marginTop: '24px' }} ></i>
                <TextFields

                  label="Login"
                  variant="standard"
                  name="email"
                  value={email}
                  onChange={onChangeUsername}
                  validations={[required]}
                  onKeyDown={handleEnterKey}
                  helperText={emailVal}
                  helperProps={emailVal ? helperText : false}
                  errorProps={emailVal ? true : false}
                />
              </div>

              <div className="loginFeild">
                <i className="icon-lock" style={{ color: password ? " rgb(72, 146, 195)" : " rgb(174, 180, 192)", marginTop: '24px' }}></i>
                <TextFields
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                  onKeyDown={handleEnterKey}
                  helperText={pswVal}
                  helperProps={pswVal ? helperText : false}
                  errorProps={pswVal ? true : false}
                  inputRef={passwordField}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <i className="icon-eye" onClick={handleClickShowPassword}
                        aria-label="toggle password visibility" style={{ color: showPassword ? " rgb(72, 146, 195)" : " rgb(174, 180, 192)" }}
                      ></i>
                    ),
                  }}

                />
              </div>

              <div className="loginFeild">
                <i className="icon-key" style={{ color: userCode ? " rgb(72, 146, 195)" : " rgb(174, 180, 192)", marginTop: '24px' }}></i>
                <TextFields
                  className="inputField"
                  label="Group code"
                  type="text"
                  variant="standard"
                  value={userCode}
                  onChange={(e) => setUserCode(e.target.value)}
                />
              </div>
              <Buttons onClick={handleLogin} style={{ width: "100%", marginTop: '40px', marginLeft: '0px' }} buttoncheck={true}> Sign In</Buttons>


              <a className="forgot-link"
                onClick={handleForfotPassword}
                component="button"
                variant="body2"
                underline="none"
                style={{
                  display: "flex",
                  webkitBoxPack: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >

                <span
                  style={{
                    cursor: "pointer",
                    fontFamily: "SF Pro Text",
                    fontSize: "15px",
                    color: "rgb(72, 146, 195)",
                  }}
                >
                  Forgot Password?
                </span>
              </a>
            </Form>
          </div>
        </div>
      </>
    );
  }

}
