import ReactTable from 'components/common/table/ReactTable';
import React, { useEffect, useState } from 'react'
import Add from './Add';
import Completed from './Completed';
import Delete from './Delete';
import { useDispatch, useSelector } from "react-redux";
import { getActionListForFocusBoard } from 'redux/actions/focusBoard';
import { useParams } from 'react-router';
import * as moment from "moment";
import { USER_TYPE } from 'utils/commonHelper';

const FocusBoard = ({ alertType, isCompleted }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const listOfFocusBoard = useSelector(({ FOCUSBOARD }) => FOCUSBOARD.allFocusBoard);
    // const { currentuser, socektObj } = useSelector(({ USERPROFILE }) => USERPROFILE);
    const currentUser = JSON.parse(localStorage.getItem("profile"));
    // const subdiv_user = currentUser.user_type === USER_TYPE.SUBDIVISION_USER;
    const [particulerRowId, setParticulerRowId] = useState('')
    const [updateFocusBoard, setUpdateFocusBoard] = useState();
    console.log("alertType", alertType)
    // let particulerRowId;
    // console.log("listOfFocusBoard",listOfFocusBoard)

    useEffect(() => {
        const actionListData = {
            id: id,
            isForTravel: alertType?.value === 3 ? true : false
        }
        dispatch(getActionListForFocusBoard(actionListData))
    }, [])

    const assignParticularActionIdForComplete = (id) => {
        if (!isCompleted) {
            setParticulerRowId(id);
            setOpenCompleteDialog(prev => !prev)
        }
    }

    const assignParticularActionIdForDelete = (id) => {
        if (!isCompleted) {
            setParticulerRowId(id);
            setOpenDeleteDialog(prev => !prev)
        }
    }

    const columns = [
        {
            title: 'Created', accessor: 'created_at',
            Component: ({ row }) => {
                // return moment(${new Date(row?.created_at)}`).format('DD-MM-YYYY hh:mm')
                return (
                    <>
                        <span className={'font-bold'}>{moment(`${new Date(row?.created_at)}`).format('DD-MM-YYYY hh:mm')}</span>
                    </>
                );
            }
        },
        {
            title: 'Action', accessor: 'action',
            Component: ({ row }) => {
                return (
                    <>
                        <span className={'font-bold'}>{row?.action}</span>
                    </>
                );
            }
        },

        {
            title: 'Responsible', accessor: 'responsible_person',
            Component: ({ row }) => {
                return (
                    <>
                        <span className={'font-bold'}>{row?.responsible_person}</span>
                    </>
                );
            }
        },
        {
            title: 'Estimated time to complete', accessor: 'est_time',
            Component: ({ row }) => {
                return (
                    <>
                        <span className={'font-bold'}>{row?.est_time}</span>
                    </>
                );
            }
        },
        {
            title: 'Actions', accessor: 'isCompleted',
            Component: ({ row }) => {
                console.log("row isCompleted", row)
                return (
                    <>
                        {/* className={`underline text-[#4892c3] mt-3 ${isCompleted ? 'cursor-not-allowed opacity-50' : 'cursor-pointer '}`} */}
                        <span className={`underline text-[#4892c3] mt-3 ${isCompleted ? 'cursor-not-allowed opacity-100' : 'cursor-pointer '}`} onClick={() => { assignParticularActionIdForComplete(row?._id) }}>Complete</span>
                        {currentUser && currentUser?.user_type !== USER_TYPE.ADMIN
                            && <span className={`underline ml-5 text-gray-600 ${isCompleted ? 'cursor-not-allowed opacity-100' : 'cursor-pointer '}`} onClick={() => {
                                if (!isCompleted) {
                                    setUpdateFocusBoard(row);
                                    setOpenDialog(true);
                                }
                            }}>
                                Edit
                            </span>}
                        {currentUser && currentUser?.user_type !== USER_TYPE.ADMIN || currentUser && currentUser?.user_type !== USER_TYPE.TENANT_USER || currentUser && currentUser?.user_type !== USER_TYPE.END_USER ? <span className={`underline text-red-600 ml-5 mt-3 ${isCompleted ? 'cursor-not-allowed opacity-100' : 'cursor-pointer '}`} onClick={() => { assignParticularActionIdForDelete(row?._id) }}>Delete</span> : null}
                    </>
                );
            }
        },
    ];
    // if(listOfFocusBoard)
    // {
    //     const data = listOfFocusBoard?.map((item)=>{  
    //             'id'= item?._id;
    //             'action'= item?.action;
    //             'created_date'= moment(`${(item?.created_at)}`).format('DD-MM-YYYY hh:mm');
    //             'responsible_person= item?.responsible_person;
    //             est_time= item?.est_time;
    //             isCompleted= item?.is_completed
    // })
    // }


    const [openDialog, setOpenDialog] = useState(false);
    const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    return (
        <>
            {listOfFocusBoard?.length > 0 ?
                <ReactTable
                    COLUMNS={columns}
                    DATA={listOfFocusBoard}
                />
                : <div className='border-b-2 border-t-2 py-4 text-[#5c6373]'>There are no action to display</div>}
            {currentUser && currentUser?.user_type !== USER_TYPE.ADMIN || currentUser && currentUser?.user_type !== USER_TYPE.TENANT_USER || currentUser && currentUser?.user_type !== USER_TYPE.END_USER ? <div className={`underline text-[#4892c3] mt-3 ${isCompleted ? 'cursor-not-allowed opacity-50' : 'cursor-pointer '}`} onClick={() => { if (!isCompleted) { setOpenDialog(prev => !prev) } }}>Create new action</div> : null}
            <Add openDialog={openDialog} setOpenDialog={setOpenDialog} type={alertType?.value} updateFocusBoard={updateFocusBoard} setUpdateFocusBoard={setUpdateFocusBoard} />
            <Completed particulerRowId={particulerRowId} openCompleteDialog={openCompleteDialog} setOpenCompleteDialog={setOpenCompleteDialog} type={alertType?.value} />
            <Delete particulerRowId={particulerRowId} openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} type={alertType?.value} />
        </>
    )
}

export default FocusBoard