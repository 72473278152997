import React from 'react'
import { Outlet } from 'react-router'
import SideBar from './sideBar'
import Grid from '@mui/material/Grid';
import Header from './header';
const Dashboard = () => {
  return (
    <>
      <Header />
      <Grid container style={{ width: "100%", height: 'calc(100vh - 79px)' }}>
        <Grid item style={{ maxWidth: "245px", width: "100%" }} >
          <SideBar />

        </Grid>
        <Grid item style={{ maxWidth: 'calc(100% - 245px)', padding: "40px 30px", width: '100%' }} >
          <Outlet />
          {/* <-- nested routes rendered here */}
        </Grid>
      </Grid>
      {/* <SideBar/> */}
      {/* <Outlet/> */}
    </>
  )
}
export default React.memo(Dashboard);