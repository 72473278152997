import * as moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import COLORS from 'utils/colors'
import CheckInDestination from './CheckInDestination'
import { Button } from '@mui/material'
import { BasicInfoTitle, DetailHeader, UserBasicInfoContainer, UserDetailContainer, UserDetailTitle, BasicInfoValue, TravelInfoValue, TravelInfoContainer, AddButton, DetailHeaderWrapper } from '../style'
import CrisisLog from './CrisisLog'
import { getTravelLogDetail, resetUpdatestatus, UpdateTravelStatus } from 'redux/actions/travel';
import { useLocation, useNavigate, useParams } from 'react-router'
import Loader from '../../Loader'
import { DeleteDialog } from './DeleteDialog'
import { TRAVEL_STATUS } from '../data'

export default function TravelDetail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [loader, setLoader] = useState(false)
    const [endLoader, setEndLoader] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { travelLogDetail, updateTravel } = useSelector(({ TRAVEL }) => TRAVEL)
    const userLogDetail = travelLogDetail.UserTravelDetails ? travelLogDetail.UserTravelDetails : {}
    const { id: travel_id } = useParams();
    const currentUser = JSON.parse(localStorage.getItem('profile'))
    const { policenumber } = useSelector(({ POLICENUMBER }) => POLICENUMBER)
    console.log("travelLogDetail", travelLogDetail?.UserTravelDetails?.status)
    console.log("userLogDetail", userLogDetail)
    useEffect(() => {
        setLoader(true)
        dispatch(getTravelLogDetail({ travel_id }))
    }, [])

    useEffect(() => {
        if (travelLogDetail?.UserTravelDetails?.status === 2) {
            navigate('/app/Travel')
        }
    }, [travelLogDetail?.UserTravelDetails?.status])

    useEffect(() => {
        setTimeout(() => setLoader(false), 3000)
    }, [userLogDetail])

    useEffect(() => {
        setEndLoader(false)
        if (Object.keys(updateTravel).length > 0) {
            navigate('/app/travel')
            dispatch(resetUpdatestatus())
        }
    }, [updateTravel])

    const onEndTravel = () => {
        setShowDeleteModal(false)
        setEndLoader(true)
        dispatch(UpdateTravelStatus({ travel_id, status: 2, test_mode: (userLogDetail ? userLogDetail?.test_mode : null) }))
    }


    console.log("userLogDetail", userLogDetail?.status, currentUser?.user_type)

    const renderUserDetailContainer = () => {
        return (
            <UserDetailContainer style={{ marginTop: '35px' }}>
                <UserDetailTitle>Contact Info:</UserDetailTitle>
                <UserBasicInfoContainer>
                    <div>
                        <BasicInfoTitle>User phone:</BasicInfoTitle>
                        <BasicInfoValue>{userLogDetail && userLogDetail.userMobileNo}</BasicInfoValue>
                    </div>
                    <div>
                        <BasicInfoTitle>User email:</BasicInfoTitle>
                        <BasicInfoValue>{userLogDetail && userLogDetail.userEmail}</BasicInfoValue>
                    </div>
                    <div>
                        <BasicInfoTitle>Local police number:</BasicInfoTitle>
                        <BasicInfoValue>{userLogDetail && userLogDetail.policeNumber || '-'}</BasicInfoValue>
                    </div>
                </UserBasicInfoContainer>
                <div style={{ marginTop: '10px' }}>
                    <UserDetailTitle>Travel reason:</UserDetailTitle>
                    <BasicInfoValue style={{ color: COLORS.grey }}>{userLogDetail ? userLogDetail.reason : '-'}</BasicInfoValue>
                </div>
                <div>
                    <UserDetailTitle>Travel info:</UserDetailTitle>
                    <BasicInfoValue style={{ color: COLORS.grey }}>{userLogDetail ? userLogDetail.comment : '-'}</BasicInfoValue>
                </div>
            </UserDetailContainer>
        )
    }

    const renderTravelInfo = (title, value) => {
        return (
            <TravelInfoContainer style={{ paddingBottom: '8px', marginBottom: '20px' }}>
                <BasicInfoTitle style={{ marginBottom: !value && '26px' }}>{value ? title : ''}</BasicInfoTitle>
                <TravelInfoValue style={{ color: value === 'Disabled' ? COLORS.redtext : !value && 'rgb(133, 142, 160)' }}>{value ? value : title}</TravelInfoValue>
            </TravelInfoContainer>
        )
    }
    const testMode = () => {
        return <div className="test-mode">Test Mode</div>
    }
    const renderTravelInfoCard = () => {
        return (
            <div style={{ marginTop: '35px', width: '78%' }}>

                <DetailHeader>Travel information</DetailHeader>
                <UserBasicInfoContainer style={{ marginTop: '20px', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ width: '50%', color: userLogDetail.is_enabled ? 'rgb(95, 170, 176)' : 'red' }}>
                        {renderTravelInfo('Auto check in status', userLogDetail.is_enabled ? userLogDetail.mode === 2 && userLogDetail.is_enabled ? `${userLogDetail.value} mins` : `${userLogDetail.value} meters` : 'disabled')}
                    </div>
                    <div style={{ width: '50%', marginLeft: '20px' }}>
                        {renderTravelInfo('Planned country', userLogDetail && userLogDetail.country ? userLogDetail.country : '-')}
                        {renderTravelInfo('Planned city', userLogDetail && userLogDetail.city ? userLogDetail.city : '-')}
                        {renderTravelInfo('Planned street', userLogDetail && userLogDetail.street_address ? userLogDetail.street_address : '-')}
                        {renderTravelInfo('End travel time', userLogDetail && userLogDetail.arrivalTime ? moment(userLogDetail.arrivalTime, 'x').format('DD-MM-YYYY HH:mm') : moment().format('DD-MM-YYYY HH:mm'))}
                    </div>
                </UserBasicInfoContainer>
            </div>
        )
    }

    return (
        loader ? <Loader /> : <div>
            {userLogDetail.test_mode ? testMode() : null}
            <DetailHeaderWrapper>

                <DetailHeader>User: {`${userLogDetail && userLogDetail.userFirstname || ''} ${userLogDetail && userLogDetail.userLastname || ''}`}</DetailHeader>
                {currentUser?.user_type === 4 && userLogDetail?.status !== TRAVEL_STATUS?.IN_ACTIVE ?
                    <Button
                        variant="contained"
                        style={{ ...AddButton, width: '20%', fontSize: '16px', backgroundColor: 'rgb(211, 47, 47)' }}
                        onClick={() => setShowDeleteModal(true)}
                    >{'End Travel'}</Button>
                    : null}
            </DetailHeaderWrapper>
            {renderUserDetailContainer()}
            {renderTravelInfoCard()}
            {userLogDetail?.status !== TRAVEL_STATUS?.IN_ACTIVE && 
            <CheckInDestination checkInDestinationData={userLogDetail && userLogDetail.checkInDestinations || []} />}
            {userLogDetail?.status !== TRAVEL_STATUS?.IN_ACTIVE && <CrisisLog travel_id={travel_id} navigate={navigate} />}
            <DeleteDialog
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={onEndTravel}
                endLoader={endLoader}
            />
        </div>
    )
}