import { Alert } from "@mui/material";
import { ADD_USER, GET_USERSLIST, GET_USER_DETAILS, ADD_TENANT, SET_USER } from "../../@jumbo/constant/ActionTypes";
import { Adduser, usersList, getuserDetails, editSubdivision, EditUser, DeleteTenant, StatisticsCount } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS, STATISTICS_COUNT } from '../../@jumbo/constant/ActionTypes';
import { refreshToken } from "./Auth";


export const getUsersList = (user_type, sortBy, sort, searchValue, activePage, activePerPage) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios.get(usersList, {
      params: {
        user_type: user_type, page: activePage, limit: activePerPage, sortBy: `${sortBy}:${sort}`, q: searchValue,
      },

    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {

          if (!data.data.data.userData.length) {
            dispatch({ type: GET_USERSLIST, payload: [] });
          } else {
            dispatch({ type: GET_USERSLIST, payload: data.data.data });
          }
        } else {
          dispatch({ type: GET_USERSLIST, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getUsersList(user_type, sortBy, sort, searchValue, activePage, activePerPage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};


export const addNewUser = (user, callbackFun) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .post(Adduser, user)
      .then(data => {

        if (data.status == 200) {
          if (callbackFun) callbackFun.setAddDialog();
          dispatch({ type: FETCH_SUCCESS, loading: false });
          setTimeout(() => {
            dispatch(fetchSuccess('User added successfully.'));

          }, 4000);
        }
        else {
          dispatch({ type: ADD_TENANT, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addNewUser(user, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          // if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
      });
  };
};




//   export const addpost = () => {
//   return dispatch => {
//     axios
//       .post('https://www.google.com/recaptcha/api2/reload/?k=6LdxmuEjAAAAAJtbzV7Uv73h8Mh_3fEYkuQR_2JF')
//       .then(data => {

//         if (data) {
//           console.log("datacheck",data)
//    }

//       });
//   };
// };

export const getCurrUserDetail = (id) => {
  return dispatch => {
    dispatch(fetchStart());
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    axios.get(getuserDetails, {
      params: {
        user_id: id,

      },
    })
      .then(data => {
        console.log("errorwewewe22", data);
        if (data.status === 200) {
          // dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: SET_USER, payload: data.data.data });
        } else {
          dispatch({ type: SET_USER, payload: [] });
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getCurrUserDetail(id))
          }))
          return;
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      });

  };
};


export const updateUser = (data, callbackFun, list) => {
  return dispatch => {

    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .put(EditUser, data)
      .then(response => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          if (callbackFun) callbackFun.setAddDialog();
          setTimeout(() => {
            dispatch(fetchSuccess('User updated successfully.'));
          }, 4000);
        }
        else {
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateUser(data, callbackFun, list))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          // if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const updateSubdivision = (user) => {
  return dispatch => {

    // dispatch(fetchStart());
    axios
      .put(editSubdivision, user)
      .then(data => {
        // setTimeout(() => {
        //   dispatch(fetchSuccess('Service activity updated successfully .'));
        // }, 2000);
        //   if (callbackFun) callbackFun();
        // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });

      })
    // .catch(error => {

    //   if(error?.response?.status===401 ){
    //     dispatch(setAuthUser(null));
    //     history.push('/signin')
    //   } 
    //   else {
    //     console.log('error',error?.response?.status)
    //     dispatch(fetchError(error?.response?.data?.message));
    //   }
    // });
  };
};

export const deleteUsers = (userId, callbackFun, list) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .get(DeleteTenant + userId)
      .then((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            if (callbackFun) callbackFun.setAddDialog();
          }, 1000);
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess("User deleted Sucessfully."));
          }, 4000);
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(deleteUsers(userId, callbackFun, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const getStatiSticsCount = (tenant, subDiv) => {
  let URL
  if (tenant || subDiv) {
    URL = `${StatisticsCount}?tenants=${tenant}&subdivisions=${subDiv}`
  } else {
    URL = StatisticsCount
  }
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .get(URL)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: STATISTICS_COUNT, payload: data?.data?.data });
          dispatch({ type: FETCH_SUCCESS, loading: false });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getStatiSticsCount(tenant, subDiv))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};