
import { USEFUL_LINK_FOR_TRAVEL_LIST, ADD_USEFUL_LINK_FOR_TRAVEL, UPDATE_USEFUL_LINK_FOR_TRAVEL, REMOVE_USEFUL_LINK_FOR_TRAVEL, SET_USEFUL_LINK_TRAVEL } from "../../@jumbo/constant/ActionTypes";
import { usefulLinkforTravelList, addUsefulLinkforTravel, updateUsefulLinkforTravel, removeUsefulLinkforTravel } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess, fetchStartSidebar } from './Common';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_DATA_SUCCESS,
} from "../../@jumbo/constant/ActionTypes";
import { refreshToken } from "./Auth";

// orderBy, order, searchValue, activePage, activePerPage
export const getUsefulLinkForTravel = () => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStartSidebar())
    dispatch(fetchStart());
    axios.get(usefulLinkforTravelList)
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // if (!data.data.data.userData.length) {
          //   dispatch({ type: IMPORTANT_CONTACT_LIST, payload: [] }); 
          // }else{
          //   dispatch({ type: IMPORTANT_CONTACT_LIST, payload: data.data.data });  
          // }
          dispatch({ type: USEFUL_LINK_FOR_TRAVEL_LIST, payload: data.data.data });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: USEFUL_LINK_FOR_TRAVEL_LIST, payload: [] });

        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getUsefulLinkForTravel())
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};

export const setUsefulLinkForTravel = user => {
  return dispatch => {
    dispatch({ type: SET_USEFUL_LINK_TRAVEL, payload: user });
  };
};
//   export const getCurrUserDetail = (id) => {

//     return dispatch => {
//       const token = JSON.parse(localStorage.getItem('userToken'));
//       axios.defaults.headers.common['Authorization'] = token
//       axios.get(getUserDetails,{
//         params: {
//           user_id:id,

//       },
//       })
//         .then(data => {
//           // console.log("row",data.data.data)
//           if (data.status === 200) {
//             // console.log(data.data.data)
//             dispatch({ type: GET_USER_DETAILS, payload: data.data.data });   
//           } else {
//             dispatch({ type: GET_USER_DETAILS, payload: [] });
//           }
//         })
//         // .catch(error => {
//         //   if(error.response.status===401 ){
//         //    console.log(error.response)
//         //     // dispatch(setAuthUser(null));
//         //     // history.push('/signin')
//         //   } 
//         //   else {
//         //     // dispatch(fetchError(error?.response?.data?.message));
//         //    console.log(error)
//         //   }
//         // });

//     };
//   };

export const addusefulLinktravel = (adduser, callbackFun, list, onClose) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .post(addUsefulLinkforTravel, adduser)
      .then(data => {

        if (data) {
          dispatch({ type: FETCH_SUCCESS, loading: false });

          if (callbackFun) callbackFun.setAddDialog();

          if (onClose) {
            setTimeout(() => {
              onClose();
            }, 300);
          }
          dispatch(list());
          // dispatch(fetchSuccess('Is in Progressing....')); 

          // if (data?.data?.data?.red_snakbar) {
          setTimeout(() => {
            dispatch(fetchSuccess('Link added sucessfully.'));
          }, 4000);
          // } else {
          //   setTimeout(() => {
          //     dispatch(fetchSuccess(data?.data?.message));
          //   }, 2000);
          // }
          // dispatch({ type: ADD_USER, payload: data.data.data });
        } else {
          dispatch({ type: ADD_USEFUL_LINK_FOR_TRAVEL, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addusefulLinktravel(adduser, callbackFun, list, onClose))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const update_useful_link_for_travel = (link_for_travel_id, user, callbackFun, list, onClose) => {

  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(updateUsefulLinkforTravel + link_for_travel_id, user)
      .then(data => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS, loading: false });

          // onClose();
          if (callbackFun) callbackFun.setAddDialog()
          if (onClose) {
            setTimeout(() => {
              onClose();
            }, 300);
          }
          dispatch(list())

          setTimeout(() => {
            dispatch(fetchSuccess('Link updated successfully .'));
          }, 4000);
          //   if (callbackFun) callbackFun();
          // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(update_useful_link_for_travel(link_for_travel_id, user, callbackFun, list, onClose))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const removeUsefulTravel = (selectedUser, callbackFun, list) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(removeUsefulLinkforTravel,
        {
          'linkForTravelId': selectedUser
        }
      )
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            if (callbackFun) callbackFun.setAddDialog();
          }, 1000);
          // if (callbackFun) callbackFun.setAddDialog();
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(list())
          setTimeout(() => {
            dispatch(fetchSuccess('Link deleted Sucessfully.'));
          }, 2000);
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(removeUsefulTravel(selectedUser, callbackFun, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {

          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};