export const gridColumns = [
    { id: 'id', label: 'ID' },
    { id: 'user_id', label: 'User' },
    { id: 'response_time', label: 'Response time' },
    { id: 'alert_type', label: 'Alert type' },
    { id: 'created_at', label: 'Alert created' },
    { id: 'user_id', sub_id: 'mobile', label: 'Phone number' },
    { id: 'current_location', sub_id: 'country', label: 'Country' },
    { id: 'current_location', sub_id: 'city', label: 'City' },
    { id: 'local_police_number', label: 'Local police number', width: '10%' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' },
]