import React, { Component, useEffect, useState } from 'react'
import Pagination from "../../Pagination/Pagintions";
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material'
import Loader from '../../Loader';
import { travelCoulmns, TRAVEL_STATUS_LIST } from '../data';
import { useDispatch, useSelector } from 'react-redux';
import COLORS from 'utils/colors';
import moment from 'moment'
import { Bullet, StatusContainer, NoData } from '../style';
import { useNavigate } from 'react-router-dom';
import { USER_TYPE } from 'utils/commonHelper';
import ReactTable from 'components/common/table/ReactTable2';
import { getTravelList } from 'redux/actions/travel';


export default function Travel({ loader, setLoader, activePerPage, filterList }) {
    const Columns = [
        {
            id: 'id',
            accessor: 'id',
            title: 'Travel ID',
            // width: '20%'
        },
        {
            id: 'lastLogEntry',
            accessor: 'lastLogEntry',
            title: 'Last Log entry',
            width: '10%',
            Component: ({ row }) => {
                return row.lastLogEntry.time ? moment(row.lastLogEntry.time).format('DD-MM-YYYY hh:mm') : '-'
            }
        },
        {
            id: 'comment',
            accessor: 'comment',
            title: 'Message'
        },
        {
            id: 'userFirstname',
            accessor: 'userFirstname',
            title: 'Name',
            Component: ({ row }) => {
                return (
                    <>
                        {row.test_mode ?
                            <div className="default-number" style={{ marginTop: "4px" }}>
                                Test Mode
                            </div>
                            : null}
                        <div>
                            {`${row.userFirstname || ""} ${row.userLastname || "-"} `}
                        </div>
                    </>
                );
            }
        },
        {
            id: 'travelCreatedDate',
            accessor: 'travelCreatedDate',
            title: 'Travel Created',
            width: '10%',
            Component: ({ row }) => {
                return moment(row?.travelCreatedDate).format('DD-MM-YYYY hh:mm') || '-'
            }
        },
        {
            id: 'status',
            accessor: 'status',
            title: 'Travel Status',
            Component: ({ row }) => {
                return <StatusContainer>
                    <Bullet style={{ backgroundColor: row?.status === 1 ? 'rgb(95, 170, 176)' : 'rgb(113, 117, 125)' }} /> {TRAVEL_STATUS_LIST[row?.status]}
                </StatusContainer>
            }
        },
        {
            id: 'lastCheckinCountry',
            accessor: 'lastCheckinCountry',
            title: 'Last Country',
            Component: ({ row }) => {
                return row?.lastCheckinCountry || '-'
            }
        },
        {
            id: 'lastCheckinCity',
            accessor: 'lastCheckinCity',
            title: 'Last City',
            Component: ({ row }) => {
                return row?.lastCheckinCity || '-'
            }
        },
        {
            id: 'action',
            title: 'Actions',
            width: '6%',
            Component: ({ row }) => {
                return <i className="icon-eye cursor-pointer" aria-label="toggle password visibility" onClick={() => {
                    navigate(`travel-detail/${row.travelID}`)
                    localStorage.setItem('crisisTabIndex', "1")
                }} />
            }
        },
    ]
    const navigate = useNavigate()
    const list = useSelector(state => state.TRAVEL.travelList)
    const Profile = JSON.parse(localStorage.getItem("profile"));
    const [activePage, setActivePage] = useState(1);
    const [callCount, setCallCount] = useState(0);
    const dispatch = useDispatch();
    const getTravelListData = (activePage = 1, activePerPage = 10) => {
        const params = {
            page: activePage,
            limit: activePerPage,
            search: "",
            subdivisions: filterList
        }
        dispatch(getTravelList(params, false));
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }
    useEffect(() => {
        getTravelListData(activePage, activePerPage);
    }, [activePage]);
    useEffect(() => {
        if (callCount > 0) {
            setActivePage(prev => typeof prev === 'number' ? '1' : 1);
        } else {
            setCallCount(prev => prev + 1);
        }
    }, [activePerPage, filterList]);
    useEffect(() => {
        localStorage.removeItem('crisisTabIndex')
    }, [])
    useEffect(() => {
        setTimeout(() => setLoader(false), 2000)
    }, [list]);
    return (
        <div>
            <ReactTable
                COLUMNS={Columns}
                DATA={list?.UserTravelList}
                fetchData={handlePageChange}
                totalCount={list?.total_no_of_records}
                pageSize={activePerPage}
                pageIndex={activePage}
                hiddenColumns={[USER_TYPE.TENANT_USER].includes(Profile.user_type) ? ['action'] : []}
            />
        </div>
    )
}