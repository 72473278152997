import axios from '../../helper/customAxios';
import { FETCH_SUCCESS, GET_ALERT_LIST, GET_ALERT_DETAIL, RESOLVE_ALERT, RESET_RESOLVE_ALERT, ADD_ALERT_COMMENT, UPDATE_GPS, RESET_UPDATE_GPS, RESET_ALERT_LIST, RESET_IN_HOUSE_LIST, GET_IN_HOUSE_LIST, GET_IN_HOUSE_LOC_DETAIL, ADD_IN_HOUSE_LOC, EDIT_IN_HOUSE_LOC, DEL_IN_HOUSE_LOC, RESET_DEL_IN_HOUSE_LOC, IS_IN_HOUSE_EDIT, GET_IN_HOUSE_LOC_LIST, RESET_ADD_IN_HOUSE_LOC, RESET_EDIT_IN_HOUSE_LOC } from '../../@jumbo/constant/ActionTypes';
import { alertList, alertDetail, alertResolve, alertComment, updateLocation, inHouseList, addInHouseLocUrl, editInHouseLocUrl, deleteInHouseLocUrl, inHouselocDetail } from '../../@jumbo/constant/ApiConstant'
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { ALERT_TYPE } from '../../utils/alertHelper';
import { refreshToken } from './Auth';

const defaultErrorMsg = 'Somthing went wrong'

export const getAlertList = (params) => {
    return dispatch => {
        // const token = JSON.parse(localStorage.getItem("userToken"));
        //remove Authorization
        dispatch(fetchStart())
        axios.get(alertList, { params: params }).then((res) => {
            const type = params.alert_type == ALERT_TYPE.IN_HOUSE_ALERT.toString() ? GET_IN_HOUSE_LIST : GET_ALERT_LIST
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type, payload: res.data.data, status: res.status, alertStatus: params.status });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type, payload: null });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getAlertList(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const resetAlertList = () => {
    return dispatch => {
        dispatch({ type: RESET_ALERT_LIST })
    }
}

export const resetInhouseList = () => {
    return dispatch => {
        dispatch({ type: RESET_IN_HOUSE_LIST })
    }
}

export const getAlertDetail = (params, setLoader, gpsUpdate) => {
    return dispatch => {
        //remove Authorization
        if (gpsUpdate) {
            dispatch(fetchStart())
        } else {
            setLoader(true)
        }
        axios.get(`${alertDetail}/${params.alert_id}`).then((res) => {
            dispatch({ type: FETCH_SUCCESS, loading: false })
            setLoader(false)
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type: GET_ALERT_DETAIL, payload: res.data.data, status: res.status });
            } else {
                dispatch({ type: GET_ALERT_DETAIL, payload: null });
            }
        }).catch((err) => {
            setLoader(false)
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getAlertDetail(params, setLoader, gpsUpdate))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
            }
        })
    }
}

export const resolveAlert = (params) => {
    return dispatch => {
        //remove Authorization
        const alert_id = params.alert_id
        // delete params.alert_id
        dispatch(fetchStart())
        axios.post(`${alertResolve}/${alert_id}`, { params: { delayed_alert_time: params.delayed_alert_time } }).then((res) => {
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (res.status === 200) {
                dispatch({ type: RESOLVE_ALERT, payload: res.status });
                //dispatch(fetchSuccess(res.data.message))
            } else {
                dispatch({ type: RESOLVE_ALERT, payload: null });
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(resolveAlert(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
            }
        })
    }
}

export const resetResolveAlert = () => {
    return dispatch => {
        dispatch({ type: RESET_RESOLVE_ALERT })
    }
}

export const addAlertComment = (params, callback) => {
    return dispatch => {
        //remove Authorization
        const alert_id = params.alert_id
        delete params.alert_id
        dispatch(fetchStart())
        axios.post(`${alertComment}/${alert_id}`, params).then((res) => {
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type: ADD_ALERT_COMMENT, payload: res.data.data });
                // dispatch(fetchSuccess(res.data.message))
                if (callback) callback();
            } else {
                dispatch({ type: ADD_ALERT_COMMENT, payload: null });
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(addAlertComment(params, callback))
                }))
                return;
            }
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
            }
        })
    }
}

export const updateGPSLocation = (alert_id) => {
    return dispatch => {
        //remove Authorization
        // const alert_id = params.alert_id
        // delete params.alert_id
        dispatch(fetchStart())
        // axios.put(`${updateLocation}/${alert_id}`).then((res) => {
        axios.get(updateLocation + alert_id).then((res) => {
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (res.status === 200) {
                dispatch({ type: UPDATE_GPS, payload: res.status });
                dispatch(fetchSuccess(res.data.message))
            } else {
                dispatch({ type: UPDATE_GPS, payload: null });
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SUCCESS, loading: false })
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(updateGPSLocation(alert_id))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
            }
        })
    }
}

export const resetUpdateGPS = () => {
    return dispatch => {
        dispatch({ type: RESET_UPDATE_GPS })
    }
}

export const getInhouseLocList = (params) => {
    return dispatch => {
        // const token = JSON.parse(localStorage.getItem("userToken"));
        // headers = { 'Authorization': 'Bearer ' + token };
        //remove Authorization
        dispatch(fetchStart())
        axios.get(inHouseList, { params: params }).then((res) => {
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type: GET_IN_HOUSE_LOC_LIST, payload: res.data.data, status: res.status });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: GET_IN_HOUSE_LOC_LIST, payload: null });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getInhouseLocList(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const addInhouseLoc = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart())
        axios.post(addInHouseLocUrl, params).then((res) => {
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type: ADD_IN_HOUSE_LOC, payload: res.data.data, status: res.status });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: ADD_IN_HOUSE_LOC, payload: null });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(addInhouseLoc(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const resetAddInHouseLoc = () => {
    return dispatch => {
        dispatch({ type: RESET_ADD_IN_HOUSE_LOC })
    }
}

export const editInhouseLoc = (params, callback) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart())
        const loc_id = params.loc_id
        delete params.loc_id
        axios.put(`${editInHouseLocUrl}/${loc_id}`, params).then((res) => {
            if (res.status === 200) {
                dispatch({ type: EDIT_IN_HOUSE_LOC, payload: params, status: res.status });
                dispatch({ type: FETCH_SUCCESS, loading: false })
                if (callback) callback()

            } else {
                dispatch({ type: EDIT_IN_HOUSE_LOC, payload: null });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(editInhouseLoc(params, callback))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}

export const resetEditInHouseLoc = () => {
    return dispatch => {
        dispatch({ type: RESET_EDIT_IN_HOUSE_LOC })
    }
}

export const deleteInhouseLoc = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart())
        axios.delete(`${deleteInHouseLocUrl}/${params.loc_id}`).then((res) => {
            if (res.status === 200) {
                dispatch({ type: DEL_IN_HOUSE_LOC, payload: res.data.message, status: res.status, loc_id: params.loc_id });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: DEL_IN_HOUSE_LOC, payload: null });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(deleteInhouseLoc(params))
                }))
                return;
            }
            if (err?.response?.data?.message) {
                // if ((err && err.response && err.response.status === 401) || (err && err.response && err.response.status === 400)) {
                // dispatch(fetchError(err.response.data && err.response.data.message))
                dispatch({ type: FETCH_SUCCESS, loading: false })
                dispatch(fetchError(err.response.data.message));
            } else {
                // dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
                dispatch({ type: FETCH_SUCCESS, loading: false })
                dispatch(fetchError(err.response.data.message));
            }
        })
    }
}

export const resetDelInHouseLoc = () => {
    return dispatch => {
        dispatch({ type: RESET_DEL_IN_HOUSE_LOC })
    }
}

export const isInHouseEdit = (params) => {
    return dispatch => {
        dispatch({ type: IS_IN_HOUSE_EDIT, payload: params.value, editData: params.editData })
    }
}

export const getInHouseDetail = (params) => {
    return dispatch => {
        //remove Authorization
        dispatch(fetchStart())
        axios.get(`${inHouselocDetail}/${params.id}`).then((res) => {
            if (res.status === 200 && res.data && res.data.data) {
                dispatch({ type: GET_IN_HOUSE_LOC_DETAIL, payload: res.data.data, status: res.status });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch({ type: GET_IN_HOUSE_LOC_DETAIL, payload: null });
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getInHouseDetail(params))
                }))
                return;
            }
            if (err && err.response && err.response.status === 401) {
                dispatch(fetchError(err.response.data && err.response.data.message))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            } else {
                dispatch(fetchError(err.response && err.response.data && err.response.data.message ? err.response.data.message : defaultErrorMsg))
                dispatch({ type: FETCH_SUCCESS, loading: false })
            }
        })
    }
}