import { useEffect, useState } from 'react';
import { statusArray } from './CustomizeSetting';
// import imageCompression from 'browser-image-compression';
export const capitalizeFLetter = string => {
  return string[0].toUpperCase() + string.slice(1);
};

export const statusChange = item => {
  if (item === statusArray.vendor_not_assigned) {
    return 'Vendor Not Assigned';
  } else if (item === statusArray.vendor_assigned) {
    return 'Vendor Assigned';
  } else if (item === statusArray.service_started) {
    return 'Service Started';
  } else if (item === statusArray.service_request_completed) {
    return 'Service Request Completed';
  } else if (item === statusArray.approve_work) {
    return 'Approve Work';
  } else if (item === statusArray.payment_sent) {
    return 'Payment Sent';
  }
};

export const logsData = data => {
  let arr = data.split(',');
  arr = arr.map(item => {
    item = item.charAt(0).toUpperCase() + item.slice(1);
    if (item === 'Name') {
      item = 'Parts';
    } else if (item === 'Charge') {
      item = 'Price';
    } else if (item === 'service_type') {
      item = 'Service Type';
    } else {
      item = item;
    }
    return item;
  });

  let final = arr.toString();
  final = final.replaceAll(',', "', '");

  return final;
};

//  image compressor

export const isValidEmail = value => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000);
};

export const linkify = inputText => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const getValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return 'http://' + url;
    }
    return 'https://' + url;
  }

  return url;
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay],
  );

  return debouncedValue;
};

export const USER_TYPE = {
  'ADMIN': 1,
  'SEC_CONTROLLER': 2,
  'TENANT_USER': 3,
  'SUBDIVISION_USER': 4,
  'EXTERNAL_CONTROLLER': 5,
  'CONTROL_USER': 6,
  'END_USER': 7
}