import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Container, Input, listItemIconClasses } from "@mui/material";
import { Form, useNavigate } from "react-router-dom";
import { maxHeight } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
// import { getAllUsersList,getCurrUserDetail } from "redux/actions/SubDivision";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
// import ConfirmDialog from "./ConfirmDialog";
// import TableHeader from "./TableHeader";
import TableSortLabel from "@mui/material/TableSortLabel";
import Search from "@mui/icons-material/Search";
// import { useNavigate } from "react-router-dom";
// import AppTextInput from 'applayout/formElements/AppTextInput';
// import AppTextInput from './applayout/formElements/AppTextInput'
import CloseIcon from "@mui/icons-material/Close";
import SvgIcon from '@mui/material/SvgIcon';


const HomeIcon = (props) => {
  return (
    <div>
    <SvgIcon >
  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
</SvgIcon>
</div>
  )
}


const HomeIcon1 = (props) => {
  return (
    <div>
    <SvgIcon >
    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
</SvgIcon>
</div>
  )
}

const columns1 = [
  {
    id: "id",
    label: "Tenant ID",
    tag: true,
    sortable: true,
    key: "id",
    align: "left",
  },

  { id: "first_name", label: "Full Name", align: "left" },
  {
    id: "mobile",
    label: "Phone",
    align: "left",
    sortable: false,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Email",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "firm_name",
    label: "Tenant name",
    align: "left",
    sortable: true,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "numberOfLicenses",
    label: "No. of licenses",
    align: "left",
    sortable: true,
  },
  {
    id: "status",
    label: "Status",
    align: "left",
    sortable: true,
  },
];

export default function UserTableHead({ orderBy, order, handleRequestSort }) {
  const onSortOrderChange = (property) => (event) => {
    // alert(event, property)
    event.preventDefault();
    if(property == 'first_name' || property == 'mobile'|| property == 'email'){
      
      return null
    }
    
    else{
      handleRequestSort(property);
    }
    
  };
  return (
    <TableHead>
      <TableRow>
        {columns1.map((column) => column.sortable?(
          <TableCell
          style={{cursor: 'pointer'}}
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
            align={column.align}
            onClick={onSortOrderChange(column.id)}
          >
            <TableSortLabel
            IconComponent={column.id == 'mobile'|| column.id == 'email'||column.id == 'first_name' ?null:order == "desc"?HomeIcon:HomeIcon1}
              sx={{"& .MuiButtonBase-root .MuiTableSortLabel-root:focus ":{
                color:'red'
              }}}
              className="custom-table-header"
            >
              {column.label}
            </TableSortLabel>
            </TableCell>
              ) : (<TableCell>{column.label}</TableCell>))}
        <TableCell style={{width:'149px'}}>
          Actions
        </TableCell>
      </TableRow>


    </TableHead>
  );
}


// <TableRow>
//               {columns1.map((column) => column.sortable ? (
//                   <TableCell
//                       style={{cursor: 'pointer'}}
//             key={column.id}
//             sortDirection={orderBy === column.id ? order : false}
//                       align={column.align}
//                       onClick={onSortOrderChange(column.id)}
//             // style={{
//             //   color: "rgb(144, 153, 170)",
//             //   height: "10px",
//             //   minWidth: "125px",
//             // }}
//           >
            

//                       <div style={{ display: 'flex' }}>
//                           <span>{column.label}</span>
//                           {HomeIcon1()}
//                       </div>
//                   </TableCell>
//               ) : (<TableCell>{column.label}</TableCell>))}
//         <TableCell style={{ minWidth: "170", color: "rgb(144, 153, 170)" }}>
//           Action
//         </TableCell>
//       </TableRow>