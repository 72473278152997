import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Form, useNavigate } from "react-router-dom";
// import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Grid from '@mui/material/Grid';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ConfirmDltDialog from './../ConfirmDltDialog'
import TableSortLabel from "@mui/material/TableSortLabel";
import { useDebounce } from "utils/commonHelper";
import Pagination from "../Pagination/Pagintions";
import AmountItems from "../AmountItems/AmountItems";
import SearchInput from "../SearchInput";
import UserList from "./UserList";
import { getExternalUser, getCurrUserDetail, getsubUsers, deleteExtController } from "redux/actions/ExtController";
import { getAllUsersList } from "redux/actions/SubDivision";
import AddConfirmDialog from "../AddConfirmDialog";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import {
  Button,
  Container,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./style";
import { Buttons } from "../Button";
import SvgIcon from '@mui/material/SvgIcon';
import ReactTable from "components/common/table/ReactTable2";
import { GET_USER_DETAILS } from "@jumbo/constant/ActionTypes";


const HomeIcon = (props) => {
  return (
    <div>
      <SvgIcon >
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </SvgIcon>
    </div>
  )
}


const HomeIcon1 = (props) => {
  return (
    <div>
      <SvgIcon >
        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
      </SvgIcon>
    </div>
  )
}


const perPages = [10, 20, 50, 100];
export default function ExtController() {
  const { allExternalUsers, subdivison } = useSelector(({ EXTCONTROLLER }) => EXTCONTROLLER);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedUser, setSelectedUser] = useState({ first_name: "" });
  const [order, setOrder] = React.useState("desc");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [orderBy, setOrderBy] = React.useState("first_name");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(10);
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const columns = [
    {
      id: "id",
      accessor: "id",
      title: "Id",
      sortable: true,
      Component: ({ row }) => row?.id || "-"
    },
    {
      id: "first_name",
      accessor: "first_name",
      title: "Full Name",
      Component: ({ row }) => { return `${row?.first_name || ''}   ${row?.last_name || ''}` }
    },
    {
      id: "mobile",
      accessor: "mobile",
      title: "Phone",
      Component: ({ row }) => row?.mobile || "-"
    },
    {
      id: "email",
      accessor: "email",
      title: "Email",
      Component: ({ row }) => row?.email || "-"

    },
    {
      id: "status",
      accessor: "status",
      title: "Status",
      sortable: true,
      Component: ({ row }) => {
        return (
          <>
            <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: row.status === 1 ? 'rgb(95, 170, 176)' : 'rgb(225, 108, 108)', marginRight: '5px' }} />
            {row.status === 1 ? `Active` : "Disabled"}
          </>
        )
      }
    },
    {
      id: 'id',
      accessor: 'id',
      title: 'Actions',
      Component: ({ row }) => {
        return (
          <>
            <span className="text-[#4892c3] cursor-pointer" onClick={() => handleEdit(row._id)} >
              Edit
            </span>
            <button className="text-[#e17070] ml-2 cursor-pointer" onClick={() => handleUserDelete(row)}>
              Delete
            </button>
          </>
        );
      }
    }
  ];
  const [sortingOption, setSortingOption] = useState({ columnName: 'id', direction: 'desc' });
  useEffect(() => {
    dispatch(
      getExternalUser(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage)
    );
    dispatch(getsubUsers())
  }, [dispatch, activePage, activePerPage, sortingOption]);

  const addSubdivisionFunction = () => {
    navigate(`/app/ExternalUsers/AddExtController/`);
  };
  const handleEdit = (id) => {
    dispatch({ type: GET_USER_DETAILS, payload: [] });
    dispatch(getCurrUserDetail(id));
    navigate(`/app/ExternalUsers/EditExtController/${id}`);
  };
  const handleUserDelete = (user) => {
    setSelectedUser(user);
    setopenConfirmDltDialog(true);
  };
  const handleCancelDelete = () => {
    setopenConfirmDltDialog(false);
  };
  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(deleteExtController(selectedUser._id, { setAddDialog: () => setOpenConfirmDialog(true) },
      // () => dispatch(getExternalUser(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage))
    ))
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };
  const onSortOrderChange = (property) => (event) => {
    event.preventDefault();
    handleRequestSort(event, property);
  };

  const changePerPage = (activePerPage) => {
    setActivePage(1);
    setActivePerPage(activePerPage);
    dispatch(
      getExternalUser(sortingOption?.columnName, sortingOption?.direction, searchValue, 1, activePerPage)
    );
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
  };
  const submitSearch = (e) => {
    e.preventDefault();
    setSearchValue(searchValue);
    dispatch(
      getExternalUser(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage)
    );
  };
  const clearSearch = (e) => {
    e.preventDefault();
    setSearchValue("");
    dispatch(getExternalUser(sortingOption?.columnName, sortingOption?.direction, "", activePage, activePerPage));
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setActivePerPage(activePerPage);
    dispatch(
      getExternalUser(
        orderBy,
        order,
        searchValue,
        activePage,
        activePerPage
      )
    );
  };

  const handleCancelConfirm = () => {
    setOpenConfirmDialog(false)
    dispatch(getExternalUser(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage))
  }
  const handleConfirm = () => {
    setOpenConfirmDialog(false)
  }
  return (
    <>
      <div className="mainDiv1">
        <div className="heading-section">
          <div className="pageTitle">External controllers table <span className="totalCount">({allExternalUsers && allExternalUsers.total ? allExternalUsers.total : 0})</span></div>

          <Buttons
            onClick={addSubdivisionFunction}
            addButtonnProps={true}
          >
            <AddIcon />
            Add Ext controller
          </Buttons>

        </div>


        <div className='serch-section'>
          <SearchInput
            onChange={onSearch}
            searchValue={searchValue}
            onSubmit={submitSearch}
            clearSearch={clearSearch}
          />
          <AmountItems
            changePerPage={changePerPage}
            activePerPage={activePerPage}
          />
        </div>
        <ReactTable
          COLUMNS={columns}
          DATA={allExternalUsers?.userData}
          fetchData={getExternalUser}
          totalCount={allExternalUsers.total}
          pageSize={activePerPage}
          pageIndex={activePage}
          sortingOption={sortingOption}
          setSortingOption={setSortingOption}
          setSearchValue={setSearchValue}
        />
        <ConfirmDltDialog
          open={openConfirmDltDialog}
          // title={`Delete Subdivision`}
          content={`Are you sure you want to delete "${selectedUser.first_name} ${selectedUser.last_name}"?`}
          content1={`All information about this user will be permanently deleted`}
          btnLabels={{
            no: "No",
            Yes: "Yes",
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <AddConfirmDialog
          open={openConfirmDialog}
          // title={`Delete Subdivision`}
          content={`Ext controller was deleted successfully! `}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
      </div>
    </>
  );
}
