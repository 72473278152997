import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Form, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddEditLink from "./AddEditLink";
import UserTableHead from "./UserTableHead";
import { useDebounce } from "utils/commonHelper";
import UserListRow from "./UserListRow";
import { getUsefulLinkForTravel, setUsefulLinkForTravel, removeUsefulTravel } from "redux/actions/usefulLinkforTravel";
import ConfirmDltDialog from "../../ConfirmDltDialog";
import AddConfirmDialog from "../../AddConfirmDialog";
import { getImportantContactList } from "redux/actions/importantContact";
import ReactTable from "components/common/table/ReactTable2";
export default function UsefulLinkForTravel({ open,
  onClose,
  onConfirm,
  setOpenPassConfirmDialog,
  butttonValue,
  setButttonValue }) {
  const { travelList, currentDetails } = useSelector(({ USEFULLINKTRAVEL }) => USEFULLINKTRAVEL);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedUser, setSelectedUser] = useState('');
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [openAddlinkConfirmDialog, setOpenAddlinkConfirmDialog] = useState(false);
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)
  const [orderBy, setOrderBy] = React.useState("first_name");
  const [currentData, setCurrentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  let successLinkMessage = selectedUser ? `Link was deleted successfully!` : currentDetails ? `Link edited successfully!` : `Link added successfully!`


  useEffect(() => {
    dispatch(
      getUsefulLinkForTravel()
    );
    dispatch(getImportantContactList())
  }, []);

  const handleEdit = user => {
    dispatch(setUsefulLinkForTravel(user))
    setOpenPassConfirmDialog(true);
  };

  const handleDelete = (id) => {
    setSelectedUser(id)
    setopenConfirmDltDialog(true);
  };

  const handleCancelDelete = () => {
    setopenConfirmDltDialog(false);
    setTimeout(() => setSelectedUser(""), 1000)
  };

  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(removeUsefulTravel(selectedUser, { setAddDialog: () => setOpenAddlinkConfirmDialog(true) }))
    // getUsefulLinkForTravel
  };

  const handleCancelConfirm = () => {
    setOpenAddlinkConfirmDialog(false)
    dispatch(getUsefulLinkForTravel())
  }
  const handleConfirm = () => {
    setOpenAddlinkConfirmDialog(false)
    setTimeout(() => setSelectedUser(""), 1000)
  }
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const columns = [
    {
      id: "name",
      accessor: "name",
      title: "Title",
    },
    {
      id: "link",
      accessor: "link",
      title: "Link",
      format: (value) => value.toLocaleString("en-US"),
      Component: ({ row }) => {
        return <a target="_blank" href={row?.link}>{row?.link}</a>
      }
    },
    {
      id: 'actions',
      accessor: 'actions',
      title: 'Actions',
      width: '20%',
      Component: ({ row }) => {
        return (
          <>
            <span className="text-[#4892c3] cursor-pointer" onClick={() => handleEdit(row)} >
              Edit
            </span>
            <button className="text-[#e17070] ml-2 cursor-pointer" onClick={() => handleDelete(row?._id)}>
              Delete
            </button>
          </>
        );
      }
    },
  ];
  return (
    <>
      <div className="mainDiv1">
        <ReactTable
          COLUMNS={columns}
          DATA={travelList?.links}
          fetchData={() => { }}
          pagination={false}
        // hiddenColumns={[USER_TYPE.CONTROL_USER, USER_TYPE.END_USER, USER_TYPE.EXTERNAL_CONTROLLER].includes(Profile?.user_type) ? ['actions'] : []}
        />
        {open ?
          <AddEditLink
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            setOpenPassConfirmDialog={setOpenAddlinkConfirmDialog}
            butttonValue={butttonValue}
            setButttonValue={setButttonValue}
          /> : null}
        <ConfirmDltDialog
          open={openConfirmDltDialog}
          content={`Are you sure you want to delete this link?`}
          btnLabels={{
            no: "No",
            Yes: "Yes",
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <AddConfirmDialog
          open={openAddlinkConfirmDialog}
          content={successLinkMessage}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
      </div>
    </>
  );
}
