import React, { useState } from "react";
// import Toolbar from '@material-ui/core/Toolbar';
import { Container, Input, listItemIconClasses } from "@mui/material";
// import useStyles from './index.style';
import { styled } from "@mui/material/styles";
import AmountItems from "../../AmountItems/AmountItems";

import SearchInput from "../../SearchInput";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Container0,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./index.style";
import Grid from "@mui/material/Grid";
import { Buttons } from "../../Button";
import { useSelector } from "react-redux";

const UserTableToolbar = ({
  onSearch,
  searchValue,
  submitSearch,
  clearSearch,
  changePerPage,
  activePerPage,
  addTenant,
  userProfile,
}) => {
  // let classes = useStyles();
  const { users } = useSelector(({ TENANT }) => TENANT);

  return (
    <>
      <div className="heading-section">
        <div className="pageTitle">Tenant Table<span className="totalCount">({users && users.total ? users.total : '0'})</span></div>


        {/* <div style={{display: 'flex'}}>
                <span className="pageTitle">Tenant table</span>
                <span className="totalCount">({users.total})</span>
              </div> */}
        {userProfile && userProfile?.user_type !== 2 ?
          <Buttons onClick={addTenant} >
            <AddIcon />
            Add Tenant
          </Buttons> : null}
      </div>

      <div className="serch-section">
        <SearchInput
          onChange={onSearch}
          searchValue={searchValue}
          onSubmit={submitSearch}
          clearSearch={clearSearch}
        />

        <AmountItems
          changePerPage={changePerPage}
          activePerPage={activePerPage}
        />
      </div>
    </>
  );
};

export default UserTableToolbar;
