import React, { Component, useEffect, useState } from "react";
import { gridColumns } from "./data";
import {
  COLOR_LIST,
  REVERSED_ALERT_STATUS,
  REVERSED_ALERT_TYPE,
  timeHelper,
} from "utils/alertHelper";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { VisibilityOutlined as VisibilityOutlinedIcon } from "@mui/icons-material";
import COLORS from "utils/colors";
import Pagination from "../Pagination/Pagintions";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { StatusContainer, Bullet } from "./style";
import * as moment from "moment";
import { EmptyBox } from "components/EmptyBox";
import { USER_TYPE } from "utils/commonHelper";
import { getAlertList } from "redux/actions/alert";
import { ALERT_STATUS } from "utils/alertHelper";
import { useDispatch } from "react-redux";
import { io } from 'socket.io-client'
import ReactTable from "components/common/table/ReactTable2";
import { Link } from "react-router-dom";

export default function AlertGrid({
  handlePageChange,
  activePage,
  activePerPage,
  tabIndex,
}) {
  const currentUser = JSON.parse(localStorage.getItem("profile"));
  const { alertList } = useSelector(({ ALERT }) => ALERT);
  const [data, setData] = useState([]);
  console.log("alertList", alertList)
  // const userCountry = alertList?.userAlerts?.map(data => data?.current_location?.country)
  let policeNoCountryWise;
  let mutualPolicePhoneNumber;
  // console.log("data",data?.current_location?.country)
  // })
  useEffect(() => {
    if (alertList?.userAlerts) {
      setData(() => {
        return alertList?.userAlerts?.filter((value) => (tabIndex === 0 ? (value.status === ALERT_STATUS.RAISED) : (value.status === ALERT_STATUS.IN_PROGRESS)));
      });
    }
  }, [alertList]);
  const { policenumber } = useSelector(({ POLICENUMBER }) => POLICENUMBER);

  useEffect(() => {
    setTimeout(() => localStorage.removeItem("alertTab"), 800);
  }, [])

  const findUserCountry = (row) => {
    let userCountry = row?.current_location?.country;
    let userMatchPoliceNumber = row?.user_id?.local_police_number?.filter((data) => data?.country === userCountry)
    return userMatchPoliceNumber?.map((data) => data?.police_phone)
  }


  const columns = [
    {
      id: 'id',
      accessor: 'id',
      title: 'ID'
    },
    {
      id: 'user_id',
      accessor: 'user_id',
      title: 'User',
      Component: ({ row }) => {
        return (
          <>
            {row.test_mode && <div className="default-number" style={{ marginTop: "4px" }}>
              Test Mode
            </div>}
            <div>{`${row.user_id.first_name || ""} ${row.user_id.last_name ||
              "-"} `}</div>
          </>
        );
      }
    },
    {
      id: 'response_time',
      accessor: 'response_time',
      title: 'Response time',
      Component: ({ row }) => {
        return row?.response_time ? timeHelper(row?.response_time) : '-'
      }
    },
    {
      id: 'alert_type',
      accessor: 'alert_type',
      title: 'Alert type',
      Component: ({ row }) => {
        const val = REVERSED_ALERT_TYPE[row['alert_type']];
        return (
          <span style={{ color: COLOR_LIST[val.toLowerCase()] }}>{val || '-'}</span>
        );
      }
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      title: 'Alert created',
      Component: ({ row }) => {
        return moment(row['created_at']).format("DD-MM-YYYY hh:mm") || '-'
      }
    },
    {
      id: 'user_id',
      accessor: 'user_id',
      title: 'Phone number',
      Component: ({ row }) => {
        return row?.user_id?.mobile || '-'
      }
    },
    {
      id: 'current_location',
      accessor: 'current_location',
      title: 'Country',
      Component: ({ row }) => {
        return row?.current_location?.country || '-';
      }
    },
    {
      id: 'current_location',
      accessor: 'current_location',
      title: 'City',
      Component: ({ row }) => {
        return row?.current_location?.city || '-';
      }
    },
    {
      id: 'local_police_number',
      accessor: 'local_police_number',
      title: 'Local police number',
      width: '10%',
      Component: ({ row }) => {
        return row?.policeNumber || '-'
        // (
        // findUserCountry(row)
        // );
      }
    },
    {
      id: 'status',
      accessor: 'status',
      title: 'Status',
      Component: ({ row }) => {
        return <StatusContainer>
          <Bullet style={{ backgroundColor: COLOR_LIST[REVERSED_ALERT_STATUS[row['status']].toLowerCase()] }} />{" "}
          {REVERSED_ALERT_STATUS[row['status']]}
        </StatusContainer>
      }
    },
    {
      id: 'actions',
      accessor: 'actions',
      title: 'Actions',
      Component: ({ row }) => {
        return (
          <Link to={`/app/alert/alert-detail/${row._id}`}>
            <i className="icon-eye actioneye" />
          </Link>
        );
      }
    },
  ]


  return (
    <div>
      <ReactTable
        COLUMNS={columns}
        DATA={data}
        fetchData={handlePageChange}
        totalCount={alertList?.total_no_of_records}
        pageSize={activePerPage}
        pageIndex={activePage}
        hiddenColumns={[USER_TYPE.TENANT_USER].includes(currentUser.user_type) ? ['actions'] : []}
      />
      {/* <Table className="custome-tbl">
        <TableHead>
          <TableRow>
            {gridColumns.map((data, index) => {
              if (data.id === "actions" && tanent_user) return;
              return (
                <TableCell
                  key={`${data.id}-${data.sub_id}-${index}`}
                  style={{
                    color: COLORS.greylighttext,
                    width: data.width && data.width,
                  }}
                >
                  {data.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.length > 0 ? (
            data.map((row, index) => {
              return (
                <TableRow key={`row-${index}`}>
                  {gridColumns.map((col, index) => {
                    if (col.id === "actions" && tanent_user) return;
                    return col.id === "actions" ? (
                      <TableCell key={`colData-${index}`}>
                        <i
                          className="icon-eye actioneye"
                          aria-label="toggle password visibility"
                          // style={{
                          //   color: "rgb(133, 142, 160)",
                          //   fontSize: "16px",
                          //   cursor: "pointer",
                          // }}
                          onClick={() =>
                            navigate(`/app/alert/alert-detail/${row._id}`)
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={`colData-${index}`}>
                        {renderValue(col, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={11}>
                <EmptyBox>{"No Record found"}</EmptyBox>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        activePage={activePage}
        totalItems={
          alertList && alertList.totalNewAlerts ? alertList.totalNewAlerts :
            alertList && alertList.total_no_of_records
              ? alertList.total_no_of_records
              : 0
        }
        itemsCountPerPage={activePerPage}
        handlePageChange={handlePageChange}
      /> */}
    </div>
  );
}
