import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import { Box, Typography } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { color } from "@mui/system";
import * as moment from 'moment'
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material'
// import { COLOR_LIST, REVERSED_ALERT_STATUS, REVERSED_ALERT_TYPE, timeHelper } from 'utils/alertHelper'
import { REVERSED_ALERT_STATUS, REVERSED_ALERT_TYPE } from "utils/alertHelper";
import { useNavigate } from 'react-router';
const useStyles = styled((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    // fontWeight: "300",
    color: theme.palette.common.dark,
    textOverflow: "ellipsis",
    maxWidth: "250px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));
const USER_TYPE = {
  'ADMIN': 1,
  'SEC_CONTROLLER': 2,
  'TENANT_USER': 3,
  'SUBDIVISION_USER': 4,
  'EXTERNAL_CONTROLLER': 5,
  'CONTROL_USER': 6,
  'END_USER': 7
}
export default function UserListRow({ row, handleUserDelete, handleEdit }) {
  let classes = useStyles();
  const navigate = useNavigate();
  const labelId = `enhanced-table-checkbox-${row && row._id}`;
  let user = !!row ? row.control_user : null;

  const Profile = JSON.parse(localStorage.getItem("profile"));
  const subdiv_user = Profile.user_type === USER_TYPE.SUBDIVISION_USER
  const control_user = Profile.user_type === USER_TYPE.EXTERNAL_CONTROLLER
  useEffect(() => {
    localStorage.removeItem('inhouseTab')
  }, [])

  const handelViewPage = () => {
    navigate(`/app/CrisisLog/crisis-log-inHouse-detail/${row.alertId}`)
    localStorage.setItem("inhouseTab", 2)
  }
  return (
    <TableRow role="checkbox" tabIndex={-1} key={row && row._id}>
      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >
        {row && !row.id ? "-" : row.id}

      </TableCell>



      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >
        {!row.lastLogEntry ? "-" : row.lastLogEntry.time && moment(row.lastLogEntry.time).format('DD-MM-YYYY hh:mm')} {" "}
        {row.lastLogEntry.user}
      </TableCell>



      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >

        {!row.lastLogMessage ? "-" : row.lastLogMessage}
        {/* {row.control_user?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(95, 170, 176)',marginRight:'5px'}}/> : ''}
        {!row.control_user?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(225, 108, 108)',marginRight:'5px'}}/> : ''}
        {!!row ? (row.control_user ? "yes" : "no") : null} */}
      </TableCell>

      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >
        {!!row && row.test_mode ? <div className="default-number" style={{ marginTop: '4px' }}>Test Mode</div> : null}
        {!row.userName ? '-' : row.userName}
      </TableCell>

      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >

        {!row.createdOn ? "-" : moment(row.createdOn).format('DD-MM-YYYY hh:mm')}
        {/* {row.status === 1 ?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(95, 170, 176)',marginRight:'5px'}}/> : ''}
        {row.status === 2 ?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(225, 108, 108)',marginRight:'5px'}}/> : ''}
        {row.status === 3 ?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'Orange',marginRight:'5px'}}/> : ''}
        {row.status === 4 ?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(119 42 42)',marginRight:'5px'}}/> : ''}
        {row.status === 1 ? `Active` : ""}
        {row.status === 2 ? "Disabled" : ""}
        {row.status === 3 ? "Pending" : ""}
        {row.status === 4 ? "Rejected" : ""} */}
      </TableCell>

      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >
        {row.alertStatus ? <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: row.alertStatus == 1 ? 'rgb(225, 108, 108)' : row.alertStatus === 3 ? 'rgb(92, 99, 115)' : 'rgb(95, 170, 176)', marginRight: '5px' }} /> : ''}
        {!row.alertStatus ? "-" : REVERSED_ALERT_STATUS[row.alertStatus]}
      </TableCell>



      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >

        {!row.country ? "-" : row.country}
      </TableCell>
      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >

        {!row.city ? "-" : row.city}
      </TableCell>
      <TableCell
        id={labelId}
        scope="row"
        padding="none"

        align="left"
      >

        {!row.offices ? "-" : row.offices}
      </TableCell>

      {subdiv_user || control_user ?
        <TableCell
          id={labelId}
          scope="row"
          padding="none"

          align="right"
        >
          <i
            className="icon-eye actioneye"
            aria-label="toggle password visibility"
            // className="actioneye"
            // style={{color:"rgb(133, 142, 160)",fontSize:'16px',cursor: "pointer",}}
            // onClick={() => navigate(`/app/CrisisLog/crisis-log-inHouse-detail/${row.alertId}`)} 
            onClick={() => handelViewPage(row)}
          ></i>
          {/* <VisibilityOutlinedIcon style={{ color: '#858EA0', fontSize: '20px', cursor: 'pointer' }} 
      style={{color:"rgb(133, 142, 160)",fontSize:'16px'}}
      onClick={() => navigate(`/app/CrisisLog/crisis-log-inHouse-detail/${row.alertId}`)} /> */}
        </TableCell> : null}

      {/* /app/CrisisLog/crisis-log-alert-detail/${row.alertId}` */}

      {/* <TableCell align="center">
        <button
          style={{
            marginRight: "40px",
            backgroundColor: "transparent",
            cursor: "pointer",
            border: "0px",
            outline: "0px",
            boxShadow: "none",
            color: " rgb(95, 170, 176)",
            fontSize: "12px",
            padding: "0px",
          }}
          onClick={() => handleEdit(row._id)}
        >
          Edit
        </button>
        <button
          style={{
            marginRight: "40px",
            backgroundColor: "transparent",
            cursor: "pointer",
            border: "0px",
            outline: "0px",
            boxShadow: "none",
            color: " rgb(225, 108, 108)",
            fontSize: "12px",
            padding: "0px",
          }}
          onClick={handleUserDelete}
        >
          Delete
        </button>
      </TableCell> */}
    </TableRow>
  );
}
