import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  USER_PROFILE,
} from "./../../@jumbo/constant/ActionTypes";
import axios from "../../helper/customAxios";
import { getUserProfile } from './Settings'
import { Form, useNavigate } from "react-router-dom";
import { fetchError, fetchStart, fetchSuccess } from './Common';
// import { Form, useNavigate } from "react-router-dom";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS } from '../../@jumbo/constant/ActionTypes';

// import AuthService from "../../services/auth.service";
import AuthService from "../../services/auth.service";
import { CompressOutlined } from "@mui/icons-material";
import { refreshToken } from "./Auth";

const API_URL = "https://node.inheritxdev.in/cc-log-dev/api/v1/users/";
// const API_URL = "https://node.inheritxdev.in/cc-log-qa/api/v1/users/";
// const API_URL = "https://node.inheritxdev.in/cc-log-prod/api/v1/users/";
export const login = (email, password, redirectPath, group_code) => (dispatch) => {

  // let navigate = useNavigate();
  dispatch(fetchStart())
  return AuthService.login(email, password, redirectPath, group_code).then(

    (data) => {


      if (data) {

        // setTimeout(() => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        // }, 2000);

        setTimeout(() => {

          dispatch(fetchSuccess(data.message));

        }, 2000);

        // console.log(data.data)
        localStorage.setItem(
          "userToken",
          JSON.stringify(data.data.token)
        );
        // localStorage.setItem(
        //   "userToken",
        //   JSON.stringify(data.data.tokens[0].token)
        // );
        localStorage.setItem("profile", JSON.stringify(data.data))
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        dispatch(getUserProfile(() => redirectPath()))
        return Promise.resolve();
      }
      else {

        dispatch({ type: FETCH_SUCCESS, loading: false });
        dispatch(fetchError('There was something issue in responding server.'));
      }

    },
    (error) => {

      if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
        // dispatch(fetchStart())
        dispatch(fetchStart())
        setTimeout(() => {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }, 2000);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        // dispatch({ type: FETCH_SUCCESS, loading: false });
        setTimeout(() => {
          dispatch(fetchError(error.response.data.message));
        }, 2000);


        redirectPath()
        return Promise.reject();
      }
      else {
        setTimeout(() => {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }, 1000);

        // dispatch(fetchError(error.response.data.message));
      }
    }
  );
};

//   export const logout = () => (dispatch) => {
// AuthService.logout();
//     dispatch({
//       type: LOGOUT,
//     });
//   };


export const onLogout = (navigate) => {

  return (dispatch) => {
    axios
      .get(API_URL + "logout")
      .then(({ data }) => {
        if (data) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("profile");
          dispatch({ type: LOGOUT })
          dispatch({ type: USER_PROFILE, payload: null });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // localStorage.removeItem("userToken");
          // localStorage.removeItem("profile");

          navigate();

          // window.location.reload();
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(data.error));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(onLogout(navigate))
          }))
          return;
        }
        if ((error.response && error.response.status == 401) || (error.response && error.response.status == 400)) {
          dispatch({ type: LOGOUT })
          dispatch({ type: USER_PROFILE, payload: null });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          localStorage.removeItem("userToken");
          localStorage.removeItem("profile");

          navigate();

          // window.location.reload();
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

