import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
// import {useStyles} from '@mui/material';
// import styled from "@emotion/styled";

import { FormControlLabel, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { Form, useNavigate } from "react-router-dom";
import ConfirmDialog from "components/ConfirmDialog";

import {
  getExternalUser,
  updateExtController,
} from "redux/actions/ExtController";
// import "antd/dist/antd.css";
// import { Switch } from "antd";
import {
  requiredMessage,
  lettersOnlyMessage,
  phoneOnlyNumericals,
  onlyNumberAllow,
  emailNotValid,
  alphaNumaricMessage,
} from "@jumbo/constant/ErrorMessages";
import {
  stringOnly,
  emptySpace,
  phoneNumberValidation,
  emailvalidation,
  alphaNumaric,
  numberOnly,
  stringCheck,
} from "@jumbo/constant/ValidationRegex";
import { AntSwitch } from "components/antSwitch/AntSwitch";
import { Container, Container3 } from "./style";
import { styled } from "@mui/material/styles";
// import { Flag } from "react-ionicons";
import AddConfirmDialog from "../AddConfirmDialog";
import { Buttons } from "../Button";
import { ButtonCancel } from "../cancelButton";
import { TextFields } from "../Textfeild";
import { BootstrapInput } from "../DropDown";
import { CustomLabel } from "../DropDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useParams } from "react-router-dom";
import {
  getCurrUserDetail,
  getsubUsers,
} from "redux/actions/ExtController";
import { CustomCheckedIcon } from './CustomCheckedIcon';
const ariaLabel = { "aria-label": "description" };

const useStyles = styled((theme) => ({
  dialogRoot: {
    width: "100%",
    margin: "opx 0px",
    minWidth: "800px",
    overflow: "hidden",
    display: "block",
  },
  root: {
    display: "flex",
    webkitBoxPack: "justify",
    justifyContent: " space-between",
    alignItems: " flex-start",
    flexDirection: "row",
    marginTop: "10px",
    padding: "0px",
  },
  root2: {
    fontFamily: "SFTextSemibold",
    textAlign: "left",
    // marginBottom: "35px",
    fontSize: "22px",
    width: "100%",
    color: " rgb(92, 99, 115)",
  },
  helperText: {
    color: "red",
  },
}));

export default function EditExtController() {
  const { allExternalUsers, currentuser, subdivison } = useSelector(
    ({ EXTCONTROLLER }) => EXTCONTROLLER
  );
  let { id } = useParams();
  let classes = useStyles();
  // const [age, setAge] = React.useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");

  const [status, setStatus] = useState("");
  const [alertTravel, setAlertTravel] = useState(false);
  const [inHouse, setInHouse] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [dropdownIcon, setDropdownIcon] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const user_type = 4;
  let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let number = /^([0-9])*$/;
  let phone = /^(\+)([0-9]{9,15})$/;
  useEffect(() => {
    dispatch(getCurrUserDetail(id));
    dispatch(getsubUsers());
  }, [id]);

  useEffect(() => {
    if (currentuser) {
      if (currentuser.detail) {
        setFname(!!currentuser ? currentuser.detail.first_name : null);
        setLname(!!currentuser ? currentuser.detail.last_name : null);
        setPhoneNumber(!!currentuser ? currentuser.detail.mobile : null);
        setEmailId(!!currentuser ? currentuser.detail.email : null);
        setStatus(!!currentuser ? currentuser.detail.status : null);
        if (currentuser.detail.subdivision_accessible) {
          setPermissions(
            !!currentuser ? currentuser.detail.subdivision_accessible : null
          );
        }
        if (currentuser.detail.has_access_to) {
          setAlertTravel(
            !!currentuser ? currentuser.detail.has_access_to.alert_travel : null
          );
          setInHouse(
            !!currentuser ? currentuser.detail.has_access_to.in_house : null
          );
        }
      }
    }
  }, [currentuser]);

  const handleCheck = (event) => {
    var permissions_array = [...permissions];
    if (event.target.checked) {
      permissions_array = [...permissions, event.target.value];
    } else {
      permissions_array.splice(permissions.indexOf(event.target.value), 1);
    }
    setPermissions(permissions_array);
  };
  const handleSaveBtn = () => {
    // alert(alertTravel);
    // alert(inHouse);
    // alert("fname");
    if (!fname || fname.match(emptySpace)) {
      setHelperText(true)
      setFnameError(requiredMessage);
    } else if (!fname.match(stringCheck)) {
      setHelperText(true)
      setFnameError(lettersOnlyMessage);
    }
    if (!lname || lname.match(emptySpace)) {
      setHelperText(true)
      setLnameError(requiredMessage);
    } else if (!lname.match(stringCheck)) {
      setHelperText(true)
      setLnameError(lettersOnlyMessage);
    }
    if (!phoneNumber || phoneNumber.match(emptySpace)) {
      setHelperText(true)
      setPhoneNumberError(requiredMessage);
    } else if (!phoneNumber.match(phone)) {
      setHelperText(true)
      setPhoneNumberError(phoneOnlyNumericals);
    }
    if (!emailId || emailId.match(emptySpace)) {
      setHelperText(true)
      setEmailIdError(requiredMessage);
    } else if (!emailId.match(email)) {
      setHelperText(true)
      setEmailIdError(emailNotValid);
    }

    if (
      fname &&
      fname.match(stringCheck) &&
      !fname.match(emptySpace) &&
      lname &&
      lname.match(stringCheck) &&
      !lname.match(emptySpace) &&
      phoneNumber &&
      phoneNumber.match(phone) &&
      !phoneNumber.match(emptySpace) &&
      emailId &&
      emailId.match(email) &&
      !emailId.match(emptySpace) &&
      status
    ) {
      // setInhous_alert(obje);
      onUserSave();
    }
  };
  const onUserSave = () => {
    const obje = {
      alert_travel: alertTravel,
      in_house: inHouse,
    };
    const user = {
      user_id: !!currentuser ? currentuser.detail._id : null,
      email: emailId,
      first_name: fname,
      last_name: lname,
      status: status,
      mobile: phoneNumber,
      has_access_to: obje,
      subdivision_accessible: permissions,
      user_type: user_type,
    };

    dispatch(
      updateExtController(
        user,
        { setAddDialog: () => setOpenConfirmDialog(true) },
        () => dispatch(getExternalUser())
      )
    );
    // navigate(`/app/ExternalUsers`);
  };
  const handleCancelDialog = () => {
    setOpenConfirmDialog(false);
    navigate(`/app/ExternalUsers`);
  };
  const handleConfirm = () => {
    setOpenConfirmDialog(false);
  };
  const handleCancel = () => {
    navigate(`/app/ExternalUsers`);
  };

  return (
    <div className="mainDiv1">
      <div className="heading-section mb-48">
        <div className="pageTitle">Edit Ext controller</div>
      </div>

      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            width: "50ch",

            width: "100%",
          },
          flexGrow: 1,
        }}
        noValidate
        autoComplete="off"
        style={{ color: "rgba(0, 0, 0, 0.87)" }}
      >
        <Grid container spacing={2} ml={0} className="formGrid">
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="First Name *"
              variant="standard"
              value={fname}
              onChange={(e) => {
                setFname(e.target.value.slice(0, 16));
                setFnameError("");
                setHelperText(false)
              }}
              helperText={fnameError}
              helperProps={fnameError ? helperText : false}
              errorProps={fnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Last Name *"
              variant="standard"
              value={lname}
              onChange={(e) => {
                setLname(e.target.value.slice(0, 16));
                setLnameError("");
                setHelperText(false)
              }}
              helperText={lnameError}
              helperProps={lnameError ? helperText : false}
              errorProps={lnameError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="phone Number *"
              variant="standard"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setPhoneNumberError("");
                setHelperText(false)
              }}
              helperText={phoneNumberError}
              helperProps={phoneNumberError ? helperText : false}
              errorProps={phoneNumberError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <TextFields
              id="standard-basic"
              label="Email *"
              variant="standard"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
                setEmailIdError("");
                setHelperText(false)
              }}
              helperText={emailIdError}
              helperProps={emailIdError ? helperText : false}
              errorProps={emailIdError ? true : false}
            />
          </Grid>
          <Grid item xs={12} mb={2} className="gridText">
            <FormControl sx={{ m: 1, minWidth: "100%", margin: 0 }}>
              <CustomLabel id="demo-customized-select-label">
                Status
              </CustomLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setStatusError("");
                }}
                helperText={statusError}
                defaultValue="Active"
                input={<BootstrapInput />}
                dropDownMenuProps={{
                  iconButton: <AccessAlarmIcon />,
                }}
                onOpen={() => setDropdownIcon(true)}
                onClose={() => setDropdownIcon(false)}
                IconComponent={() =>
                  dropdownIcon ? (
                    <i
                      class="icon-arrow-up"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 0,
                        zIndex: -1,
                        color: "rgb(133, 142, 160)",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="icon-arrow-down"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 0,
                        zIndex: -1,
                        color: "rgb(133, 142, 160)",
                      }}
                    ></i>
                  )
                }
              >
                <MenuItem value={1}>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.5rem",
                      color: "rgb(95, 170, 176)",
                      marginRight: "4px",
                    }}
                  />
                  Active
                </MenuItem>
                <MenuItem value={2}>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.5rem",
                      color: "rgb(225, 108, 108)",
                      marginRight: "4px",
                    }}
                  />
                  Disabled
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <InputLabel id="demo-simple-select-label"
          className={'lable-style mb-5 ' + (phoneNumberError || lnameError || fnameError || emailIdError
            ? 'lable-red' : 'lable-style mb-5')}
        >
          {!emailIdError
            && !phoneNumberError && !fnameError && !lnameError ?
            '* all fields required' : '* Please fill all required fields'}
        </InputLabel>
      </Box>
      <Box
        style={{
          position: "relative",
          width: "100%",
          minHeight: "100%",
          margin: "0px",
        }}
      >
        <div className="heading-section">
          <div className="pageTitle">Has access to</div>
        </div>
        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              width: "50ch",
              mb: 4,
              width: "100%",
            },
            flexGrow: 1,
          }}
          noValidate
          autoComplete="off"
          style={{ color: "rgba(0, 0, 0, 0.87)" }}
        >
          <Grid container spacing={2} ml={0} className="formGrid">
            <Grid
              item
              xs={6}
              mb={2}
              style={{ position: "relative" }}
              className="gridText"
            >
              <TextFields
                defaultValue="Alert & Travel"
                variant="standard"
                textProps={true}
                disabled
              />

              <AntSwitch
                value={alertTravel}
                onChange={() => {
                  setAlertTravel(!alertTravel);
                }}
                checked={alertTravel}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              mb={2}
              style={{ position: "relative" }}
              className="gridText"
            >
              <TextFields
                defaultValue="In house"
                variant="standard"
                textProps={true}
                disabled
              />

              <AntSwitch
                value={inHouse}
                onChange={() => {
                  setInHouse(!inHouse);
                }}
                checked={inHouse}
                inputProps={{ "aria-label": "ant design" }}
              />
            </Grid>
          </Grid>
        </Box>
        <div className="heading-section">
          <div className="pageTitle">SubDivision access</div>
        </div>

        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              ml: 0,
              width: "50ch",
              mb: 4,
              width: "33%",
            },
          }}
          noValidate
          autoComplete="off"
          style={{ color: "rgba(0, 0, 0, 0.87)" }}
        >
          {!!subdivison.userData
            ? subdivison.userData.map((row, index) => {
              return (
                <FormControlLabel
                  key={row._id}
                  control={
                    // <Checkbox
                    //   className="checkbox-ui"
                    //   id={row._id}
                    //   checked={
                    //     permissions.filter((x) => x === row._id).length
                    //   }
                    //   onChange={handleCheck}
                    //   value={row._id}
                    // />
                    <Checkbox
                      checked={row ? permissions.filter((x) => x === row._id).length : null}
                      onChange={handleCheck}
                      icon={<CustomCheckedIcon checked={permissions.filter((x) => x === row._id).length} />}
                      checkedIcon={<CustomCheckedIcon checked={permissions.filter((x) => x === row._id).length} />}
                      value={row._id}

                      sx={{
                        "&:hover": {
                          backgroundColor: 'white'
                        }
                      }}
                    />
                  }
                  className="checkbox-lable"
                  label={row.firm_name}
                />
              );
            })
            : null}
        </Box>

        <ButtonCancel onClick={handleCancel}> cancel</ButtonCancel>
        <Buttons onClick={handleSaveBtn} buttoncheck={true}>
          Save
        </Buttons>
      </Box>
      <AddConfirmDialog
        open={openConfirmDialog}
        // title={`Delete Subdivision`}
        content={`All changes saved successfully. `}
        btnLabels={{
          ok: "Ok",
        }}
        onClose={handleCancelDialog}
        onConfirm={handleConfirm}
      />
    </div>
  );
}
