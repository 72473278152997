import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { crisisLogTable } from '../style'
import { crisisLogCols } from '../data'
import COLORS from 'utils/colors'
import * as moment from "moment";
import ReactTable from 'components/common/table/ReactTable'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getAlertTravelLogsList } from '../../../redux/actions/CrisisLogAlert';
import { AmountItemsContainer } from "../style";
import AmountItems from "../../AmountItems/AmountItems"


export const LogPrint = React.forwardRef(({ userLogDetail }, ref) => {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { logList } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT);
    const [activePage, setActivePage] = useState(1);
    const [activePerPage, setActivePerPage] = useState(10);
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const changePerPage = (activePerPage) => {
        setActivePage(1);
        setActivePerPage(activePerPage);
    }

    useEffect(() => {
        if (id) {
            dispatch(getAlertTravelLogsList(id, activePage, activePerPage, false));
        }
    }, [id, activePage, activePerPage]);

    const columns = [
        {
            id: 'time',
            title: "Time",
            accessor: 'time',
            width: '20%',
            align: 'left',
            Component: ({ row }) => {
                return (
                    <div style={{
                        color: 'rgb(92, 99, 115)',
                        fontFamily: 'SFTextLight', fontSize: '15px'
                    }}>

                        <div >{row.time ? moment(`${new Date(row.time)}`).format('DD-MM-YYYY') : '-'}</div>
                        <div>{row.time ? moment(`${new Date(row.time)}`).format('hh:mm') : '-'}</div>
                    </div>
                )
            }
        },
        {
            id: 'msg',
            accessor: 'msg',
            title: 'Details ',
            align: 'left',
            Component: ({ row }) => {
                return (
                    <div className='logDetails' dangerouslySetInnerHTML={{ __html: (row?.msg || '') }}></div>
                )
            }
        },

    ];
    return (
        <>
            <div ref={ref}>
                <AmountItemsContainer
                    style={{ width: "100%" }}
                >
                    <AmountItems
                        changePerPage={changePerPage}
                        activePerPage={activePerPage}
                    />
                </AmountItemsContainer>
                <ReactTable
                    COLUMNS={columns}
                    DATA={logList?.logs || []}
                    hiddenColumns={[]}
                    fetchData={handlePageChange}
                    pageIndex={activePage}
                    totalCount={logList?.total_no_of_logs_overall}
                    pageSize={activePerPage}
                    type={"checklist"}
                />
            </div>
        </>
        // <Table style={crisisLogTable} ref={ref}>
        //     <TableHead>
        //         <TableRow>
        //             {crisisLogCols.map((col) => <TableCell key={col.id} sx={{ color: COLORS.greylighttext }}>{col.label}</TableCell>)}
        //         </TableRow>
        //     </TableHead>
        //     <TableBody>
        //         {userLogDetail && userLogDetail.travelLogs && userLogDetail.travelLogs.map((data, idx) => {
        //             return <TableRow key={`col-${idx}`}>{renderCell(data)}</TableRow>
        //         })}
        //     </TableBody>
        // </Table>
    )
})