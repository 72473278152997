import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { useMemo } from 'react';
import Pagination from './Pagination/Pagination';

const ReactTable = ({
    COLUMNS = [],
    DATA = [],
    fetchData,
    pageIndex = 1,
    pageSize,
    totalCount = 0,
    totalPages = Math.ceil(totalCount / pageSize),
    sortingOption,
    setSortingOption,
    pagination = true,
    hiddenColumns = [],
    customText
}) => {
    const columns = useMemo(() => COLUMNS, [COLUMNS]);
    const data = useMemo(() => DATA, [DATA]);
    return (
        <>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="sticky enhanced table"
                    className="custome-tbl"
                >
                    <TableHead /* className='border-t-2' */>
                        <TableRow>
                            {columns?.length > 0 && columns.map((value, index) => {
                                if (!hiddenColumns.includes(value.id))
                                    return (
                                        <TableCell sx={{ width: (value?.width || 'auto') }} align={value?.align || 'left'} key={index} className='cursor-pointer' style={{ paddingTop: '0.30rem', paddingBottom: '0.30rem' }} onClick={() => {
                                            if (value.sortable && setSortingOption instanceof Function) {
                                                setSortingOption({
                                                    columnName: value.accessor,
                                                    direction: sortingOption?.direction === 'desc' ? 'asc' : 'desc',
                                                })
                                            }
                                        }
                                        }>

                                            <div className='flex'>
                                                {value?.title}
                                                {value?.sortable &&
                                                    <div className="flex flex-col pl-1 pt-1">
                                                        {
                                                            <>
                                                                <span className="material-icons-outlined text-sm h-2 leading-[10px]">
                                                                    {(!sortingOption?.direction || sortingOption.columnName !== value.accessor || (sortingOption.columnName === value.accessor && sortingOption?.direction === 'asc')) && 'keyboard_arrow_up'}
                                                                </span>
                                                                <span className="material-icons-outlined text-sm h-2 leading-[10px]">
                                                                    {(!sortingOption?.direction || sortingOption.columnName !== value.accessor || (sortingOption.columnName === value.accessor && sortingOption?.direction === 'desc')) && `keyboard_arrow_down`}
                                                                </span>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </TableCell>
                                    );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data && data.length > 0) ? data.map((row, index) => (
                            <TableRow
                                key={index}
                                className='text-center'
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {columns.map((value, index) => {
                                    const Component = value?.Component;
                                    if (!hiddenColumns.includes(value.id))
                                        return (
                                            <TableCell key={index} align={value?.align || 'left'}>{Component ? <Component row={row} /> : row[value?.accessor]}</TableCell>
                                        );
                                })}
                            </TableRow>
                        )) : <TableRow>
                            <TableCell
                                colSpan={columns?.length}
                                // rowSpan={columns.length}
                                style={{ textAlign: "center" }}
                            >
                                {customText ? customText : "No Record found"}
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer >
            {pagination && <Pagination
                activePage={pageIndex}
                totalItems={totalCount}
                itemsCountPerPage={pageSize}
                handlePageChange={fetchData}
            />}
        </>
    )
}

export default ReactTable;