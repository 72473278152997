import { GET_ALL_USERS, ADD_USER, GET_USER_DETAILS, EDIT_SUBDIVISION_USER, CONNECTED_SUB_SIDEBAR, GET_SUBDIVISION_LIST, CONNECTED_SUBDIVISIONS, SEC_SUBDIV } from "../../@jumbo/constant/ActionTypes";

const INIT_STATE = {
  allUsers: [],
  // addUser:null,
  currentuser: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }
    case ADD_USER: {
      return {
        ...state,
        allUsers: [action.payload, ...state.allUsers],
      };
    }
    case GET_USER_DETAILS: {
      return {
        ...state,
        currentuser: action.payload,
      };
    }
    case EDIT_SUBDIVISION_USER: {
      return {
        ...state,
        allUsers: state.allUsers.map(allUsers => (allUsers.id === action.payload._id ? action.payload : allUsers)),
      };

    }

    case GET_SUBDIVISION_LIST: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }
    // CONNECTED_SUBDIVISIONS
    case CONNECTED_SUBDIVISIONS: {
      return {
        ...state,
        allUsers: action.payload,
      };
    }

    case CONNECTED_SUB_SIDEBAR: {
      return {
        ...state,
        ConnectedSub: action.payload,
      };
    }

    case SEC_SUBDIV: {
      return {
        ...state,
        secSubdivisionUser: action.payload,
      };
    }
    default:
      return state;
  }
};
