import axios from '../../helper/customAxios';
import { FETCH_SUCCESS, ADD_ACTION_FOCUSBOARD, GET_ACTION_FOCUSBOARD, COMPLETE_ACTION_FOCUSBOARD, SET_ACTION_FOCUSBOARD_ID } from '../../@jumbo/constant/ActionTypes';
import { createActionInFocusBoard, listOfActionsInFocusBoard, completeActionInFocusBoard, deleteActionInFocusBoard, updateFocusBoardURL } from '@jumbo/constant/ApiConstant'
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { getAlertDetail } from './CrisisLogAlert';
import { getTravelLogDetail } from './travel';
import { refreshToken } from './Auth';

export const addActionInFocusBoard = (id, data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(createActionInFocusBoard + id, data)
      .then(res => {
        if (res?.data?.data) {
          dispatch(getActionListForFocusBoard({ id, isForTravel: data?.isForTravel }))
        }
        // console.log("res?.data?.data?.message",res?.data?.message)
        dispatch(fetchSuccess(res?.data?.message));
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addActionInFocusBoard(id, data, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          dispatch(fetchError(error?.response?.data?.message));
        }
        // if (error.response && error.response.status === 401 || error.response && error.response.status === 400) {
        //   dispatch(fetchError(error?.response?.data?.message));
        // }
      });
  };
};

export const getActionListForFocusBoard = (params) => {
  return dispatch => {
    dispatch(fetchStart())
    axios.get(listOfActionsInFocusBoard, { params: params }).then((res) => {
      if (res.status === 200 && res?.data?.data?.focus_board) {
        if (res?.data?.data) {
          dispatch({ type: GET_ACTION_FOCUSBOARD, payload: res?.data?.data?.focus_board });
        }
        dispatch({ type: FETCH_SUCCESS, loading: false })
      }
    }).catch((err) => {
      if (err.response && err.response.status == 444) {
        dispatch(refreshToken(() => {
          dispatch(getActionListForFocusBoard(params))
        }))
        return;
      }
      if (err?.response?.data?.message) {
        dispatch(fetchError(err?.response?.data?.message));
      }
      // if (err && err.response && err.response.status === 401) {
      //   dispatch(fetchError(err.response.data && err.response.data.message))
      // dispatch({ type: FETCH_SUCCESS, loading: false })
      // }
    })
  }
}

export const completeActionList = (datas) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(completeActionInFocusBoard, datas)
      .then((res) => {
        console.log("res complete1", res)
        if (res?.data?.data) {
          dispatch({ type: COMPLETE_ACTION_FOCUSBOARD, payload: res?.data?.data });
          dispatch(getActionListForFocusBoard({ id: datas?.id, isForTravel: datas?.isForTravel }))
          if (datas?.isForTravel) {
            dispatch(getTravelLogDetail({ travel_id: datas?.id }));
          } else {
            dispatch(getAlertDetail(datas?.id));
          }
        }
        dispatch(fetchSuccess(res?.data?.message));
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(completeActionList(datas))
          }))
          return;
        }
        if (error.response.status === 404) {
          dispatch(fetchError(error?.response?.data?.message));
          setTimeout(() => {
            dispatch(getActionListForFocusBoard({ id: datas?.id, isForTravel: datas?.isForTravel }))
          }, 2000)
        }
        else if (error?.response?.data?.message) {
          dispatch(fetchError(error?.response?.data?.message));
        }
        //   if (err?.response?.data?.message) {
        //     dispatch(fetchError(err?.response?.data?.message));
        // }
        // if ((error.response && error.response.status == 401) || (error.response && error.response.status == 400) || (error.response && error.response.status == 404)) {
        //   dispatch(fetchError(error.response.data.message));
        // }
      });
  };
};

export const deleteActionOfFocusBoard = (id, datas, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .put(deleteActionInFocusBoard + id, datas)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getActionListForFocusBoard({ id, isForTravel: datas?.isForTravel }))
        }
        dispatch(fetchSuccess(res?.data?.message));
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(deleteActionOfFocusBoard(id, datas, callbackFun))
          }))
          return;
        }
        if (error.response.status === 404) {
          dispatch(fetchError(error?.response?.data?.message));
          setTimeout(() => {
            dispatch(getActionListForFocusBoard({ id: id, isForTravel: datas?.isForTravel }))
          }, 2000)
        }
        else if (error?.response?.data?.message) {
          dispatch(fetchError(error?.response?.data?.message));
        }
        //   if (error?.response?.data?.message) {
        //     dispatch(fetchError(error?.response?.data?.message));
        // }
        // if ((error.response && error.response.status == 401) || (error.response && error.response.status == 400)) {
        //     dispatch(fetchError(error.response.data.message));
        //   }
      });
  };
};

// updateFocusBoard
export const updateFocusBoardAction = (actionID, id, editData, { resetForm }) => {
  return dispatch => {
    dispatch(fetchStart());
    axios.put(updateFocusBoardURL + actionID, editData)
      .then((res) => {
        if (res?.data?.data) {
          resetForm({});
          dispatch(getActionListForFocusBoard({ id, isForTravel: editData?.isForTravel }))
        }
        dispatch(fetchSuccess(res?.data?.message || ''));
      }).catch((err) => {
        if (err.response && err.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateFocusBoardAction(actionID, id, editData, { resetForm }))
          }))
          return;
        }
        if (err?.response?.data?.message) {
          dispatch(fetchError(err?.response?.data?.message));
        } else {
          dispatch(fetchError());
        }
      })
  }
}