import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import "./ChangePassword.css";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import ConfirmDialog from "components/ConfirmDialog";
import { password } from "@jumbo/constant/ValidationRegex";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { onLogout } from "redux/actions/auth.action";
import {
  userChangePassword,
  getUserProfile,
} from "redux/actions/Settings";
import {
  passwordMessage,
  passwordMisMatch,
  requiredMessage,
} from "@jumbo/constant/ErrorMessages";

import { Buttons } from "../Button";
import { TextFields } from "../Textfeild";
import AddConfirmDialog from "../AddConfirmDialog";
import { Margin } from "@mui/icons-material";
const useStyles = styled((theme) => ({
  dialogRoot: {
    position: "relative",
    // maxHeight:'600px'
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
      textOverflow: "ellipsis",
      maxWidth: "350px",
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },

  },
  dialogActionsRoot: {
    // padding: '16px 24px',
    // align:'center'
  },
}));

function ChangePassword({ open, onClose, onConfirm, setOpenPassConfirmDialog }) {
  // const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [helperText, setHelperText] = useState(false);

  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newpasswordError, setNewPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");

  // const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$.!%*#?&])[A-Za-z\d@$.!%*#?&]{8,}$/;
  const passwordRegex = /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
  const dispatch = useDispatch();
  let navigate = useNavigate();



  useEffect(() => {


    return () => {
      setRePasswordError("");
      setNewPasswordError("");
      setOldPasswordError("");
    }
  }, [])


  const handleChangePass = () => {

    if (newpassword) {
      if (!newpassword.match(passwordRegex)) {

        setNewPasswordError(
          "Password must be at least 8 character long and should contain upper case, lower case, symbol and number."
        );
        setHelperText(true)
      }
    }

    // if (!rePassword.match(passwordRegex)) {
    //   setRePasswordError(
    //     "Password must be at least 8 character long and should contain upper case, lower case, symbol and number."
    //   );
    // }
    if (oldPassword && newpassword) {
      if (oldPassword === newpassword && oldPassword !== "") {
        setHelperText(true)
        // alert(oldPassword, newpassword)
        setNewPasswordError(
          "Old and New password are same. Please use different password."
        );
      }
    }

    if (oldPassword === "") {
      setHelperText(true)
      setOldPasswordError(requiredMessage);
    }

    if (newpassword === "") {
      setHelperText(true)
      setNewPasswordError(requiredMessage);
    }
    if (rePassword === "") {
      setHelperText(true)
      setRePasswordError(requiredMessage);
    }


    if (newpassword && rePassword) {

      if (newpassword !== rePassword && rePassword !== "") {

        setHelperText(true)
        setRePasswordError(passwordMisMatch);
      }

    }


    if (
      oldPassword !== "" &&
      oldPassword !== newpassword &&
      newpassword.match(passwordRegex) &&
      rePassword === newpassword && !rePasswordError
    ) {
      onUserSave();
    }
  };

  const onUserSave = () => {


    const user = {
      old_password: oldPassword,
      new_password: newpassword,
      confirm_password: rePassword
    };
    dispatch(userChangePassword(user, { setAddDialog: () => setOpenConfirmDialog(true) }));

    // onConfirm();

  }
  const handleCancelDelete = () => {

    setOpenConfirmDialog(false);
    onClose();
    dispatch(onLogout(() => navigate('/')))
    // navigate('/')

  };
  const handleConfirmDelete = () => {

    dispatch(onLogout(() => navigate('/')))
    setOpenConfirmDialog(false);

    // navigate('/')
  };
  return (
    // <div className='dialogBox'>

    <Dialog open={open} onClose={onClose} style={{ maxWidth: '500px', margin: ' auto' }} className='dialog' sx={{
      // '& .MuiDialog-paper':{
      //   height:'80% !important',
      //   marginTop:'none'

      // }
    }}>
      {/* <CloseIcon className="icon" onClick={onClose} /> */}
      <i className="icon-close icon" onClick={onClose}></i>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent >
        <TextFields
          // autoFocus
          margin="dense"
          label="Old Password"
          type="text"
          fullWidth
          variant="standard"
          // placeholder="Type Current Password"
          onChange={(event) => {
            setOldPassword(event.target.value);
            setOldPasswordError("");
            setHelperText(false)

          }}
          helperText={oldPasswordError}
          id="oldPassword"
          style={{ marginBottom: '15px !important' }}
          // error={oldPasswordError?true:false}
          // helperProps={oldPasswordError && !oldPassword && oldPassword == ""?helperText:false}
          helperProps={oldPasswordError ? helperText : false}
          errorProps={oldPasswordError ? true : false}
        />
        <TextFields
          // autoFocus
          margin="dense"
          id="newPassword"
          label="New Password"
          type="text"
          fullWidth
          variant="standard"
          // placeholder="Type New Password"
          onChange={(event) => {
            setNewPassword(event.target.value);
            setNewPasswordError("");
            setHelperText(false)
            newpassword && setRePasswordError('')

          }}
          helperText={newpasswordError}
          style={{ marginBottom: '20px' }}
          // sx= {{ height: 62,marginTop:"9px" }}
          // error={newpasswordError?true:false}
          helperProps={newpasswordError ? helperText : false}
          errorProps={newpasswordError ? true : false}
        />
        <TextFields
          // autoFocus
          margin="dense"
          id="name"
          label="Repeat Password"
          type="text"
          fullWidth
          variant="standard"
          // placeholder="Type Reapet Password"
          onChange={(event) => {
            setRePassword(event.target.value);
            setRePasswordError("");
            setHelperText(false)
          }}
          helperText={rePasswordError}
          style={{ marginBottom: '15px' }}
          helperProps={rePasswordError ? helperText : false}
          errorProps={rePasswordError ? true : false}
        />
      </DialogContent>

      {/* <DialogActions > */}

      {/* <Button
        // className="changePasswordBtn"
        variant="contained"
        style={{
          background: 'rgb(72, 146, 195) ',
  textTransform: 'capitalize ',
  padding: '15px ',
  width:' 100% ',
  marginTop: '40px ',
  lineHeight: '1.2 ',
  borderRadius: '8px ',
  boxShadow: 'rgb(98 132 152 / 33%) 0px 11px 26px -10px ',
  fontFamily: "SF Pro Text" ,
  fontSize: '16px ',
  maxWidth: "192px",
          color:'white',
          // marginLeft: "20px",
          height: "49px",
          marginBottom: "40px",
          margin:' 20px auto 65px' ,
         
// borderTop: `solid 1px ${theme.palette.borderColor.main}`,
'&:hover': {
background:'rgb(62, 138, 193)'}

        }}
        type="submit"
        onClick={() => {
          handleChangePass();
          // onConfirm();
        }}
      >
        Change password
      </Button> */}

      <Buttons onClick={
        handleChangePass}
        style={{
          width: '50%', marginLeft: '108px',
          marginBottom: '40px',
          marginTop: '10px'
        }} buttoncheck={true}>Change password</Buttons>
      {/* </DialogActions> */}
      <AddConfirmDialog
        open={openConfirmDialog}
        // title={`Delete Subdivision`}
        content={`All changes saved successfully. `}
        btnLabels={{
          ok: "Ok",

        }}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Dialog>

    // </div>
  );
}
export default ChangePassword;
