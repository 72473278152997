import styled from "styled-components";
import { Tabs, Tab, TableRow, TextField, Button, Chip } from "@mui/material"
import { InfoWindow } from 'google-maps-react';
import COLORS from "utils/colors";

export const TravelContainer = styled.div``

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const HeaderText = styled.h1`
    font-family: SFTextSemibold;
    text-align: left;
    font-size: 22px;
    width: 100%;
    color: rgb(92, 99, 115);
`

export const CustomButton = styled(Button)({
    backgroundColor: 'rgb(72, 146, 195) !important',
    width: '200px !important',
    height: '36px !important',
    padding: '0 25px !important',
    textTransform: 'none !important',
    fontSize: '15px !important',
    fontFamily: 'SFTextLight !important'
})

export const TableTitle = {
    textAlign: 'left',
    fontFamily: 'SFTextMedium',
    fontSize: '15px',
    color: 'rgb(92, 99, 115)',
}

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: -4px !important;
`

export const DetailHeader = styled.div`
    font-family: SFTextSemibold;
    text-align: left;
    font-size: 22px;
    width: 100%;
    color: rgb(92, 99, 115);
`

export const UserDetailContainer = styled.div`
    width: 78%;
    padding: 25px 18px;
    border: 0.5px solid rgb(168, 201, 224);
    border-radius: 8px;
    background-color: rgb(247, 249, 252);
`

export const UserDetailTitle = styled.div`
    font-size: 18px;
    margin-bottom: 16px;
    font-family: SFTextMedium;
    color: rgb(72, 146, 195);
`

export const UserBasicInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
`
export const BasicInfoTitle = styled.div`
    font-size: 12px;
    color: rgb(133, 142, 160);
    margin: 0px 0px 10px;
}
`

export const BasicInfoValue = styled.div`
    font-size: 15px;
    font-family: "SF Pro Text";
    line-height: 1.3;
    margin: 0px 0px 25px;
`

export const TravelInfoValue = styled.div`
    font-size: 15px;
    font-weight: 300;
`

export const TravelInfoContainer = styled.div`
    border-bottom: 1px solid #F1F1F1;
    padding-bottom: 5px;
`

export const CustomTextareaContainer = styled.div`
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    -webkit-box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    -moz-box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    padding: 15px;
`

export const CustomTextarea = styled(TextField)({
    fontFamily: 'SFTextLight',
    fontSize: '12px',
})

export const AddButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 15px 0px;
`

export const AddButton = {
    width: '12%',
    backgroundColor: 'rgb(72, 146, 195)',
    padding: '9px 13px',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: "SF Pro Text",
}

export const UserAddButton = {
    fontSize: '12px',
    fontFamily: "SF Pro Text",
    width: '50px',
    borderRadius: '8px',
    padding: '10px 0px'
}

export const crisisLogTable = {
    fontSize: '15px',
    fontFamily: 'SFTextMedium'
}

export const CrisisTravelRow = styled(TableRow)({
    backgroundColor: 'rgb(247, 249, 252)',
    border: '1px solid rgb(197, 219, 235)',
    borderRadius: '5px'
})

export const CheckInDestinationHeader = {
    '&:last-child th': { paddingRight: '0 !important' },
    '&:first-child th': { paddingLeft: '0 !important' }
}

export const CheckInDestinationCell = {
    'td': { paddingRight: '0 !important' },
    'td': { paddingLeft: '0 !important' }
}

export const MapHeader = styled.div`
    font-family: SFTextSemibold;
    text-align: left;
    margin: 20px 0px;
    font-size: 22px;
    color: rgb(92, 99, 115);
`

export const CheckInComment = styled.div`
    padding: 25px 18px;
    background-color: rgb(247, 249, 252);
    border: 1px solid rgb(197, 219, 235);
    border-radius: 5px;
    width: 96%
`

export const AddTravelContainer = styled.div`
    width: 75%;
`

export const UserInputContainer = styled.div`
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3%;
`

export const AddTravelButtonContainer = {
    marginTop: '20px',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}

export const CustomErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
`

export const CustomError = styled.div`
    font-size: 14px;
    color: #D32F2F;
`

export const CustomPickerSpan = styled.span`
    font-family: SFTextLight;
    position: absolute;
    right: 35px;
    bottom: 13%;
    color: rgb(133, 142, 160);
    transform: translateY(-50%);
`

export const CustomerPickerIconContainer = styled.span`
    width: 16px;
    position: absolute;
    right: 0px;
    bottom: -1%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    color: rgb(133, 142, 160);
`

export const CheckedIconContainer = styled.div`
    height: 16px;
    width: 16px;
    background-color: rgb(255, 255, 255);
    border: 3px solid rgb(162, 169, 183);
    border-radius: 3px;
    padding: 7px;
`

export const CheckedIconSelected = {
    height: '16px',
    width: '16px',
    top: '10px',
    position: 'absolute',
    right: '10px',
    fontWeight: 'bolder'
}

export const CheckboxLabel = styled.label`
    font-family: SFTextLight;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 15px;
    line-height: 1.1;
    color: rgb(113, 117, 125)
`

export const GoogleInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 54%;
`

export const GoogleInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    border: 1px solid rgb(229,230,233);
    border-radius: 5px;
    padding: 0 3px;
`

export const GoogleInputBox = styled.input`
    font-family: SFTextLight;
    font-size: 15px;
    outline: none;
    box-shadow: none;
    padding: 0px 10px;
    width: 95%;
    border: 0px;
`

export const CursorStyle = {
    cursor: 'pointer'
}

export const DeleteIcon = styled.div`
    width: 16px;
    transform: translateY(-50%);
    cursor: pointer;
`
export const InfoWindowStyle = {
    height: '50%',
    width: '50%',
    padding: '0 !important'
}

export const CustomInfoWindow = styled(InfoWindow)({
    '& .gm-style-iw': {
        backgroundColor: 'red !important'
    },
    '& gm-style-iw-tc': {
        display: 'none'
    }
})

export const DetailHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 78%;
`

export const CustomChip = styled(Chip)({
    borderRadius: '3px !important',
    backgroundColor: `${COLORS.blue} !important`
})

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PlaceholderStyle = {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.5)'
}

export const DialogStyle = {
    '& .MuiDialog-paper': {
        width: '80%',
        maxHeight: 435
    }
}

export const AmountItemsContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 52%;
    margin-bottom: 15px;
`
