import { Buttons } from 'modules/Button';
import React, { useEffect } from 'react'
import Modal from "components/common/modal";
import Input from 'components/formComponents/Input';
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useParams } from 'react-router';
import { validationMsg } from '@jumbo/constant/ErrorMessages';
import { useDispatch } from "react-redux";
import { addActionInFocusBoard, updateFocusBoardAction } from 'redux/actions/focusBoard';

const Add = ({ openDialog, setOpenDialog, type, updateFocusBoard, setUpdateFocusBoard }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    // const [openAddActionDialog, setOpenAddActionDialog] = useState(false);
    console.log("type", type)
    const schema = Yup.object().shape({
        action: Yup.string().trim(validationMsg.focusBoard.action).required(validationMsg.focusBoard.action),
        responsible_person: Yup.string().trim(validationMsg.focusBoard.responsibleUser).required(validationMsg.focusBoard.responsibleUser),
        est_time: Yup.string().trim(validationMsg.focusBoard.estTime).required(validationMsg.focusBoard.estTime),
    });

    useEffect(() => {
        console.log("updateFocusBoard", updateFocusBoard);
    }, [openDialog])
    const onSubmitHandler = (values, { resetForm }) => {
        const data = {
            action: values?.action,
            responsible_person: values?.responsible_person,
            est_time: values?.est_time,
            isForTravel: values?.isForTravel
        }
        const editData = {
            id: id,
            action: values?.action,
            responsible_person: values?.responsible_person,
            est_time: values?.est_time,
            isForTravel: values?.isForTravel
        }
        if (updateFocusBoard) {
            dispatch(updateFocusBoardAction(updateFocusBoard._id, id, values, { resetForm }))
            // dispatch(updateCheckList(values, id, { resetForm }, subdivisionId, updateChecklistItem.action_id));
            setOpenDialog(false);
        }
        else {
            dispatch(addActionInFocusBoard(id, data))
            setOpenDialog(false)
        }
    }

    useEffect(() => {
        if (!openDialog) setTimeout(() => setUpdateFocusBoard(), 300);
    }, [openDialog])

    return (
        <>
            <Modal fullWidth={true} openDialog={openDialog} setOpenDialog={setOpenDialog} title={updateFocusBoard ? "Edit action" : "Add new action"}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        id: id,
                        action: updateFocusBoard?.action || '',
                        responsible_person: updateFocusBoard?.responsible_person || '',
                        est_time: updateFocusBoard?.est_time || '',
                        isForTravel: type === 3 ? true : false
                    }}
                    onSubmit={onSubmitHandler}
                    validationSchema={schema}
                >
                    {({ errors, setFieldValue, values }) => {
                        return (
                            <FormikForm>
                                <div className="w-full last:mb-0 text-right">
                                    <Input
                                        label="Describe the action"
                                        fullWidth
                                        name="action"
                                        defaultValue={values.action}
                                    />
                                </div>
                                <div className="w-full last:mb-0 text-right">
                                    <Input
                                        label="Who gets this done?"
                                        fullWidth
                                        defaultValue={values.responsible_person}
                                        name="responsible_person"
                                    />
                                </div>
                                <div className="w-full mb-6 last:mb-0 text-right">
                                    <Input
                                        label="How long time might it take?"
                                        fullWidth
                                        defaultValue={values.est_time}
                                        name="est_time"
                                    />
                                </div>
                                <div className='flex justify-center mt-4'>
                                    <Buttons type='submit' className='px-5 py-3'> {updateFocusBoard ? 'Edit action' : 'Register action'}</Buttons>
                                    {/* <button type="submit" className='p-5 bg-[#4792c3] text-white rounded-md'>Register action</button> */}
                                </div>
                            </FormikForm>
                        )
                    }}
                </Formik >
            </Modal>
        </>
    )
}

export default Add;